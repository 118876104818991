import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';

export const create5weeksStringDateData = ({
    startDate
}: {
    startDate: dayjs.Dayjs;
}) => {
    dayjs.locale(ja);

    let day = -1;
    const dateData = new Array(5).fill([]).map(() =>
        new Array(7).fill(null).map(() => {
            day++;
            return startDate.add(day, 'day').format('YYYY/MM/DD');
        })
    );

    return dateData;
};
