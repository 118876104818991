import { rest } from 'msw';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const subjectSlotTimesHandlers = [
    rest.put(
        `${employeeURL}/subject_slot_times/:subjectSlotLabelId/:subjectBeltId`,
        (_req, res, ctx) => {
            return res(ctx.status(200));
        }
    ),
    rest.get(
        `${employeeURL}/subject_slot_times/:subjectSlotLabelId/:subjectBeltId`,
        (_req, res, ctx) => {
            return res(
                ctx.status(200),
                ctx.json({
                    subjectBeltId: 1,
                    subjectSlotLabelId: 1,
                    startTime: '9:00',
                    endTime: '17:00'
                })
            );
        }
    )
];
