import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import { cn } from '@/utils';

type ButtonProps = {
    variant?: 'primary' | 'primary-neutral' | 'primary-outline' | 'cyan';
    children: ReactNode;
} & ComponentPropsWithoutRef<'button'>;

export const Button: FC<ButtonProps> = ({
    variant = 'primary',
    children,
    ...props
}) => {
    const variantClass = {
        primary:
            'bg-primary text-white border-primary disabled:bg-gray-light disabled:text-gray-middle disabled:border-gray-neutral',
        cyan: 'bg-cyan text-white border-cyan disabled:bg-gray-light disabled:text-gray-middle disabled:border-gray-neutral',
        'primary-neutral':
            'bg-primary-neutral text-white border-primary-neutral disabled:bg-gray-light disabled:text-gray-middle disabled:border-gray-neutral',
        'primary-outline':
            'text-primary border-primary bg-white disabled:bg-gray-light disabled:text-gray-middle disabled:border-gray-neutral'
    };

    return (
        <button
            {...props}
            className={cn(
                'rounded border-2 px-10 py-2.5 text-sm hover:font-semibold disabled:hover:font-normal',
                variantClass[variant],
                props.className
            )}
        >
            {children}
        </button>
    );
};
