import { useMemo } from 'react';
import { getTokenFromCookie, explicitUserIdFromJwt } from '@/utils';

export const useAuth = () => {
    const token = useMemo(() => getTokenFromCookie({}), []);

    const id = useMemo(() => explicitUserIdFromJwt(token), [token]);

    return {
        token,
        id
    };
};
