import { rest } from 'msw';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const passwordHandlers = [
    rest.put(`${employeeURL}/password/reset/:uuid`, (_req, res, ctx) => {
        return res(ctx.status(409));
    }),
    rest.post(`${employeeURL}/password/reset`, (_req, res, ctx) => {
        return res(ctx.status(200));
    })
];
