import { FC, ReactNode } from 'react';
import { QueryClientProvider } from 'react-query';

import { Loading } from './Loading';
import { Suspense } from './Suspense';
import { queryClient } from '@/apis/lib';

export const AppProvider: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <Suspense>
            <QueryClientProvider client={queryClient}>
                <Loading>{children}</Loading>
            </QueryClientProvider>
        </Suspense>
    );
};
