import { ReactComponent as KeyboardArrowDown } from '@material-design-icons/svg/outlined/keyboard_arrow_down.svg';
import { ReactComponent as KeyboardArrowUp } from '@material-design-icons/svg/outlined/keyboard_arrow_up.svg';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SubjectPanelProps } from './SubjectPanel.types';

export const SubjectPanel: FC<SubjectPanelProps> = ({
    title,
    id,
    implementationDate,
    deadline,
    applicantNumber,
    jobTitles,
    note,
    isOpen,
    onClick
}) => {
    const applicantNumbers = [
        jobTitles!.find((job) => job.name === 'ベルト盛り付け')
            ?.applicantNumber ?? null,
        jobTitles!.find((job) => job.name === 'おせち')?.applicantNumber ??
            null,
        jobTitles!.find((job) => job.name === '調理補助')?.applicantNumber ??
            null
    ];

    return (
        <div className="flex flex-col justify-center px-2 py-4 border w-128 border-primary rounded-md gap-3.5">
            <div className="flex w-full gap-2">
                {isOpen === true ? (
                    <KeyboardArrowUp
                        className="w-6 h-6 cursor-pointer fill-primary"
                        onClick={onClick}
                    />
                ) : (
                    <KeyboardArrowDown
                        className="w-6 h-6 cursor-pointer fill-primary"
                        onClick={onClick}
                    />
                )}

                <Link
                    to={'/subject/' + id.toString()}
                    className="text-base link-cyan-4"
                >
                    <p>{title}</p>
                </Link>
            </div>
            {isOpen === true && (
                <>
                    <div className="flex flex-col justify-center pl-10 text-sm text-gray-dark gap-1">
                        <p>実施日：{implementationDate}</p>
                        <p>応募期限：{deadline}</p>
                        <p>
                            応募人数：{applicantNumber}人（ベルト：
                            {applicantNumbers[0] != null
                                ? applicantNumbers[0] + '人'
                                : '-'}
                            、おせち：
                            {applicantNumbers[1] != null
                                ? applicantNumbers[1] + '人'
                                : '-'}
                            、調理補助：
                            {applicantNumbers[2] != null
                                ? applicantNumbers[2] + '人'
                                : '-'}
                            ）
                        </p>
                    </div>
                    <div className="flex flex-col pl-10 text-sm font-semibold text-gray-dark gap-1">
                        <p>備考</p>
                        <p className="pl-4">{note != null ? note : '-'}</p>
                    </div>
                </>
            )}
        </div>
    );
};
