import { FC, ReactNode } from 'react';
import { ErrorBoundary as _ErrorBoundary } from 'react-error-boundary';
import { Error } from '@/features/userMisc/pages/Error';

type ErrorBoundaryProps = {
    children: ReactNode;
};

export const ErrorBoundaryForUser: FC<ErrorBoundaryProps> = ({ children }) => {
    return (
        // eslint-disable-next-line react/jsx-pascal-case
        <_ErrorBoundary
            onError={(error) => {
                console.log(error);
            }}
            FallbackComponent={(error) =>
                Error({ statusCode: error.error?.response?.status })
            }
        >
            {children}
        </_ErrorBoundary>
    );
};
