export const divideHHMM = ({
    timeString
}: {
    timeString?: string;
}): { hour: string; minutes: string } | undefined => {
    if (timeString != null) {
        const timeParts = timeString.split(':');

        if (timeParts.length === 2) {
            return { hour: timeParts[0], minutes: timeParts[1] };
        }
    }

    return undefined;
};
