import { Switch } from '@headlessui/react';
import { FC } from 'react';
import { ToggleProps } from './Toggle.types';
import { cn } from '@/utils';

export const Toggle: FC<ToggleProps> = ({
    className,
    enabled,
    disabled = false,
    onChange
}) => {
    return (
        <div className={className}>
            <Switch
                checked={enabled}
                onChange={onChange}
                className={cn(
                    'relative inline-flex items-center h-7 w-14 shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75',
                    enabled
                        ? 'bg-primary-neutral hover:bg-primary'
                        : 'bg-gray-middle hover:bg-gray',
                    disabled && 'cursor-default bg-gray-light'
                )}
                disabled={disabled}
            >
                <span
                    aria-hidden="true"
                    className={`${
                        enabled === true ? 'translate-x-7' : 'translate-x-1'
                    }
                pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
            </Switch>
        </div>
    );
};
