import { Listbox } from '@headlessui/react';
import { ReactComponent as KeyboardArrowDown } from '@material-design-icons/svg/round/keyboard_arrow_down.svg';
import { ReactComponent as KeyboardArrowUp } from '@material-design-icons/svg/round/keyboard_arrow_up.svg';
import { FC, useReducer } from 'react';
import { SelectProps } from './Select.types';
import { cn } from '@/utils';

export const Select: FC<SelectProps> = ({
    variant = 'primary',
    onChange,
    className,
    buttonClassName,
    arrowClassName,
    options,
    optionsClassName,
    optionClassName,
    defaultValue,
    uniqueKey,
    placeholder,
    disabled
}) => {
    const variantClass = {
        primary:
            'bg-white text-gray-dark placeholder:text-gray-neutral border-gray-light disabled:bg-gray-light disabled:placeholder:text-gray-middle disabled:border-gray-neutral',
        gray: 'bg-gray-light text-primary placeholder:text-gray-middle border-gray-light disabled:bg-gray-neutral disabled:placeholder:text-gray-dark disabled:border-gray-middle'
    };

    const reducer = (
        state: {
            selected: { name: string; value: string };
            uniqKeyOld: string;
        },
        action: {
            type: 'SET_SELECTED_VALUE' | 'SET_KEY';
            selected?: { name: string; value: string };
            uniqKeyOld?: string;
        }
    ) => {
        switch (action.type) {
            case 'SET_SELECTED_VALUE':
                return {
                    ...state,
                    selected: action.selected!
                };

            case 'SET_KEY':
                return {
                    selected: options.find(
                        (option) => option.value === defaultValue
                    ) ?? { name: '', value: '' },
                    uniqKeyOld: action.uniqKeyOld!
                };

            default:
                return state;
        }
    };
    const [{ selected, uniqKeyOld }, dispatch] = useReducer(reducer, {
        selected: options.find((option) => option.value === defaultValue) ?? {
            name: '',
            value: ''
        },
        uniqKeyOld: ''
    });

    if (uniqueKey !== undefined && uniqKeyOld !== uniqueKey) {
        dispatch({
            type: 'SET_KEY',
            uniqKeyOld: uniqueKey ?? ''
        });
    }

    return (
        <div className={cn('w-full h-full text-sm', className)}>
            <Listbox
                disabled={disabled}
                value={selected}
                onChange={(e) => {
                    onChange(e);
                    dispatch({ type: 'SET_SELECTED_VALUE', selected: e });
                }}
            >
                <div className="relative w-full">
                    <Listbox.Button
                        placeholder={placeholder}
                        className={cn(
                            'w-full h-9 pl-4 pr-8 py-1.5 rounded border-2 text-left',
                            variantClass[variant],
                            buttonClassName
                        )}
                    >
                        <span>{selected.name}</span>
                        <span
                            className={cn(
                                'absolute inset-y-0 right-0 flex flex-col items-center justify-center pr-3 pointer-events-none',
                                arrowClassName
                            )}
                        >
                            <KeyboardArrowUp className="w-3 h-3 fill-gray-dark" />
                            <KeyboardArrowDown className="w-3 h-3 fill-gray-dark" />
                        </span>
                    </Listbox.Button>
                </div>
                <Listbox.Options
                    className={cn(
                        'z-30 absolute mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                        optionsClassName
                    )}
                >
                    {options.map((option, optionIdx) => (
                        <Listbox.Option
                            key={optionIdx}
                            className={({ active }) =>
                                cn(
                                    'relative cursor-default select-none py-2 px-4 text-left',
                                    active && 'bg-gray-light',
                                    optionClassName
                                )
                            }
                            value={option}
                        >
                            {option.name}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </Listbox>
        </div>
    );
};
