import { FC, ReactNode, Suspense as _Suspense } from 'react';
import { Loading } from '@/components/modules';

const SuspenseFallback = (): ReactNode => {
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <Loading />
        </div>
    );
};

type SuspenseProps = {
    children: ReactNode;
    fallback?: ReactNode;
};

export const Suspense: FC<SuspenseProps> = (props) => {
    const { children, fallback = SuspenseFallback() } = props;

    // eslint-disable-next-line react/jsx-pascal-case
    return <_Suspense fallback={fallback}>{children}</_Suspense>;
};
