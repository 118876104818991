import { rest } from 'msw';
import {
    identificationDocumentBackImgSrc,
    identificationDocumentFrontImgSrc
} from '../constants';
const userURL = process.env.REACT_APP_USER_API_URL!;

export const usersHandlers = [
    rest.post(`${userURL}/users/:id`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                firstName: '太郎',
                lastName: '山田',
                phoneticFirstName: 'タロウ',
                phoneticLastName: 'ヤマダ',
                prefecture: '東京都',
                address: '渋谷区1-1-1',
                gender: 0,
                birthDate: '1993/01/01',
                myNumber: '123456789012',
                identificationDocumentFrontImgSrc:
                    identificationDocumentFrontImgSrc,
                identificationDocumentBackImgSrc:
                    identificationDocumentBackImgSrc,
                expiredResidenceCard: '1993/01/01',
                residenceCardFrontImgSrc: '/images/example.jpg',
                residenceCardBackImgSrc: '/images/example.jpg',
                extraImgSrc: '/images/example.jpg',
                checkStatus: 0,
                phoneNumber: '09012345678',
                emergencyPhoneNumber: '09012345678',
                mailAddress: 'user@example.com',
                lastUpdaterName: '田村 花子',
                isInBlackList: false,
                leaveStatus: 0,
                taxPaymentType: '甲',
                note: '備考です'
            })
        );
    }),
    rest.put(`${userURL}/users/:id`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.get(`${userURL}/users/:id`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                firstName: '太郎',
                lastName: '山田',
                phoneticFirstName: 'たろう',
                phoneticLastName: 'やまだ',
                postalCode: '4510041',
                prefecture: '東京都',
                address: '渋谷区1-1-1',
                gender: 1,
                birthDate: '1993/01/01',
                myNumber: '123456789012',
                identificationDocumentFrontImgSrc:
                    identificationDocumentFrontImgSrc,
                identificationDocumentBackImgSrc:
                    identificationDocumentBackImgSrc,
                expiredResidenceCard: '2023/10/30',
                residenceCardFrontImgSrc: null,
                residenceCardBackImgSrc: null,
                extraImgSrc: identificationDocumentFrontImgSrc,
                checkStatus: 1,
                phoneNumber: '090-1234-5678',
                emergencyPhoneNumber: null,
                mailAddress: 'user@example.com',
                lastUpdaterName: '田村花子',
                isInBlackList: false,
                leaveStatus: 2,
                taxPaymentType: null,
                note: '備考です'
            })
        );
    }),
    rest.put(`${userURL}/users/:id`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                firstName: '太郎',
                lastName: '山田',
                phoneticFirstName: 'タロウ',
                phoneticLastName: 'ヤマダ',
                postalCode: '4510041',
                prefecture: '東京都',
                address: '渋谷区1-1-1',
                gender: 0,
                birthDate: '1993/01/01',
                myNumber: '123456789012',
                identificationDocumentFrontImgSrc: '/images/example.jpg',
                identificationDocumentBackImgSrc: '/images/example.jpg',
                expiredResidenceCard: '1993/01/01',
                residenceCardFrontImgSrc: '/images/example.jpg',
                residenceCardBackImgSrc: '/images/example.jpg',
                extraImgSrc: '/images/example.jpg',
                checkStatus: 0,
                phoneNumber: '09012345678',
                emergencyPhoneNumber: '09012345678',
                mailAddress: 'user@example.com',
                lastUpdaterName: '田村 花子',
                isInBlackList: false,
                leaveStatus: 0,
                taxPaymentType: '丙',
                note: '備考です'
            })
        );
    }),
    rest.put(`${userURL}/users/:id/password`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.put(`${userURL}/users/:id/email`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.post(`${userURL}/users/:id/leave`, (_req, res, ctx) => {
        return res(ctx.status(500));
    })
];
