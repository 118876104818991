import { rest } from 'msw';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const calendarHandlers = [
    rest.get(`${employeeURL}/calendar`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json([
                {
                    subjectId: 1,
                    status: 0,
                    implementationDate: '2023/11/29',
                    jobTitles: [
                        {
                            name: 'ベルト盛り付け',
                            beyondAssignedNumber: 11,
                            recruitmentCapacity: 15,
                            isClosed: true,
                            totalNumbers: [
                                {
                                    totalRecruitmentCapacity: 11,
                                    totalAssignedNumber: 2,
                                    totalConfirmedNumber: 3,
                                    totalProvisionalHiredNumber: 3,
                                    totalHiredNumber: 3,
                                    subjectBeltCapacities: [
                                        {
                                            beltName: 'A',
                                            timeSlot: 0,
                                            recruitmentCapacity: 10,
                                            assignedNumber: 2,
                                            confirmedNumber: 3,
                                            provisionalHiredNumber: 3,
                                            hiredNumber: 3
                                        },
                                        {
                                            beltName: 'A',
                                            timeSlot: 1,
                                            recruitmentCapacity: 1,
                                            assignedNumber: 0,
                                            confirmedNumber: 0,
                                            provisionalHiredNumber: 0,
                                            hiredNumber: 0
                                        }
                                    ]
                                },
                                {
                                    totalRecruitmentCapacity: 4,
                                    totalAssignedNumber: 0,
                                    totalConfirmedNumber: 0,
                                    totalProvisionalHiredNumber: 0,
                                    totalHiredNumber: 0,
                                    subjectBeltCapacities: [
                                        {
                                            beltName: 'B',
                                            timeSlot: 0,
                                            recruitmentCapacity: 3,
                                            assignedNumber: 0,
                                            confirmedNumber: 0,
                                            provisionalHiredNumber: 0,
                                            hiredNumber: 0
                                        },
                                        {
                                            beltName: 'B',
                                            timeSlot: 1,
                                            recruitmentCapacity: 1,
                                            assignedNumber: 0,
                                            confirmedNumber: 0,
                                            provisionalHiredNumber: 0,
                                            hiredNumber: 0
                                        }
                                    ]
                                },
                                {
                                    totalRecruitmentCapacity: 0,
                                    totalAssignedNumber: 0,
                                    totalConfirmedNumber: 0,
                                    totalProvisionalHiredNumber: 0,
                                    totalHiredNumber: 0,
                                    subjectBeltCapacities: [
                                        {
                                            beltName: 'C',
                                            timeSlot: 0,
                                            recruitmentCapacity: null,
                                            assignedNumber: 0,
                                            confirmedNumber: 0,
                                            provisionalHiredNumber: 0,
                                            hiredNumber: 0
                                        },
                                        {
                                            beltName: 'C',
                                            timeSlot: 1,
                                            recruitmentCapacity: null,
                                            assignedNumber: 0,
                                            confirmedNumber: 0,
                                            provisionalHiredNumber: 0,
                                            hiredNumber: 0
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            name: '調理補助',
                            beyondAssignedNumber: 4,
                            recruitmentCapacity: 10,
                            isClosed: true,
                            totalNumbers: [
                                {
                                    totalRecruitmentCapacity: 10,
                                    totalAssignedNumber: 1,
                                    totalConfirmedNumber: 1,
                                    totalProvisionalHiredNumber: 1,
                                    totalHiredNumber: 1,
                                    subjectBeltCapacities: [
                                        {
                                            beltName: '調理補助',
                                            timeSlot: 0,
                                            recruitmentCapacity: 5,
                                            assignedNumber: 1,
                                            confirmedNumber: 1,
                                            provisionalHiredNumber: 1,
                                            hiredNumber: 1
                                        },
                                        {
                                            beltName: '調理補助',
                                            timeSlot: 1,
                                            recruitmentCapacity: 3,
                                            assignedNumber: 0,
                                            confirmedNumber: 0,
                                            provisionalHiredNumber: 0,
                                            hiredNumber: 0
                                        },
                                        {
                                            beltName: '調理補助',
                                            timeSlot: 2,
                                            recruitmentCapacity: 2,
                                            assignedNumber: 0,
                                            confirmedNumber: 0,
                                            provisionalHiredNumber: 0,
                                            hiredNumber: 0
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            name: 'おせち',
                            beyondAssignedNumber: 0,
                            recruitmentCapacity: null,
                            isClosed: true,
                            totalNumbers: [
                                {
                                    totalRecruitmentCapacity: null,
                                    totalAssignedNumber: 0,
                                    totalConfirmedNumber: 0,
                                    totalProvisionalHiredNumber: 0,
                                    totalHiredNumber: 0,
                                    subjectBeltCapacities: null
                                }
                            ]
                        }
                    ]
                },
                {
                    subjectId: 1,
                    status: 1,
                    implementationDate: '2024/07/27',
                    jobTitles: [
                        {
                            name: 'ベルト盛り付け',
                            beyondAssignedNumber: 11,
                            recruitmentCapacity: 15,
                            isClosed: true,
                            totalNumbers: [
                                {
                                    totalRecruitmentCapacity: 11,
                                    totalAssignedNumber: 2,
                                    totalConfirmedNumber: 3,
                                    totalProvisionalHiredNumber: 3,
                                    totalHiredNumber: 3,
                                    subjectBeltCapacities: [
                                        {
                                            beltName: 'A',
                                            timeSlot: 0,
                                            recruitmentCapacity: 10,
                                            assignedNumber: 2,
                                            confirmedNumber: 3,
                                            provisionalHiredNumber: 3,
                                            hiredNumber: 3
                                        },
                                        {
                                            beltName: 'A',
                                            timeSlot: 1,
                                            recruitmentCapacity: 1,
                                            assignedNumber: 0,
                                            confirmedNumber: 0,
                                            provisionalHiredNumber: 0,
                                            hiredNumber: 0
                                        }
                                    ]
                                },
                                {
                                    totalRecruitmentCapacity: 4,
                                    totalAssignedNumber: 0,
                                    totalConfirmedNumber: 0,
                                    totalProvisionalHiredNumber: 0,
                                    totalHiredNumber: 0,
                                    subjectBeltCapacities: [
                                        {
                                            beltName: 'B',
                                            timeSlot: 0,
                                            recruitmentCapacity: 3,
                                            assignedNumber: 0,
                                            confirmedNumber: 0,
                                            provisionalHiredNumber: 0,
                                            hiredNumber: 0
                                        },
                                        {
                                            beltName: 'B',
                                            timeSlot: 1,
                                            recruitmentCapacity: 1,
                                            assignedNumber: 0,
                                            confirmedNumber: 0,
                                            provisionalHiredNumber: 0,
                                            hiredNumber: 0
                                        }
                                    ]
                                },
                                {
                                    totalRecruitmentCapacity: 0,
                                    totalAssignedNumber: 0,
                                    totalConfirmedNumber: 0,
                                    totalProvisionalHiredNumber: 0,
                                    totalHiredNumber: 0,
                                    subjectBeltCapacities: [
                                        {
                                            beltName: 'C',
                                            timeSlot: 0,
                                            recruitmentCapacity: null,
                                            assignedNumber: 0,
                                            confirmedNumber: 0,
                                            provisionalHiredNumber: 0,
                                            hiredNumber: 0
                                        },
                                        {
                                            beltName: 'C',
                                            timeSlot: 1,
                                            recruitmentCapacity: null,
                                            assignedNumber: 0,
                                            confirmedNumber: 0,
                                            provisionalHiredNumber: 0,
                                            hiredNumber: 0
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            name: '調理補助',
                            beyondAssignedNumber: 4,
                            recruitmentCapacity: 10,
                            isClosed: true,
                            totalNumbers: [
                                {
                                    totalRecruitmentCapacity: 10,
                                    totalAssignedNumber: 1,
                                    totalConfirmedNumber: 1,
                                    totalProvisionalHiredNumber: 1,
                                    totalHiredNumber: 1,
                                    subjectBeltCapacities: [
                                        {
                                            beltName: '調理補助',
                                            timeSlot: 0,
                                            recruitmentCapacity: 5,
                                            assignedNumber: 1,
                                            confirmedNumber: 1,
                                            provisionalHiredNumber: 1,
                                            hiredNumber: 1
                                        },
                                        {
                                            beltName: '調理補助',
                                            timeSlot: 1,
                                            recruitmentCapacity: 3,
                                            assignedNumber: 0,
                                            confirmedNumber: 0,
                                            provisionalHiredNumber: 0,
                                            hiredNumber: 0
                                        },
                                        {
                                            beltName: '調理補助',
                                            timeSlot: 2,
                                            recruitmentCapacity: 2,
                                            assignedNumber: 0,
                                            confirmedNumber: 0,
                                            provisionalHiredNumber: 0,
                                            hiredNumber: 0
                                        }
                                    ]
                                }
                            ]
                        }
                        // {
                        //     name: 'おせち',
                        //     beyondAssignedNumber: 0,
                        //     recruitmentCapacity: null,
                        //     isClosed: true,
                        //     totalNumbers: [
                        //         {
                        //             totalRecruitmentCapacity: null,
                        //             totalAssignedNumber: 0,
                        //             totalConfirmedNumber: 0,
                        //             totalProvisionalHiredNumber: 0,
                        //             totalHiredNumber: 0,
                        //             subjectBeltCapacities: null
                        //         }
                        //     ]
                        // }
                    ]
                },
                {
                    subjectId: 1,
                    status: 2,
                    implementationDate: '2024/07/02',
                    jobTitles: [
                        {
                            name: 'ベルト盛り付け',
                            isClosed: false,
                            beyondAssignedNumber: null,
                            recruitmentCapacity: 30
                        },
                        {
                            name: 'おせち',
                            isClosed: false,
                            beyondAssignedNumber: null,
                            recruitmentCapacity: 30
                        },
                        {
                            name: '調理補助',
                            isClosed: false,
                            beyondAssignedNumber: 10,
                            recruitmentCapacity: 30
                        }
                    ],
                    belts: [
                        {
                            timeSlot: 1,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 1,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'C',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        }
                    ]
                },
                {
                    subjectId: 1,
                    status: 0,
                    implementationDate: '2024/07/13',
                    jobTitles: [
                        {
                            name: 'ベルト盛り付け',
                            isClosed: true,
                            beyondAssignedNumber: 30,
                            recruitmentCapacity: 30
                        },
                        {
                            name: 'おせち',
                            isClosed: false,
                            beyondAssignedNumber: null,
                            recruitmentCapacity: null
                        },
                        {
                            name: '調理補助',
                            isClosed: false,
                            beyondAssignedNumber: 10,
                            recruitmentCapacity: 30
                        }
                    ],
                    belts: [
                        {
                            timeSlot: 1,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 1,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'C',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        }
                    ]
                },
                {
                    subjectId: 1,
                    status: 0,
                    isCancelled: false,
                    implementationDate: '2024/07/24',
                    jobTitles: [
                        {
                            name: 'ベルト盛り付け',
                            isClosed: true,
                            beyondAssignedNumber: 30,
                            recruitmentCapacity: 30
                        },
                        {
                            name: 'おせち',
                            isClosed: false,
                            beyondAssignedNumber: null,
                            recruitmentCapacity: null
                        },
                        {
                            name: '調理補助',
                            isClosed: false,
                            beyondAssignedNumber: 10,
                            recruitmentCapacity: 30
                        }
                    ],
                    belts: [
                        {
                            timeSlot: 1,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 1,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'C',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        }
                    ]
                },
                {
                    subjectId: 1,
                    status: 0,
                    isCancelled: true,
                    implementationDate: '2024/07/23',
                    jobTitles: [
                        {
                            name: 'ベルト盛り付け',
                            isClosed: true,
                            beyondAssignedNumber: 30,
                            recruitmentCapacity: 30
                        },
                        {
                            name: 'おせち',
                            isClosed: false,
                            beyondAssignedNumber: null,
                            recruitmentCapacity: null
                        },
                        {
                            name: '調理補助',
                            isClosed: false,
                            beyondAssignedNumber: 10,
                            recruitmentCapacity: 30
                        }
                    ],
                    belts: [
                        {
                            timeSlot: 1,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 1,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'C',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        }
                    ]
                },
                {
                    subjectId: 2,
                    status: 0,
                    implementationDate: '2024/07/23',
                    jobTitles: [
                        {
                            name: 'ベルト盛り付け',
                            isClosed: true,
                            beyondAssignedNumber: 30,
                            recruitmentCapacity: 30
                        },
                        {
                            name: 'おせち',
                            isClosed: false,
                            beyondAssignedNumber: null,
                            recruitmentCapacity: null
                        },
                        {
                            name: '調理補助',
                            isClosed: false,
                            beyondAssignedNumber: 10,
                            recruitmentCapacity: 30
                        }
                    ],
                    belts: [
                        {
                            timeSlot: 1,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 1,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'C',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        }
                    ]
                },
                {
                    subjectId: 1,
                    status: 0,
                    implementationDate: '2024/07/17',
                    jobTitles: [
                        {
                            name: 'ベルト盛り付け',
                            isClosed: true,
                            beyondAssignedNumber: 30,
                            recruitmentCapacity: 30
                        },
                        {
                            name: 'おせち',
                            isClosed: false,
                            beyondAssignedNumber: null,
                            recruitmentCapacity: null
                        },
                        {
                            name: '調理補助',
                            isClosed: false,
                            beyondAssignedNumber: 10,
                            recruitmentCapacity: 30
                        }
                    ],
                    belts: [
                        {
                            timeSlot: 1,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 1,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'C',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        }
                    ]
                },
                {
                    subjectId: 1,
                    status: 0,
                    implementationDate: '2024/07/19',
                    jobTitles: [
                        {
                            name: 'ベルト盛り付け',
                            isClosed: true,
                            beyondAssignedNumber: 30,
                            recruitmentCapacity: 30
                        },
                        {
                            name: 'おせち',
                            isClosed: false,
                            beyondAssignedNumber: null,
                            recruitmentCapacity: null
                        },
                        {
                            name: '調理補助',
                            isClosed: false,
                            beyondAssignedNumber: 10,
                            recruitmentCapacity: 30
                        }
                    ],
                    belts: [
                        {
                            timeSlot: 1,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 1,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'C',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        }
                    ]
                },
                {
                    subjectId: 1,
                    status: 0,
                    implementationDate: '2024/07/20',
                    jobTitles: [
                        {
                            name: 'ベルト盛り付け',
                            isClosed: true,
                            beyondAssignedNumber: 30,
                            recruitmentCapacity: 30
                        },
                        {
                            name: 'おせち',
                            isClosed: false,
                            beyondAssignedNumber: null,
                            recruitmentCapacity: null
                        },
                        {
                            name: '調理補助',
                            isClosed: false,
                            beyondAssignedNumber: 10,
                            recruitmentCapacity: 30
                        }
                    ],
                    belts: [
                        {
                            timeSlot: 1,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 1,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'C',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        }
                    ]
                },
                {
                    subjectId: 1,
                    status: 0,
                    implementationDate: '2024/07/21',
                    jobTitles: [
                        {
                            name: 'ベルト盛り付け',
                            isClosed: true,
                            beyondAssignedNumber: 30,
                            recruitmentCapacity: 30
                        },
                        {
                            name: 'おせち',
                            isClosed: false,
                            beyondAssignedNumber: null,
                            recruitmentCapacity: null
                        },
                        {
                            name: '調理補助',
                            isClosed: false,
                            beyondAssignedNumber: 10,
                            recruitmentCapacity: 30
                        }
                    ],
                    belts: [
                        {
                            timeSlot: 1,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 1,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'C',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        }
                    ]
                },
                {
                    subjectId: 1,
                    status: 0,
                    implementationDate: '2024/05/26',
                    jobTitles: [
                        {
                            name: 'ベルト盛り付け',
                            isClosed: true,
                            beyondAssignedNumber: 30,
                            recruitmentCapacity: 30
                        },
                        {
                            name: 'おせち',
                            isClosed: false,
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            name: '調理補助',
                            isClosed: false,
                            beyondAssignedNumber: 10,
                            recruitmentCapacity: 30
                        }
                    ],
                    belts: [
                        {
                            timeSlot: 1,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 1,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'A',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'B',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        },
                        {
                            timeSlot: 2,
                            name: 'C',
                            beyondAssignedNumber: 5,
                            recruitmentCapacity: 30
                        }
                    ]
                }
            ])
        );
    }),
    rest.delete(`${employeeURL}/auth`, (_req, res, ctx) => {
        document.cookie = 'idToken=;';
        return res(ctx.status(200));
    })
];
