export const getPagerDisplay = ({
    pagerSize,
    currentPage,
    dataLength
}: {
    pagerSize: number;
    currentPage: number;
    dataLength: number;
}) => {
    const labels: number[] = [];
    const maxPage = Math.floor((dataLength - 1) / pagerSize);

    const labelMinValue =
        currentPage === 0
            ? currentPage
            : currentPage === 1
            ? currentPage - 1
            : maxPage - currentPage === 1
            ? Math.max(currentPage - 3, 0)
            : maxPage === currentPage
            ? Math.max(currentPage - 4, 0)
            : currentPage - 2;

    const labelMaxValue = Math.min(labelMinValue + 4, maxPage);

    for (let i = labelMinValue; i <= labelMaxValue; i++) {
        labels.push(i + 1);
    }

    return {
        currentPageMin: Math.min(pagerSize * currentPage + 1, dataLength),
        currentPageMax: Math.min(pagerSize * (currentPage + 1), dataLength),
        maxPage: maxPage,
        labels: labels
    };
};
