import { rest } from 'msw';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const employeesHandlers = [
    rest.put(`${employeeURL}/employees/:id/password`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.get(`${employeeURL}/employees/:id`, (req, res, ctx) => {
        const { id } = req.params;
        if (id === 'test1') {
            return res(
                ctx.status(200),
                ctx.json({
                    id: 1,
                    firstName: '太郎',
                    lastName: '山田',
                    phoneticFirstName: 'タロウ',
                    phoneticLastName: 'ヤマダ',
                    mailAddress: 'employee@example.com',
                    employeeType: '管理者',
                    employeeTypeId: 1,
                    note: '備考です'
                })
            );
        } else {
            return res(
                ctx.status(200),
                ctx.json({
                    id: 3,
                    firstName: '太郎',
                    lastName: '山田',
                    phoneticFirstName: 'タロウ',
                    phoneticLastName: 'ヤマダ',
                    mailAddress: 'employee@example.com',
                    employeeType: '閲覧者',
                    employeeTypeId: 3,
                    note: '備考です'
                })
            );
        }
    }),
    rest.put(`${employeeURL}/employees/:id`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                firstName: '太郎',
                lastName: '山田',
                phoneticFirstName: 'タロウ',
                phoneticLastName: 'ヤマダ',
                mailAddress: 'employee@example.com',
                employeeType: '管理者',
                employeeTypeId: 1,
                note: null
            })
        );
    }),
    rest.delete(`${employeeURL}/employees/:id`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                firstName: '太郎',
                lastName: '山田',
                phoneticFirstName: 'タロウ',
                phoneticLastName: 'ヤマダ',
                mailAddress: 'employee@example.com',
                employeeType: '管理者',
                employeeTypeId: 1,
                note: null
            })
        );
    }),
    rest.post(`${employeeURL}/employees`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.get(`${employeeURL}/employees`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json([
                {
                    id: 1,
                    firstName: '太郎',
                    lastName: '山田',
                    phoneticFirstName: 'タロウ',
                    phoneticLastName: 'ヤマダ',
                    mailAddress: 'employee@example.com',
                    employeeType: '管理者',
                    note: '備考です'
                },
                {
                    id: 2,
                    firstName: '次郎',
                    lastName: '山田',
                    phoneticFirstName: 'ジロウ',
                    phoneticLastName: 'ヤマダ',
                    mailAddress: 'employee2@example.com',
                    employeeType: '閲覧者',
                    note: '備考です'
                },
                {
                    id: 3,
                    firstName: '三郎',
                    lastName: '山田',
                    phoneticFirstName: 'サブロウ',
                    phoneticLastName: 'ヤマダ',
                    mailAddress: 'employee3@example.com',
                    employeeType: '管理者',
                    note: '備考です'
                },
                {
                    id: 4,
                    firstName: '士郎',
                    lastName: '山田',
                    phoneticFirstName: 'シロウ',
                    phoneticLastName: 'ヤマダ',
                    mailAddress: 'employee4@example.com',
                    employeeType: '管理者',
                    note: '備考です'
                },
                {
                    id: 5,
                    firstName: '吾郎',
                    lastName: '山田',
                    phoneticFirstName: 'ゴロウ',
                    phoneticLastName: 'ヤマダ',
                    mailAddress: 'employee5@example.com',
                    employeeType: '管理者',
                    note: '備考です'
                },
                {
                    id: 6,
                    firstName: '花子',
                    lastName: '八尾彦',
                    phoneticFirstName: 'ハナコ',
                    phoneticLastName: 'ヤオヒコ',
                    mailAddress: 'employee6@example.com',
                    employeeType: '編集者',
                    note: '備考です'
                },
                {
                    id: 7,
                    firstName: 'ニコ',
                    lastName: '八尾彦',
                    phoneticFirstName: 'ニコ',
                    phoneticLastName: 'ヤオヒコ',
                    mailAddress: 'employee7@example.com',
                    employeeType: '編集者',
                    note: '備考です'
                },
                {
                    id: 8,
                    firstName: 'みつこ',
                    lastName: '八尾彦',
                    phoneticFirstName: 'ミツコ',
                    phoneticLastName: 'ヤオヒコ',
                    mailAddress: 'employee8@example.com',
                    employeeType: '管理者',
                    note: '備考です'
                }
            ])
        );
    })
];
