import { rest } from 'msw';
const userURL = process.env.REACT_APP_USER_API_URL!;

export const subjectsHandlers = [
    rest.get(`${userURL}/subjects/:id`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                title: '弁当作成',
                createdDate: '2023/04/01',
                implementationDate: '2023/07/30',
                deadline: '2023/04/01',
                beyondAssignedNumber: 20,
                applicantNumber: 160,
                jobTitles: [
                    {
                        name: 'ベルト盛り付け',
                        recruitmentCapacity: 100,
                        applicantNumber: 50,
                        isClosed: true,
                        slotLabels: [
                            {
                                timeSlot: 0,
                                name: '朝イチ〜ラスト（17時予定）まで',
                                id: 0
                            },
                            { timeSlot: 0, name: '6:30〜ラストまで', id: 1 },
                            { timeSlot: 1, name: '6:30〜昼まで', id: 2 },
                            { timeSlot: 2, name: '昼〜ラストまで', id: 3 }
                        ]
                    },
                    {
                        name: 'おせち',
                        recruitmentCapacity: 30,
                        isClosed: false
                    },
                    {
                        name: '調理補助',
                        recruitmentCapacity: 30,
                        applicantNumber: 35,
                        isClosed: false,
                        slotLabels: [
                            {
                                timeSlot: 0,
                                name: '朝イチ〜ラスト（17時予定）まで',
                                id: 0
                            },
                            { timeSlot: 0, name: '6:30〜ラストまで', id: 1 },
                            { timeSlot: 1, name: '朝イチ〜昼まで', id: 2 },
                            { timeSlot: 1, name: '6:30〜昼まで', id: 3 },
                            { timeSlot: 2, name: '昼〜ラストまで', id: 4 }
                        ]
                    }
                ],
                transportationCost: '500円',
                workLocation: '八百彦本店調理場',
                hourlyWage: '1,020円',
                paymentMethod: '当日払い',
                personInCharge: '田中 一郎',
                status: 0,
                isCancelled: false,
                isOutputtedCsv: false,
                note: '備考です'
            })
        );
    }),
    rest.get(`${userURL}/subjects`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                currentPage: 1,
                totalItems: 0,
                items: [
                    {
                        id: 1,
                        title: '弁当作成',
                        createdDate: '2023/04/01',
                        implementationDate: '2023/07/08',
                        deadline: '2023/07/01',
                        beyondAssignedNumber: 100,
                        recruitmentCapacity: 100,
                        personInCharge: '田中 一郎',
                        status: 0,
                        isCancelled: false,
                        isOutputtedCsv: false
                    },
                    {
                        id: 2,
                        title: '弁当作成',
                        createdDate: '2023/04/01',
                        implementationDate: '2023/07/07',
                        deadline: '2023/06/30',
                        beyondAssignedNumber: 0,
                        recruitmentCapacity: 100,
                        personInCharge: '田中 一郎',
                        status: 0,
                        isCancelled: false,
                        isOutputtedCsv: false
                    },
                    {
                        id: 3,
                        title: '弁当作成',
                        createdDate: '2023/04/01',
                        implementationDate: '2023/04/01',
                        deadline: '2023/04/01',
                        beyondAssignedNumber: 90,
                        recruitmentCapacity: 100,
                        personInCharge: '田中 一郎',
                        status: 0,
                        isCancelled: false,
                        isOutputtedCsv: false
                    },
                    {
                        id: 4,
                        title: '弁当作成',
                        createdDate: '2023/04/01',
                        implementationDate: '2023/07/07',
                        deadline: '2023/06/30',
                        beyondAssignedNumber: 20,
                        recruitmentCapacity: 100,
                        personInCharge: '田中 一郎',
                        status: 0,
                        isCancelled: false,
                        isOutputtedCsv: false
                    },
                    {
                        id: 5,
                        title: '弁当作成',
                        createdDate: '2023/04/01',
                        implementationDate: '2023/06/22',
                        deadline: '2023/06/15',
                        beyondAssignedNumber: 15,
                        recruitmentCapacity: 100,
                        personInCharge: '田中 一郎',
                        status: 0,
                        isCancelled: false,
                        isOutputtedCsv: false
                    },
                    {
                        id: 6,
                        title: 'おせちおせちおせちおせちおせちおせちおせちおせちおせちおせちおせちおせちおせちおせちおせちおせちおせち',
                        createdDate: '2023/04/01',
                        implementationDate: '2023/05/15',
                        deadline: '2023/05/30',
                        beyondAssignedNumber: 60,
                        recruitmentCapacity: 100,
                        personInCharge: '田中 一郎',
                        status: 1,
                        isCancelled: false,
                        isOutputtedCsv: false
                    },
                    {
                        id: 7,
                        title: '弁当作成弁当作成弁当作成弁当作成弁当作成',
                        createdDate: '2023/04/01',
                        implementationDate: '2023/04/01',
                        deadline: '2023/04/01',
                        beyondAssignedNumber: 20,
                        recruitmentCapacity: 100,
                        personInCharge: '田中 一郎',
                        status: 2,
                        isCancelled: false,
                        isOutputtedCsv: false
                    },
                    {
                        id: 8,
                        title: '弁当作成',
                        createdDate: '2023/04/01',
                        implementationDate: '2023/04/01',
                        deadline: '2023/04/01',
                        beyondAssignedNumber: 20,
                        recruitmentCapacity: 100,
                        personInCharge: '田中 一郎',
                        status: 2,
                        isCancelled: false,
                        isOutputtedCsv: false
                    },
                    {
                        id: 9,
                        title: '弁当作成',
                        createdDate: '2023/04/01',
                        implementationDate: '2023/04/01',
                        deadline: '2023/04/01',
                        beyondAssignedNumber: 20,
                        recruitmentCapacity: 100,
                        personInCharge: '田中 一郎',
                        status: 2,
                        isCancelled: true,
                        isOutputtedCsv: false
                    },
                    {
                        id: 9,
                        title: '弁当作成',
                        createdDate: '2023/04/01',
                        implementationDate: '2023/04/01',
                        deadline: '2023/04/01',
                        beyondAssignedNumber: 20,
                        recruitmentCapacity: 100,
                        isNotified: true,
                        personInCharge: '田中 一郎',
                        status: 2,
                        isCancelled: false,
                        isOutputtedCsv: true,
                        note: '備考です'
                    },
                    {
                        id: 10,
                        title: '弁当作成',
                        createdDate: '2023/04/01',
                        implementationDate: '2023/04/01',
                        deadline: '2023/04/01',
                        beyondAssignedNumber: 20,
                        recruitmentCapacity: 100,
                        personInCharge: '田中 一郎',
                        status: 2,
                        isCancelled: false,
                        isOutputtedCsv: true
                    }
                ]
            })
        );
    })
];
