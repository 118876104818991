import { rest } from 'msw';
const userURL = process.env.REACT_APP_USER_API_URL!;

export const passwordHandlers = [
    rest.put(`${userURL}/password/reset/:uuid`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.post(`${userURL}/password/reset`, (_req, res, ctx) => {
        return res(ctx.status(200));
    })
];
