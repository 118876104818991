export const downloadFile = async ({
    downloadFileName,
    blob
}: {
    downloadFileName: string;
    blob: Blob;
}) => {
    const downloadDummyTag = document.createElement('a');
    downloadDummyTag.href = URL.createObjectURL(blob);
    downloadDummyTag.download = downloadFileName;
    downloadDummyTag.click();
    return {};
};
