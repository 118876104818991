import qs from 'qs';
import { sanitizeObjectProperties } from '../sanitizeObjectProperties';

export const fetchOptions = {
    validateStatus: (status: number) =>
        (status >= 200 && status < 300) ||
        status === 409 ||
        status === 404 ||
        status === 422,
    transformRequest: [
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data: any) => {
            if (data !== undefined) {
                return JSON.stringify(
                    sanitizeObjectProperties(JSON.parse(data))
                );
            }
        }
    ],
    paramsSerializer: (params: { [key: string]: unknown }) =>
        qs.stringify(sanitizeObjectProperties(params), {
            arrayFormat: 'comma'
        })
};
