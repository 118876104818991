import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/index.css';
import { RouterProvider } from 'react-router-dom';
import { server } from '@/apis/mocks/server';
import { AppProvider } from '@/providers';
import { appRouter } from '@/routes';

if (process.env.NODE_ENV === 'development') {
    server.start();
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <AppProvider>
            <RouterProvider router={appRouter} />
        </AppProvider>
    </React.StrictMode>
);
