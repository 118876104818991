import { useCallback } from 'react';

export const useAllSelectionsHandler = () => {
    const checkAll = useCallback((checked: boolean) => {
        const selectors = document.querySelectorAll(`input[name="select"]`);
        selectors.forEach((selector) => {
            (selector as HTMLInputElement).checked = checked;
        });
    }, []);

    const checkAllClear = useCallback(() => {
        checkAll(false);
        const selector = document.querySelector(`input[name="selectAll"]`);
        if (selector !== null) {
            (selector as HTMLInputElement).checked = false;
        }
    }, [checkAll]);

    const getSelectedIds = useCallback(() => {
        const getCheckedElements = () => {
            const elements = document.querySelectorAll(`input[name="select"]`);
            const checkedElements = Array.from(elements).filter(
                (element) => (element as HTMLInputElement).checked
            );
            return checkedElements;
        };

        const selected = getCheckedElements();

        const selectedIds = selected.map(
            (selector) => (selector as HTMLInputElement).parentElement!.id
        );
        return selectedIds;
    }, []);
    return { checkAll, checkAllClear, getSelectedIds };
};
