import { rest } from 'msw';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const subjectJobCapacityHandlers = [
    rest.patch(
        `${employeeURL}/subject_job_capacities/:subjectId/:subjectJobTitle`,
        (_req, res, ctx) => {
            return res(ctx.status(200));
        }
    ),
    rest.get(
        `${employeeURL}/subject_job_capacities/:subjectId/:subjectJobTitle`,
        (_req, res, ctx) => {
            return res(
                ctx.status(200),
                ctx.json({
                    recruitmentCapacity: 30,
                    assignedNumber: 14,
                    confirmedNumber: 5,
                    provisionalHiredNumber: 5,
                    hiredNumber: 5
                })
            );
        }
    )
];
