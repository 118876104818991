export const stringToBoolean = (
    str: string | undefined
): boolean | undefined => {
    switch (str) {
        case 'true':
            return true;
        case 'false':
            return false;
        default:
            return undefined;
    }
};
