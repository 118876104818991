import { ComponentPropsWithRef, FC, forwardRef } from 'react';
import { cn } from '@/utils';

type InputProps = {
    variant?: 'primary' | 'gray';
} & ComponentPropsWithRef<'input'>;

export const Input: FC<InputProps> = forwardRef(
    ({ variant = 'primary', ...props }, ref) => {
        const variantClass = {
            primary:
                'bg-white text-gray-dark placeholder:text-gray-neutral border-gray-light disabled:bg-gray-light disabled:placeholder:text-gray-middle disabled:border-gray-neutral',
            gray: 'bg-gray-light text-primary placeholder:text-gray-middle border-gray-light disabled:bg-gray-neutral disabled:placeholder:text-gray-dark disabled:border-gray-middle'
        };
        return (
            <input
                {...props}
                className={cn(
                    'h-full w-full rounded border-2 px-4 py-2 text-sm',
                    variantClass[variant],
                    'data-error:border-2 data-error:border-primary data-error:bg-primary-light',
                    props.className
                )}
                ref={ref}
            />
        );
    }
);
