import { ReactComponent as Logout } from '@material-design-icons/svg/outlined/logout.svg';
import { FC, useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { HeaderProps } from './Header.types';

import { Caution, Dialog } from '@/components/modules';
import { useApiClient } from '@/hooks';
import { cn } from '@/utils';

export const Header: FC<HeaderProps> = ({ employeeType }) => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const { apiClientForEmployee } = useApiClient();
    const navigate = useNavigate();

    const mutationSignOutAsEmployee = useMutation(() =>
        apiClientForEmployee().auth.delete()
    );

    const onClickLogout = useCallback(() => {
        setIsDialogOpen(true);
    }, []);
    const handleClick = useCallback(async () => {
        await mutationSignOutAsEmployee.mutateAsync();

        navigate('/auth/login');
    }, [mutationSignOutAsEmployee, navigate]);

    const HeaderContents = ({
        label,
        path,
        className
    }: {
        label: string;
        path: string;
        className?: string;
    }) => (
        <Link
            className={cn(
                'h-full hover:bg-white/30 hover:font-bold text-xs w-28 flex items-center justify-center',
                className
            )}
            to={path}
        >
            {label}
        </Link>
    );

    const HeaderDropdownContents = ({
        label,
        path,
        dropdownList
    }: {
        label: string;
        path: string;
        dropdownList: {
            label: string;
            path: string;
        }[];
    }) => (
        <ul className="h-full">
            <li className="relative w-full h-full group">
                <HeaderContents path={path} label={label} className="w-36" />
                <ul className="absolute left-0 rounded-b shadow-md opacity-0 pointer-events-none top-full bg-primary-shadow text-gray-dark group-hover:opacity-100 transition-opacity duration-300 group-hover:pointer-events-auto">
                    {dropdownList.map((dropdown) => (
                        <li className="w-36" key={dropdown.label}>
                            <Link
                                to={dropdown.path}
                                className="flex items-center px-3 py-4 text-xs hover:bg-white/30 hover:font-bold"
                            >
                                {dropdown.label}
                            </Link>
                        </li>
                    ))}
                </ul>
            </li>
        </ul>
    );

    return (
        <>
            <Dialog
                type="double"
                isOpen={isDialogOpen}
                onClose={() => {
                    setIsDialogOpen(false);
                }}
                labelRight="ログアウト"
                onClickRight={handleClick}
                labelLeft="戻る"
                onClickLeft={() => {
                    setIsDialogOpen(false);
                }}
            >
                <div className="flex items-center justify-center">
                    <Caution className="mr-3" />
                    <div className="flex flex-col gap-2">
                        <p>ログアウトします</p>
                    </div>
                </div>
            </Dialog>

            <div className="fixed z-50 flex items-center justify-between w-screen h-16 px-6 text-white box-border bg-primary min-w-3xl">
                <div className="flex items-center h-full">
                    <Link to="/">
                        <p className="font-semibold">八百彦労務管理システム</p>
                    </Link>
                    <div className="flex items-center h-full pl-20">
                        <HeaderContents label="スタッフ" path="/staff" />
                        <HeaderContents label="募集案件" path="/subject" />
                        <HeaderContents label="お知らせ" path="/notice" />
                        <HeaderDropdownContents
                            label="管理者メニュー"
                            path="/admin"
                            dropdownList={
                                employeeType === '管理者'
                                    ? [
                                          {
                                              label: '管理ユーザー一覧',
                                              path: '/admin'
                                          },
                                          {
                                              label: '管理ユーザー新規登録',
                                              path: '/admin/new'
                                          },
                                          {
                                              label: 'テンプレート編集',
                                              path: '/template'
                                          },
                                          {
                                              label: 'パスワード変更',
                                              path: '/setting/password-change'
                                          }
                                      ]
                                    : employeeType === '編集者'
                                    ? [
                                          {
                                              label: '管理ユーザー一覧',
                                              path: '/admin'
                                          },
                                          {
                                              label: 'テンプレート編集',
                                              path: '/template'
                                          },
                                          {
                                              label: 'パスワード変更',
                                              path: '/setting/password-change'
                                          }
                                      ]
                                    : // employeeTypeがundefinedか閲覧者の場合
                                      [
                                          {
                                              label: '管理ユーザー一覧',
                                              path: '/admin'
                                          },
                                          {
                                              label: 'パスワード変更',
                                              path: '/setting/password-change'
                                          }
                                      ]
                            }
                        />
                    </div>
                </div>
                <span onClick={onClickLogout}>
                    <Logout className="cursor-pointer fill-white" />
                </span>
            </div>
        </>
    );
};
