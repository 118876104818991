import { ReactComponent as KeyboardArrowDown } from '@material-design-icons/svg/outlined/keyboard_arrow_down.svg';
import { ReactComponent as KeyboardArrowUp } from '@material-design-icons/svg/outlined/keyboard_arrow_up.svg';
import { FC } from 'react';
import { SearchPanelProps } from './SearchPanel.types';

export const SearchPanel: FC<SearchPanelProps> = ({
    children,
    title = '検索条件',
    isOpen,
    onClick
}) => {
    return (
        <div className="flex flex-col w-full border-2 rounded-md border-primary">
            <div
                className="flex w-full p-2 cursor-pointer bg-primary"
                onClick={onClick}
            >
                {isOpen ? (
                    <KeyboardArrowUp className="w-6 h-6 fill-white" />
                ) : (
                    <KeyboardArrowDown className="w-6 h-6 fill-white" />
                )}
                <p className="ml-2 text-white">{title}</p>
            </div>
            {isOpen && (
                <div className="flex flex-col p-5 gap-3">{children}</div>
            )}
        </div>
    );
};
