import type { AspidaClient, BasicHeaders } from 'aspida'
import { dataToURLString } from 'aspida'
import type { Methods as Methods0 } from './applications'
import type { Methods as Methods1 } from './applications/_subjectId@number/_userId@number'
import type { Methods as Methods2 } from './applications/_subjectId@number/_userId@number/hire/_uuid@string'
import type { Methods as Methods3 } from './auth'
import type { Methods as Methods4 } from './auth/check'
import type { Methods as Methods5 } from './auth/email'
import type { Methods as Methods6 } from './auth/resend-code'
import type { Methods as Methods7 } from './auth/verify'
import type { Methods as Methods8 } from './notifications/user-updated'
import type { Methods as Methods9 } from './password/reset'
import type { Methods as Methods10 } from './password/reset/_uuid@string'
import type { Methods as Methods11 } from './subject_belt_capacities'
import type { Methods as Methods12 } from './subject_job_titles'
import type { Methods as Methods13 } from './subject_slot_labels'
import type { Methods as Methods14 } from './subjects'
import type { Methods as Methods15 } from './subjects/_id@number'
import type { Methods as Methods16 } from './users/_id@number'
import type { Methods as Methods17 } from './users/_id@number/calendar'
import type { Methods as Methods18 } from './users/_id@number/email'
import type { Methods as Methods19 } from './users/_id@number/leave'
import type { Methods as Methods20 } from './users/_id@number/password'
import type { Methods as Methods21 } from './verify'

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'https://staff.yaohiko.net/user-api' : baseURL).replace(/\/$/, '')
  const PATH0 = '/applications'
  const PATH1 = '/hire'
  const PATH2 = '/auth'
  const PATH3 = '/auth/check'
  const PATH4 = '/auth/email'
  const PATH5 = '/auth/resend-code'
  const PATH6 = '/auth/verify'
  const PATH7 = '/notifications/user-updated'
  const PATH8 = '/password/reset'
  const PATH9 = '/subject_belt_capacities'
  const PATH10 = '/subject_job_titles'
  const PATH11 = '/subject_slot_labels'
  const PATH12 = '/subjects'
  const PATH13 = '/users'
  const PATH14 = '/calendar'
  const PATH15 = '/email'
  const PATH16 = '/leave'
  const PATH17 = '/password'
  const PATH18 = '/verify'
  const GET = 'GET'
  const POST = 'POST'
  const PUT = 'PUT'
  const DELETE = 'DELETE'

  return {
    applications: {
      _subjectId: (val1: number) => {
        const prefix1 = `${PATH0}/${val1}`

        return {
          _userId: (val2: number) => {
            const prefix2 = `${prefix1}/${val2}`

            return {
              hire: {
                _uuid: (val4: string) => {
                  const prefix4 = `${prefix2}${PATH1}/${val4}`

                  return {
                    /**
                     * uuidが有効かの確認
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<void, BasicHeaders, Methods2['get']['status']>(prefix, prefix4, GET, option).send(),
                    /**
                     * uuidが有効かの確認
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<void, BasicHeaders, Methods2['get']['status']>(prefix, prefix4, GET, option).send().then(r => r.body),
                    /**
                     * スタッフが採用通知メールの労働条件通知書を確認し、採用ステータスになる
                     */
                    put: (option?: { config?: T | undefined } | undefined) =>
                      fetch<void, BasicHeaders, Methods2['put']['status']>(prefix, prefix4, PUT, option).send(),
                    /**
                     * スタッフが採用通知メールの労働条件通知書を確認し、採用ステータスになる
                     */
                    $put: (option?: { config?: T | undefined } | undefined) =>
                      fetch<void, BasicHeaders, Methods2['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix4}`
                  }
                }
              },
              /**
               * 応募情報を1件更新します
               * @returns 成功
               */
              put: (option: { body: Methods1['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods1['put']['resBody'], BasicHeaders, Methods1['put']['status']>(prefix, prefix2, PUT, option).json(),
              /**
               * 応募情報を1件更新します
               * @returns 成功
               */
              $put: (option: { body: Methods1['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods1['put']['resBody'], BasicHeaders, Methods1['put']['status']>(prefix, prefix2, PUT, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}`
            }
          }
        }
      },
      /**
       * スタッフが案件に応募します
       * @returns 成功
       */
      post: (option: { body: Methods0['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json(),
      /**
       * スタッフが案件に応募します
       * @returns 成功
       */
      $post: (option: { body: Methods0['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH0}`
    },
    auth: {
      check: {
        /**
         * IDトークンが有効かどうかを判定します
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<void, BasicHeaders, Methods4['get']['status']>(prefix, PATH3, GET, option).send(),
        /**
         * IDトークンが有効かどうかを判定します
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<void, BasicHeaders, Methods4['get']['status']>(prefix, PATH3, GET, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH3}`
      },
      email: {
        /**
         * Cognitoへスタッフを登録し、スタッフのメールアドレスへ認証コードを送信します
         */
        post: (option: { body: Methods5['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods5['post']['status']>(prefix, PATH4, POST, option).send(),
        /**
         * Cognitoへスタッフを登録し、スタッフのメールアドレスへ認証コードを送信します
         */
        $post: (option: { body: Methods5['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods5['post']['status']>(prefix, PATH4, POST, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH4}`
      },
      resend_code: {
        /**
         * スタッフのメールアドレスへ認証コードを再送信します(ログイン前)
         */
        post: (option: { body: Methods6['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods6['post']['status']>(prefix, PATH5, POST, option).send(),
        /**
         * スタッフのメールアドレスへ認証コードを再送信します(ログイン前)
         */
        $post: (option: { body: Methods6['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods6['post']['status']>(prefix, PATH5, POST, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH5}`
      },
      verify: {
        /**
         * 認証コードをもとにメールアドレスを有効にする(ログイン前)
         * @returns 成功
         */
        post: (option: { body: Methods7['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods7['post']['resBody'], BasicHeaders, Methods7['post']['status']>(prefix, PATH6, POST, option).json(),
        /**
         * 認証コードをもとにメールアドレスを有効にする(ログイン前)
         * @returns 成功
         */
        $post: (option: { body: Methods7['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods7['post']['resBody'], BasicHeaders, Methods7['post']['status']>(prefix, PATH6, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH6}`
      },
      /**
       * Cookieに入っているリフレッシュトークンを元にIDトークンを更新します
       */
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<void, BasicHeaders, Methods3['get']['status']>(prefix, PATH2, GET, option).send(),
      /**
       * Cookieに入っているリフレッシュトークンを元にIDトークンを更新します
       */
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<void, BasicHeaders, Methods3['get']['status']>(prefix, PATH2, GET, option).send().then(r => r.body),
      /**
       * スタッフのCognitoへのサインイン
       * @returns 成功
       */
      post: (option: { body: Methods3['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods3['post']['resBody'], BasicHeaders, Methods3['post']['status']>(prefix, PATH2, POST, option).json(),
      /**
       * スタッフのCognitoへのサインイン
       * @returns 成功
       */
      $post: (option: { body: Methods3['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods3['post']['resBody'], BasicHeaders, Methods3['post']['status']>(prefix, PATH2, POST, option).json().then(r => r.body),
      /**
       * Cognitoからサインアウト
       */
      delete: (option?: { config?: T | undefined } | undefined) =>
        fetch<void, BasicHeaders, Methods3['delete']['status']>(prefix, PATH2, DELETE, option).send(),
      /**
       * Cognitoからサインアウト
       */
      $delete: (option?: { config?: T | undefined } | undefined) =>
        fetch<void, BasicHeaders, Methods3['delete']['status']>(prefix, PATH2, DELETE, option).send().then(r => r.body),
      $path: () => `${prefix}${PATH2}`
    },
    notifications: {
      user_updated: {
        /**
         * スタッフ情報変更通知の送信
         * @returns 成功
         */
        post: (option: { body: Methods8['post']['reqBody'], query: Methods8['post']['query'], config?: T | undefined }) =>
          fetch<Methods8['post']['resBody'], BasicHeaders, Methods8['post']['status']>(prefix, PATH7, POST, option).json(),
        /**
         * スタッフ情報変更通知の送信
         * @returns 成功
         */
        $post: (option: { body: Methods8['post']['reqBody'], query: Methods8['post']['query'], config?: T | undefined }) =>
          fetch<Methods8['post']['resBody'], BasicHeaders, Methods8['post']['status']>(prefix, PATH7, POST, option).json().then(r => r.body),
        $path: (option?: { method: 'post'; query: Methods8['post']['query'] } | undefined) =>
          `${prefix}${PATH7}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      }
    },
    password: {
      reset: {
        _uuid: (val2: string) => {
          const prefix2 = `${PATH8}/${val2}`

          return {
            /**
             * パスワード変更(ログイン前)
             */
            put: (option: { body: Methods10['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods10['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * パスワード変更(ログイン前)
             */
            $put: (option: { body: Methods10['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods10['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        /**
         * スタッフのパスワード変更用URLのメール送付を受け付けます
         */
        post: (option: { body: Methods9['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods9['post']['status']>(prefix, PATH8, POST, option).send(),
        /**
         * スタッフのパスワード変更用URLのメール送付を受け付けます
         */
        $post: (option: { body: Methods9['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods9['post']['status']>(prefix, PATH8, POST, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH8}`
      }
    },
    subject_belt_capacities: {
      /**
       * 案件ベルト情報一覧を取得します
       * @returns 成功
       */
      get: (option: { query: Methods11['get']['query'], config?: T | undefined }) =>
        fetch<Methods11['get']['resBody'], BasicHeaders, Methods11['get']['status']>(prefix, PATH9, GET, option).json(),
      /**
       * 案件ベルト情報一覧を取得します
       * @returns 成功
       */
      $get: (option: { query: Methods11['get']['query'], config?: T | undefined }) =>
        fetch<Methods11['get']['resBody'], BasicHeaders, Methods11['get']['status']>(prefix, PATH9, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods11['get']['query'] } | undefined) =>
        `${prefix}${PATH9}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    subject_job_titles: {
      /**
       * 案件職種一覧を取得します
       * @returns 成功
       */
      get: (option: { query: Methods12['get']['query'], config?: T | undefined }) =>
        fetch<Methods12['get']['resBody'], BasicHeaders, Methods12['get']['status']>(prefix, PATH10, GET, option).json(),
      /**
       * 案件職種一覧を取得します
       * @returns 成功
       */
      $get: (option: { query: Methods12['get']['query'], config?: T | undefined }) =>
        fetch<Methods12['get']['resBody'], BasicHeaders, Methods12['get']['status']>(prefix, PATH10, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods12['get']['query'] } | undefined) =>
        `${prefix}${PATH10}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    subject_slot_labels: {
      /**
       * 案件時間帯ラベル一覧を取得します
       * @returns 成功
       */
      get: (option: { query: Methods13['get']['query'], config?: T | undefined }) =>
        fetch<Methods13['get']['resBody'], BasicHeaders, Methods13['get']['status']>(prefix, PATH11, GET, option).json(),
      /**
       * 案件時間帯ラベル一覧を取得します
       * @returns 成功
       */
      $get: (option: { query: Methods13['get']['query'], config?: T | undefined }) =>
        fetch<Methods13['get']['resBody'], BasicHeaders, Methods13['get']['status']>(prefix, PATH11, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods13['get']['query'] } | undefined) =>
        `${prefix}${PATH11}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    subjects: {
      _id: (val1: number) => {
        const prefix1 = `${PATH12}/${val1}`

        return {
          /**
           * 指定の案件情報を取得します
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods15['get']['resBody'], BasicHeaders, Methods15['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * 指定の案件情報を取得します
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods15['get']['resBody'], BasicHeaders, Methods15['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      /**
       * 案件情報の一覧を取得します
       * @returns 成功
       */
      get: (option: { query: Methods14['get']['query'], config?: T | undefined }) =>
        fetch<Methods14['get']['resBody'], BasicHeaders, Methods14['get']['status']>(prefix, PATH12, GET, option).json(),
      /**
       * 案件情報の一覧を取得します
       * @returns 成功
       */
      $get: (option: { query: Methods14['get']['query'], config?: T | undefined }) =>
        fetch<Methods14['get']['resBody'], BasicHeaders, Methods14['get']['status']>(prefix, PATH12, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods14['get']['query'] } | undefined) =>
        `${prefix}${PATH12}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    users: {
      _id: (val1: number) => {
        const prefix1 = `${PATH13}/${val1}`

        return {
          calendar: {
            /**
             * マイページのカレンダーを取得
             * @returns 成功
             */
            get: (option: { query: Methods17['get']['query'], config?: T | undefined }) =>
              fetch<Methods17['get']['resBody'], BasicHeaders, Methods17['get']['status']>(prefix, `${prefix1}${PATH14}`, GET, option).json(),
            /**
             * マイページのカレンダーを取得
             * @returns 成功
             */
            $get: (option: { query: Methods17['get']['query'], config?: T | undefined }) =>
              fetch<Methods17['get']['resBody'], BasicHeaders, Methods17['get']['status']>(prefix, `${prefix1}${PATH14}`, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods17['get']['query'] } | undefined) =>
              `${prefix}${prefix1}${PATH14}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
          },
          email: {
            /**
             * スタッフのメールアドレスへ認証コードを送信します(ログイン中)
             */
            put: (option: { body: Methods18['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods18['put']['status']>(prefix, `${prefix1}${PATH15}`, PUT, option).send(),
            /**
             * スタッフのメールアドレスへ認証コードを送信します(ログイン中)
             */
            $put: (option: { body: Methods18['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods18['put']['status']>(prefix, `${prefix1}${PATH15}`, PUT, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH15}`
          },
          leave: {
            /**
             * スタッフが退会申請の送信をする
             * @returns 成功
             */
            post: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods19['post']['resBody'], BasicHeaders, Methods19['post']['status']>(prefix, `${prefix1}${PATH16}`, POST, option).json(),
            /**
             * スタッフが退会申請の送信をする
             * @returns 成功
             */
            $post: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods19['post']['resBody'], BasicHeaders, Methods19['post']['status']>(prefix, `${prefix1}${PATH16}`, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH16}`
          },
          password: {
            /**
             * パスワード変更(ログイン中)
             */
            put: (option: { body: Methods20['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods20['put']['status']>(prefix, `${prefix1}${PATH17}`, PUT, option).send(),
            /**
             * パスワード変更(ログイン中)
             */
            $put: (option: { body: Methods20['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods20['put']['status']>(prefix, `${prefix1}${PATH17}`, PUT, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH17}`
          },
          /**
           * スタッフを新規作成します
           * @returns 成功
           */
          post: (option: { body: Methods16['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods16['post']['resBody'], BasicHeaders, Methods16['post']['status']>(prefix, prefix1, POST, option).json(),
          /**
           * スタッフを新規作成します
           * @returns 成功
           */
          $post: (option: { body: Methods16['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods16['post']['resBody'], BasicHeaders, Methods16['post']['status']>(prefix, prefix1, POST, option).json().then(r => r.body),
          /**
           * 指定のスタッフを取得します
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods16['get']['resBody'], BasicHeaders, Methods16['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * 指定のスタッフを取得します
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods16['get']['resBody'], BasicHeaders, Methods16['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          /**
           * スタッフ情報を更新します
           * @returns 成功
           */
          put: (option: { body: Methods16['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods16['put']['resBody'], BasicHeaders, Methods16['put']['status']>(prefix, prefix1, PUT, option).json(),
          /**
           * スタッフ情報を更新します
           * @returns 成功
           */
          $put: (option: { body: Methods16['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods16['put']['resBody'], BasicHeaders, Methods16['put']['status']>(prefix, prefix1, PUT, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      }
    },
    verify: {
      /**
       * 認証コードをもとにメールアドレスを有効にする(ログイン中)
       */
      post: (option: { body: Methods21['post']['reqBody'], config?: T | undefined }) =>
        fetch<void, BasicHeaders, Methods21['post']['status']>(prefix, PATH18, POST, option).send(),
      /**
       * 認証コードをもとにメールアドレスを有効にする(ログイン中)
       */
      $post: (option: { body: Methods21['post']['reqBody'], config?: T | undefined }) =>
        fetch<void, BasicHeaders, Methods21['post']['status']>(prefix, PATH18, POST, option).send().then(r => r.body),
      $path: () => `${prefix}${PATH18}`
    }
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
