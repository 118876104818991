import { FC } from 'react';
import { SearchResultTableProps } from './SearchResultTable.types';
import { cn } from '@/utils';

export const SearchResultTable: FC<SearchResultTableProps> = ({
    headerList,
    headerListClassName,
    children,
    className
}) => {
    return (
        <table
            className={cn(
                'w-full text-xs border-separate border-spacing-0',
                className
            )}
        >
            <thead>
                <tr className="sticky top-0 z-20 font-semibold text-center text-primary bg-primary-light">
                    {headerList.map((header, i) => (
                        <td
                            key={'tableHeader' + i}
                            className={cn(
                                i === 0
                                    ? 'rounded-tl-md pl-4 pr-2.5 py-2.5 border-l border-t'
                                    : i + 1 === headerList.length
                                    ? 'border-r border-t pr-4 pl-2.5 py-2.5  rounded-tr-md'
                                    : 'border-t p-2.5',
                                'border-primary whitespace-pre-wrap',
                                headerListClassName
                            )}
                        >
                            {header}
                        </td>
                    ))}
                </tr>
            </thead>
            <tbody className="bg-white text-gray-dark">
                {children}
                <tr className="h-1.5">
                    {headerList.map((_, i) => (
                        <td
                            key={'tableBottom' + i}
                            className={cn(
                                i === 0
                                    ? 'rounded-bl-md  border-l  border-b'
                                    : i + 1 === headerList.length
                                    ? 'border-r border-b rounded-br-md'
                                    : 'border-b ',
                                'border-primary'
                            )}
                        ></td>
                    ))}
                </tr>
            </tbody>
        </table>
    );
};
