import { rest } from 'msw';
import {
    identificationDocumentBackImgSrc,
    identificationDocumentFrontImgSrc
} from '../constants';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const usersHandlers = [
    rest.get(`${employeeURL}/users`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                totalItems: 0,
                currentPage: 1,
                items: [
                    {
                        id: 11,
                        firstName: '太郎',
                        lastName: '山田',
                        phoneticFirstName: 'タロウ',
                        phoneticLastName: 'ヤマダ',
                        prefecture: '東京都',
                        address: '渋谷区1-1-1',
                        gender: 0,
                        birthDate: '1993/01/01',
                        myNumber: '123456789012',
                        identificationDocumentFrontImgSrc:
                            '/images/example.jpg',
                        identificationDocumentBackImgSrc: '/images/example.jpg',
                        expiredResidenceCard: null,
                        residenceCardFrontImgSrc: '/images/example.jpg',
                        residenceCardBackImgSrc: '/images/example.jpg',
                        extraImgSrc: '/images/example.jpg',
                        checkStatus: 0,
                        phoneNumber: '09012345678',
                        emergencyPhoneNumber: '09012345678',
                        mailAddress: 'user@example.com',
                        lastUpdaterName: '田村 花子',
                        isInBlackList: false,
                        leaveStatus: 0,
                        taxPaymentType: '甲',
                        note: '備考です'
                    },
                    {
                        id: 12,
                        firstName: '花子',
                        lastName: '山田',
                        phoneticFirstName: 'ハナコ',
                        phoneticLastName: 'ヤマダ',
                        prefecture: '東京都',
                        address: '渋谷区1-1-1',
                        gender: 1,
                        birthDate: '1993/02/02',
                        myNumber: '223456789012',
                        identificationDocumentFrontImgSrc:
                            '/images/example.jpg',
                        identificationDocumentBackImgSrc: '/images/example.jpg',
                        expiredResidenceCard: '2023/06/01',
                        residenceCardFrontImgSrc: '/images/example.jpg',
                        residenceCardBackImgSrc: '/images/example.jpg',
                        extraImgSrc: '/images/example.jpg',
                        checkStatus: 0,
                        phoneNumber: '09012345678',
                        emergencyPhoneNumber: '09012345678',
                        mailAddress: 'user@example.com',
                        lastUpdaterName: null,
                        isInBlackList: false,
                        leaveStatus: 0,
                        taxPaymentType: '乙',
                        note: '備考です'
                    },
                    {
                        id: 13,
                        firstName: '三郎',
                        lastName: '佐藤',
                        phoneticFirstName: 'サブロウ',
                        phoneticLastName: 'サトウ',
                        prefecture: '東京都',
                        address: '渋谷区1-1-1',
                        gender: 0,
                        birthDate: '1983/03/03',
                        myNumber: '323456789012',
                        identificationDocumentFrontImgSrc:
                            '/images/example.jpg',
                        identificationDocumentBackImgSrc: '/images/example.jpg',
                        expiredResidenceCard: null,
                        residenceCardFrontImgSrc: '/images/example.jpg',
                        residenceCardBackImgSrc: '/images/example.jpg',
                        extraImgSrc: '/images/example.jpg',
                        checkStatus: 1,
                        phoneNumber: '09012345678',
                        emergencyPhoneNumber: '09012345678',
                        mailAddress: 'user@example.com',
                        lastUpdaterName: '田村 花子',
                        isInBlackList: true,
                        leaveStatus: 0,
                        taxPaymentType: '甲',
                        note: '備考です'
                    },
                    {
                        id: 14,
                        firstName: '四郎',
                        lastName: '田中',
                        phoneticFirstName: 'シロウ',
                        phoneticLastName: 'タナカ',
                        prefecture: '東京都',
                        address: '文京区1-1-1',
                        gender: 0,
                        birthDate: '1937/06/15',
                        myNumber: '423456789012',
                        identificationDocumentFrontImgSrc:
                            '/images/example.jpg',
                        identificationDocumentBackImgSrc: '/images/example.jpg',
                        expiredResidenceCard: null,
                        residenceCardFrontImgSrc: '/images/example.jpg',
                        residenceCardBackImgSrc: '/images/example.jpg',
                        extraImgSrc: '/images/example.jpg',
                        checkStatus: 2,
                        phoneNumber: '09012345678',
                        emergencyPhoneNumber: '09012345678',
                        mailAddress: 'user@example.com',
                        lastUpdaterName: null,
                        isInBlackList: false,
                        leaveStatus: 0,
                        taxPaymentType: '甲',
                        note: '85歳'
                    },
                    {
                        id: 15,
                        firstName: '五郎',
                        lastName: '齋藤',
                        phoneticFirstName: 'ゴロウ',
                        phoneticLastName: 'サイトウ',
                        prefecture: '東京都',
                        address: '世田谷区1-1-1',
                        gender: 0,
                        birthDate: '1986/03/01',
                        myNumber: '523456789012',
                        identificationDocumentFrontImgSrc:
                            '/images/example.jpg',
                        identificationDocumentBackImgSrc: '/images/example.jpg',
                        expiredResidenceCard: null,
                        residenceCardFrontImgSrc: '/images/example.jpg',
                        residenceCardBackImgSrc: '/images/example.jpg',
                        extraImgSrc: '/images/example.jpg',
                        checkStatus: 1,
                        phoneNumber: '09012345678',
                        emergencyPhoneNumber: '09012345678',
                        mailAddress: 'user@example.com',
                        lastUpdaterName: '田村 花子',
                        isInBlackList: false,
                        leaveStatus: 2,
                        taxPaymentType: '甲',
                        note: '37歳'
                    },
                    {
                        id: 16,
                        firstName: '六実',
                        lastName: '加藤',
                        phoneticFirstName: 'ムツミ',
                        phoneticLastName: 'カトウ',
                        prefecture: '東京都',
                        address: '港区1-1-1',
                        gender: 1,
                        birthDate: '1989/06/01',
                        myNumber: '623456789012',
                        identificationDocumentFrontImgSrc:
                            '/images/example.jpg',
                        identificationDocumentBackImgSrc: '/images/example.jpg',
                        expiredResidenceCard: null,
                        residenceCardFrontImgSrc: '/images/example.jpg',
                        residenceCardBackImgSrc: '/images/example.jpg',
                        extraImgSrc: '/images/example.jpg',
                        checkStatus: 1,
                        phoneNumber: '09012345678',
                        emergencyPhoneNumber: '09012345678',
                        mailAddress: 'user@example.com',
                        lastUpdaterName: '木村 一',
                        isInBlackList: false,
                        leaveStatus: 1,
                        taxPaymentType: '甲',
                        note: '33歳'
                    },
                    {
                        id: 17,
                        firstName: 'ナナ',
                        lastName: 'スミス',
                        phoneticFirstName: 'ナナ',
                        phoneticLastName: 'スミス',
                        prefecture: '愛知県',
                        address: '名古屋市1-1-1',
                        gender: 1,
                        birthDate: '1995/07/07',
                        myNumber: '723456789012',
                        identificationDocumentFrontImgSrc:
                            '/images/example.jpg',
                        identificationDocumentBackImgSrc: '/images/example.jpg',
                        expiredResidenceCard: '2023/08/30',
                        residenceCardFrontImgSrc: '/images/example.jpg',
                        residenceCardBackImgSrc: '/images/example.jpg',
                        extraImgSrc: '/images/example.jpg',
                        checkStatus: 1,
                        phoneNumber: '09012345678',
                        emergencyPhoneNumber: '09012345678',
                        mailAddress: 'user@example.com',
                        lastUpdaterName: '木村 一',
                        isInBlackList: false,
                        leaveStatus: 0,
                        taxPaymentType: '丙',
                        note: null
                    }
                ]
            })
        );
    }),
    rest.post(`${employeeURL}/users`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json([
                {
                    id: 1,
                    firstName: '太郎',
                    lastName: '山田',
                    phoneticFirstName: 'タロウ',
                    phoneticLastName: 'ヤマダ',
                    prefecture: '東京都',
                    address: '渋谷区1-1-1',
                    gender: 0,
                    birthDate: '1993/01/01',
                    myNumber: '123456789012',
                    identificationDocumentFrontImgSrc: '/images/example.jpg',
                    identificationDocumentBackImgSrc: '/images/example.jpg',
                    expiredResidenceCard: '1993/01/01',
                    residenceCardFrontImgSrc: '/images/example.jpg',
                    residenceCardBackImgSrc: '/images/example.jpg',
                    extraImgSrc: '/images/example.jpg',
                    checkStatus: 0,
                    phoneNumber: '09012345678',
                    emergencyPhoneNumber: '09012345678',
                    mailAddress: 'user@example.com',
                    lastUpdaterName: '田村 花子',
                    isInBlackList: false,
                    leaveStatus: 0,
                    taxPaymentType: '甲',
                    note: '備考です'
                }
            ])
        );
    }),
    rest.get(`${employeeURL}/users/:id`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                firstName: '太郎',
                lastName: '山田',
                phoneticFirstName: 'たろう',
                phoneticLastName: 'やまだ',
                prefecture: '東京都',
                address: '渋谷区1-1-1',
                gender: 0,
                birthDate: '1993/01/01',
                myNumber: '123456789012',
                identificationDocumentFrontImgSrc:
                    identificationDocumentFrontImgSrc,
                identificationDocumentBackImgSrc:
                    identificationDocumentBackImgSrc,
                expiredResidenceCard: '2024/02/23',
                residenceCardFrontImgSrc: identificationDocumentFrontImgSrc,
                residenceCardBackImgSrc: identificationDocumentBackImgSrc,
                extraImgSrc: null,
                checkStatus: 1,
                phoneNumber: '090-1234-5678',
                emergencyPhoneNumber: '090-2345-6789',
                mailAddress: 'user@example.com',
                lastUpdaterName: '田村花子',
                isInBlackList: false,
                leaveStatus: 1,
                taxPaymentType: null,
                note: '備考です'
            })
        );
    }),
    rest.put(`${employeeURL}/users/:id`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                firstName: '太郎',
                lastName: '山田',
                phoneticFirstName: 'タロウ',
                phoneticLastName: 'ヤマダ',
                prefecture: '東京都',
                address: '渋谷区1-1-1',
                gender: 0,
                birthDate: '1993/01/01',
                myNumber: '123456789012',
                identificationDocumentFrontImgSrc: '/images/example.jpg',
                identificationDocumentBackImgSrc: '/images/example.jpg',
                expiredResidenceCard: '1993/01/01',
                residenceCardFrontImgSrc: '/images/example.jpg',
                residenceCardBackImgSrc: '/images/example.jpg',
                extraImgSrc: '/images/example.jpg',
                checkStatus: 0,
                phoneNumber: '09012345678',
                emergencyPhoneNumber: '09012345678',
                mailAddress: 'user@example.com',
                lastUpdaterName: '田村 花子',
                isInBlackList: false,
                leaveStatus: 0,
                taxPaymentType: '丙',
                note: '備考です'
            })
        );
    }),
    rest.delete(`${employeeURL}/users/:id`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.put(`${employeeURL}/users/:id/leave/approve`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                firstName: '太郎',
                lastName: '山田',
                phoneticFirstName: 'タロウ',
                phoneticLastName: 'ヤマダ',
                prefecture: '東京都',
                address: '渋谷区1-1-1',
                gender: 0,
                birthDate: '1993/01/01',
                myNumber: '123456789012',
                identificationDocumentFrontImgSrc: '/images/example.jpg',
                identificationDocumentBackImgSrc: '/images/example.jpg',
                expiredResidenceCard: '1993/01/01',
                residenceCardFrontImgSrc: '/images/example.jpg',
                residenceCardBackImgSrc: '/images/example.jpg',
                extraImgSrc: '/images/example.jpg',
                checkStatus: 0,
                phoneNumber: '09012345678',
                emergencyPhoneNumber: '09012345678',
                mailAddress: 'user@example.com',
                lastUpdaterName: '田村 花子',
                isInBlackList: false,
                leaveStatus: 2,
                taxPaymentType: '甲',
                note: '備考です'
            })
        );
    }),
    rest.put(`${employeeURL}/users/:id/leave/reject`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                firstName: '太郎',
                lastName: '山田',
                phoneticFirstName: 'タロウ',
                phoneticLastName: 'ヤマダ',
                prefecture: '東京都',
                address: '渋谷区1-1-1',
                gender: 0,
                birthDate: '1993/01/01',
                myNumber: '123456789012',
                identificationDocumentFrontImgSrc: '/images/example.jpg',
                identificationDocumentBackImgSrc: '/images/example.jpg',
                expiredResidenceCard: '1993/01/01',
                residenceCardFrontImgSrc: '/images/example.jpg',
                residenceCardBackImgSrc: '/images/example.jpg',
                extraImgSrc: '/images/example.jpg',
                checkStatus: 0,
                phoneNumber: '09012345678',
                emergencyPhoneNumber: '09012345678',
                mailAddress: 'user@example.com',
                lastUpdaterName: '田村 花子',
                isInBlackList: false,
                leaveStatus: 0,
                taxPaymentType: '甲',
                note: '備考です'
            })
        );
    }),
    rest.put(`${employeeURL}/users/:id/approve`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.put(`${employeeURL}/users/:id/reject`, (_req, res, ctx) => {
        return res(ctx.status(200));
    })
];
