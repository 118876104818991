import { rest } from 'msw';
const userURL = process.env.REACT_APP_USER_API_URL!;

export const mypageCalendarHandlers = [
    rest.get(`${userURL}/users/:id/calendar`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json([
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/01',
                    status: 0,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/02',
                    status: 3,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/03',
                    status: 2,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/04',
                    status: 2,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/05',
                    status: 2,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 0,
                    title: '弁当作成',
                    implementationDate: '2023/10/07',
                    status: 3,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/07',
                    status: 2,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/08',
                    status: 2,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/09',
                    status: 5,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/10',
                    status: 5,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/11',
                    status: 5,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/12',
                    status: 6,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/13',
                    status: 3,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/20',
                    status: 1,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/21',
                    status: 1,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/22',
                    status: 1,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/23',
                    status: 5,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/24',
                    status: 5,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/25',
                    status: 4,
                    applicationJobTitle: '調理補助',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/26',
                    status: 4,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/27',
                    status: 6,
                    applicationJobTitle: 'おせち',
                    applicationTime: '13:00〜17:00',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/28',
                    status: 0,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/10/29',
                    status: 3,
                    applicationJobTitle: 'ベルト盛り付け',
                    applicationTime: '朝からラストまで',
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成弁当作成弁当作成',
                    implementationDate: '2023/10/31',
                    status: 0,
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成弁当作成弁当作成',
                    implementationDate: '2023/10/30',
                    status: 4,
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/08/01',
                    status: 0,
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/08/02',
                    status: 0,
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/08/03',
                    status: 0,
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/08/04',
                    status: 0,
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/08/05',
                    status: 0,
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/08/06',
                    status: 0,
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/08/07',
                    status: 0,
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                },
                {
                    subjectId: 1,
                    title: '弁当作成',
                    implementationDate: '2023/08/08',
                    status: 0,
                    hourlyWage: '1,020円',
                    paymentMethod: '当日払い',
                    workLocation: '八百彦本店調理場'
                }
            ])
        );
    })
];
