import { useAspidaQuery } from '@aspida/react-query';
import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useApiClientNotSignedIn } from '@/hooks';

export const AuthenticatedRoute: FC = () => {
    const { apiClientForEmployeeNotSignedIn } = useApiClientNotSignedIn();

    const { data: authCheckResponse, isLoading: isLoadingEmployeeAuthCheck } =
        useAspidaQuery(apiClientForEmployeeNotSignedIn().auth.check, 'get', {
            useErrorBoundary: (error) =>
                // @ts-expect-error
                error?.response?.status >= 500
        });

    if (isLoadingEmployeeAuthCheck === true) {
        return null;
    }
    if (authCheckResponse?.status !== 200) {
        return <Navigate to="/auth/login" />;
    } else {
        return <Outlet />;
    }
};
