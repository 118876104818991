import { Dialog as Modal } from '@headlessui/react';
import { FC } from 'react';
import { useIsFetching, useIsMutating } from 'react-query';
import { DialogProps } from './Dialog.types';
import { cn } from '@/utils';

export const Dialog: FC<DialogProps> = ({
    type = 'single',
    className,
    childrenClassName,
    isOpen,
    children,
    onClose,
    label,
    onClick,
    labelRight,
    onClickRight,
    labelLeft,
    onClickLeft
}) => {
    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    const isLoading = isFetching + isMutating > 0;

    return (
        <Modal open={isOpen} onClose={isLoading ? () => {} : onClose}>
            <div className="fixed inset-0 z-70 bg-gray/50" />
            <div className="fixed inset-0 flex items-center justify-center z-80">
                <Modal.Panel
                    className={cn(
                        'flex flex-col items-center justify-between max-w-2xl bg-white border-2 min-h-36 min-w-80 rounded-2xl border-primary',
                        className
                    )}
                >
                    <div className="flex flex-col items-center justify-center w-full h-full pt-3 text-sm max-h-contents text-gray-dark">
                        <div
                            className={cn(
                                'h-full px-20 py-8 overflow-auto',
                                childrenClassName
                            )}
                        >
                            {children}
                        </div>
                    </div>
                    {type === 'single' ? (
                        <div
                            className={cn(
                                'flex items-center justify-center w-full h-12 text-sm text-white rounded-b-xl bg-primary',
                                !isLoading &&
                                    'hover:font-semibold cursor-pointer'
                            )}
                            onClick={isLoading ? () => {} : onClick}
                        >
                            {label}
                        </div>
                    ) : (
                        <div className="flex w-full">
                            <div
                                className={cn(
                                    'flex items-center justify-center w-1/2 h-12 text-sm border-t-2 rounded-bl-xl border-primary text-primary',
                                    !isLoading &&
                                        'hover:font-semibold cursor-pointer'
                                )}
                                onClick={isLoading ? () => {} : onClickLeft}
                            >
                                {labelLeft}
                            </div>
                            <div
                                className={cn(
                                    'flex items-center justify-center w-1/2 h-12 text-sm text-white  rounded-br-xl bg-primary',
                                    !isLoading &&
                                        'hover:font-semibold cursor-pointer'
                                )}
                                onClick={isLoading ? () => {} : onClickRight}
                            >
                                {labelRight}
                            </div>
                        </div>
                    )}
                </Modal.Panel>
            </div>
        </Modal>
    );
};
