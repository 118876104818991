import { FC, ReactNode } from 'react';
import { useIsFetching, useIsMutating } from 'react-query';
import { Loading as _Loading } from '@/components/modules';

type LoadingProps = {
    children: ReactNode;
};

export const Loading: FC<LoadingProps> = ({ children }) => {
    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    return (
        <>
            {isFetching + isMutating > 0 && (
                <div className="fixed inset-0 flex flex-col items-center justify-center h-screen z-100 bg-gray-dark/50">
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <_Loading />
                </div>
            )}
            {children}
        </>
    );
};
