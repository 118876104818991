import { ReactComponent as ArrowBack } from '@material-design-icons/svg/filled/arrow_back.svg';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { BackPageProps } from './BackPage.types';
import { cn } from '@/utils';

export const BackPage: FC<BackPageProps> = ({ label, className, ...props }) => {
    return (
        <Link
            {...props}
            className={cn(
                'flex items-center underline-offset-8 text-sm hover:font-semibold hover:underline',
                className
            )}
        >
            <ArrowBack className="w-5 h-5 mr-10 fill-primary" />
            {label}
        </Link>
    );
};
