export const checkWithinTimeRange = ({
    startTime,
    endTime,
    targetStartTime,
    targetEndTime
}: {
    startTime: string;
    endTime: string;
    targetStartTime: string;
    targetEndTime: string;
}) => {
    const [startHour, startMinute] = startTime.split(':');
    const [endHour, endMinute] = endTime.split(':');
    const [targetStartHour, targetStartMinute] = targetStartTime.split(':');
    const [targetEndHour, targetEndMinute] = targetEndTime.split(':');

    const startHourNumber = Number(startHour);
    const startMinuteNumber = Number(startMinute);
    const endHourNumber = Number(endHour);
    const endMinuteNumber = Number(endMinute);
    const targetStartHourNumber = Number(targetStartHour);
    const targetStartMinuteNumber = Number(targetStartMinute);
    const targetEndHourNumber = Number(targetEndHour);
    const targetEndMinuteNumber = Number(targetEndMinute);

    const startTotalMinute = startHourNumber * 60 + startMinuteNumber;
    const endTotalMinute = endHourNumber * 60 + endMinuteNumber;
    const targetStartTotalMinute =
        targetStartHourNumber * 60 + targetStartMinuteNumber;
    const targetEndTotalMinute =
        targetEndHourNumber * 60 + targetEndMinuteNumber;

    if (
        startTotalMinute <= targetStartTotalMinute &&
        targetStartTotalMinute <= endTotalMinute &&
        startTotalMinute <= targetEndTotalMinute &&
        targetEndTotalMinute <= endTotalMinute
    ) {
        return true;
    }
    return false;
};
