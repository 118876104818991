import { Buffer } from 'buffer';

export const explicitUserIdFromJwt = (
    jwt: string | undefined
): number | null => {
    if (jwt != null && jwt !== '') {
        const payload = jwt!.split('.')[1];
        const decoded = Buffer.from(payload, 'base64').toString('utf8');
        return JSON.parse(decoded)['cognito:username'];
    } else {
        return null;
    }
};
