import { useState, FC, useEffect, useReducer } from 'react';
import { ComboBoxProps } from './ComboBox.types';
import { cn, kanaToHira } from '@/utils';

export const ComboBox: FC<ComboBoxProps> = ({
    variant = 'primary',
    className,
    optionsClassName,
    optionClassName,
    defaultValue = '',
    queries,
    onChange,
    uniqueKey,
    ...props
}) => {
    const [showOptions, setShowOptions] = useState(false);
    const inputName = props.name ?? Math.random().toString(36).substring(7);

    const variantClass = {
        primary:
            'bg-white text-gray-dark placeholder:text-gray-neutral disabled:bg-gray-light disabled:placeholder:text-gray-middle disabled:border-gray-neutral',
        gray: 'bg-gray-light text-primary placeholder:text-gray-middle  disabled:bg-gray-neutral disabled:placeholder:text-gray-dark disabled:border-gray-middle',
        'primary-options': 'hover:bg-gray-light',
        'gray-options': 'hover:bg-white'
    };

    const reducer = (
        state: {
            inputQuery: string;
            selectedQuery: string;
            uniqKeyOld: string;
        },
        action: {
            type: 'SET_QUERY' | 'SET_SELECTED_QUERY' | 'SET_KEY';
            inputQuery?: string;
            selectedQuery?: string;
            uniqKeyOld?: string;
        }
    ): { inputQuery: string; selectedQuery: string; uniqKeyOld: string } => {
        switch (action.type) {
            case 'SET_QUERY':
                return {
                    ...state,
                    inputQuery: action.inputQuery!
                };
            case 'SET_SELECTED_QUERY':
                return {
                    ...state,
                    selectedQuery: action.selectedQuery!
                };
            case 'SET_KEY':
                return {
                    inputQuery: defaultValue,
                    selectedQuery: defaultValue,
                    uniqKeyOld: action.uniqKeyOld!
                };
            default:
                return state;
        }
    };

    const [{ inputQuery, selectedQuery, uniqKeyOld }, dispatch] = useReducer(
        reducer,
        {
            inputQuery: defaultValue,
            selectedQuery: defaultValue,
            uniqKeyOld: ''
        }
    );

    // onBlurではリストが非表示になってしまうため、useEffectでイベントを監視
    useEffect(() => {
        let mouseDownEvent: MouseEvent | null = null;

        const handleMouseDown = (e: MouseEvent) => {
            mouseDownEvent = e;
        };

        // ドキュメント全体に対してクリックイベントをリッスン
        const handleClickOutside = () => {
            const inputElement = document.querySelector(
                `input[name="${inputName}"]`
            );

            // オプション選択前に発火させないため、clickではなくmousedownのタイミングで判定
            // inputの入力文字を選択時、clickだとドラッグ終了地点のNodeを取得するためmousedownで開始地点を判定する
            if (
                showOptions === true &&
                inputElement != null &&
                mouseDownEvent != null &&
                !inputElement.contains(mouseDownEvent.target as Node)
            ) {
                // コンボボックス外のクリックでリストを非表示にする
                setShowOptions(false);

                // 選択された値がない場合は表示をクリア
                dispatch({
                    type: 'SET_QUERY',
                    inputQuery: selectedQuery ?? ''
                });
            }
        };

        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('click', handleClickOutside);

        return () => {
            // コンポーネントがアンマウントされるときにイベントリスナーをクリア
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('click', handleClickOutside);
        };
    }, [selectedQuery, showOptions, inputName]);

    const onChangeQuery = (inputQuery: string) => {
        dispatch({
            type: 'SET_QUERY',
            inputQuery: inputQuery
        });

        if (queries.some((query) => query.value === inputQuery) === true) {
            // キー入力にて候補と一致した場合
            onChangeSelectedQuery(inputQuery);
        } else if (selectedQuery !== '') {
            // 候補と一致しない場合、かつ選択未クリア時は選択をクリア
            onChangeSelectedQuery('');
        }
    };

    const onChangeSelectedQuery = (query: string) => {
        dispatch({
            type: 'SET_SELECTED_QUERY',
            selectedQuery: query
        });

        onChange(query);
    };

    if (uniqueKey !== undefined && uniqKeyOld !== uniqueKey) {
        dispatch({
            type: 'SET_KEY',
            inputQuery: defaultValue,
            selectedQuery: defaultValue,
            uniqKeyOld: uniqueKey
        });
    }

    const filteredOptions = queries.filter(
        (query: {
            value: string;
            label: string;
            reading?: string | undefined;
        }) =>
            (
                query.label +
                (query.reading !== undefined
                    ? query.reading + kanaToHira({ str: query.reading })
                    : '')
            ).includes(inputQuery)
    );

    return (
        <div>
            <input
                {...props}
                name={inputName}
                type="text"
                className={cn(
                    'rounded border-2 border-gray-light px-4 py-2 text-sm w-auto',
                    variantClass[variant],
                    'data-error:border-2 data-error:border-primary data-error:bg-primary-light',
                    className
                )}
                value={inputQuery}
                onChange={(e) => {
                    onChangeQuery(e.target.value);
                }}
                onFocus={() => {
                    setShowOptions(true);
                }}
            />
            {showOptions && filteredOptions.length > 0 && (
                <ul
                    className={cn(
                        'mt-1 overflow-auto max-h-60 absolute focus:outline-none rounded border-2 border-gray-light px-4 py-2 text-sm z-30 ',
                        variantClass[variant],
                        optionsClassName
                    )}
                >
                    {filteredOptions.map((query) => (
                        <li
                            className={cn(
                                variantClass[
                                    `${variant as 'primary' | 'gray'}-options`
                                ],
                                'py-2 px-3 h-8',
                                optionClassName
                            )}
                            key={query.value}
                            onClick={() => {
                                onChangeQuery(query.value);
                                setShowOptions(false);
                            }}
                        >
                            {query.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
