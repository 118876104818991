import { applicationsHandlers } from './applications';
import { authHandlers } from './auth';
import { calendarHandlers } from './calendar';
import { emailTemplatesHandlers } from './email_templates';
import { employeesHandlers } from './employees';
import { noticeTargetsHandlers } from './notice_targets';
import { noticeTemplatesHandlers } from './notice_templates';
import { noticesHandlers } from './notices';
import { notificationTemplatesHandlers } from './notification_templates';
import { notificationsHandlers } from './notifications';
import { passwordHandlers } from './password';
import { subjectBeltCapacityHandlers } from './subject_belt_capacities';
import { subjectJobCapacityHandlers } from './subject_job_capacities';
import { subjectJobTitlesHandlers } from './subject_job_titles';
import { subjectSlotTimesHandlers } from './subject_slot_times';
import { subjectsHandlers } from './subjects';
import { templateMetadataHandlers } from './template_metadata';
import { usersHandlers } from './users';

export const employeeHandlers = [
    ...authHandlers,
    ...employeesHandlers,
    ...passwordHandlers,
    ...usersHandlers,
    ...subjectsHandlers,
    ...notificationsHandlers,
    ...templateMetadataHandlers,
    ...notificationTemplatesHandlers,
    ...noticeTemplatesHandlers,
    ...emailTemplatesHandlers,
    ...calendarHandlers,
    ...noticesHandlers,
    ...noticeTargetsHandlers,
    ...applicationsHandlers,
    ...subjectBeltCapacityHandlers,
    ...subjectSlotTimesHandlers,
    ...subjectJobTitlesHandlers,
    ...subjectJobCapacityHandlers
];
