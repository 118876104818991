import { rest } from 'msw';
import { getTokenFromCookie } from '@/utils';
const userURL = process.env.REACT_APP_USER_API_URL!;

export const authHandlers = [
    rest.get(`${userURL}/auth/check`, (_req, res, ctx) => {
        const token = getTokenFromCookie({});
        if (token != null && token !== '') {
            return res(ctx.delay(1000), ctx.status(200));
        } else {
            return res(ctx.delay(1000), ctx.status(401));
        }
    }),
    rest.post(`${userURL}/auth`, (_req, res, ctx) => {
        document.cookie =
            'idToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI3MmEwZjg1OC1kOTgzLTQxYjYtOTI5Mi1kNGM5NTliNDk4NmUiLCJpc3MiOiJodHRwczovL2NvZ25pdG8taWRwLmFwLW5vcnRoZWFzdC0xLmFtYXpvbmF3cy5jb20vYXAtbm9ydGhlYXN0LTFfZDZ1TEZJNmRaIiwiY29nbml0bzp1c2VybmFtZSI6InRlc3QxIiwib3JpZ2luX2p0aSI6Ijk1N2RkNWVkLTU2ZTQtNDRkOS04NDlkLWVhZmFkNTIyYjI5NSIsImF1ZCI6IjRraGMxOTc5MDVzbnRhNTI2MGFuYmJyZmlvIiwiZXZlbnRfaWQiOiI4OTEwZjJiZC1mYjRmLTQ3MGMtOWExZS03NTIzNDVlZDU1M2IiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY2ODQxODc3OCwiZXhwIjoxNjY4NDIyMzc4LCJpYXQiOjE2Njg0MTg3NzgsImp0aSI6ImVhYTdjOWU1LTI5NDMtNGEyOS04NDI2LTgyNzMzMTQ1Y2M5ZSIsImVtYWlsIjoic25zbG9naW4rdGVzdDFAeC1wb2ludC0xLm5ldCJ9.aSR2nlprePnrSH2VDShVgTdMbJeFcBVPQU1JZMddE5Y';
        document.cookie = 'accessToken=accessTokenDummy;';

        return res(
            ctx.status(200),
            ctx.json({ signInStatus: 'USER_INFORMATION_REQUIRED' })
        );
    }),
    rest.delete(`${userURL}/auth`, (_req, res, ctx) => {
        document.cookie = 'idToken=';
        return res(ctx.status(200));
    }),
    rest.post(`${userURL}/auth/verify`, (_req, res, ctx) => {
        document.cookie =
            'idToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI3MmEwZjg1OC1kOTgzLTQxYjYtOTI5Mi1kNGM5NTliNDk4NmUiLCJpc3MiOiJodHRwczovL2NvZ25pdG8taWRwLmFwLW5vcnRoZWFzdC0xLmFtYXpvbmF3cy5jb20vYXAtbm9ydGhlYXN0LTFfZDZ1TEZJNmRaIiwiY29nbml0bzp1c2VybmFtZSI6InRlc3QxIiwib3JpZ2luX2p0aSI6Ijk1N2RkNWVkLTU2ZTQtNDRkOS04NDlkLWVhZmFkNTIyYjI5NSIsImF1ZCI6IjRraGMxOTc5MDVzbnRhNTI2MGFuYmJyZmlvIiwiZXZlbnRfaWQiOiI4OTEwZjJiZC1mYjRmLTQ3MGMtOWExZS03NTIzNDVlZDU1M2IiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY2ODQxODc3OCwiZXhwIjoxNjY4NDIyMzc4LCJpYXQiOjE2Njg0MTg3NzgsImp0aSI6ImVhYTdjOWU1LTI5NDMtNGEyOS04NDI2LTgyNzMzMTQ1Y2M5ZSIsImVtYWlsIjoic25zbG9naW4rdGVzdDFAeC1wb2ludC0xLm5ldCJ9.aSR2nlprePnrSH2VDShVgTdMbJeFcBVPQU1JZMddE5Y';
        document.cookie = 'accessToken=accessTokenDummy;';

        return res(ctx.status(200), ctx.json({ id: 1 }));
    }),
    rest.post(`${userURL}/auth/email`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.post(`${userURL}/auth/resend-code`, (_req, res, ctx) => {
        return res(ctx.status(200));
    })
];
