import aspida from '@aspida/axios';
import axios from 'axios';
import { useCallback, useMemo } from 'react';
import employeeApi from '@/apis/bin/employee_openapi/$api';
import userApi from '@/apis/bin/user_openapi/$api';
import { getTokenFromCookie, fetchOptions } from '@/utils';

const baseURLForEmployee = process.env.REACT_APP_EMPLOYEE_API_URL;
const baseURLForUser = process.env.REACT_APP_USER_API_URL;

export const useApiClientNotSignedIn = () => {
    const token = getTokenFromCookie({});

    const headers = useMemo(
        () => (token != null ? { Authorization: token ?? '' } : undefined),
        [token]
    );

    const apiClientForEmployeeNotSignedIn = useCallback(() => {
        return employeeApi(
            aspida(axios, {
                baseURL: baseURLForEmployee,
                ...fetchOptions,
                headers
            })
        );
    }, [headers]);

    const apiClientForUserNotSignedIn = useCallback(() => {
        return userApi(
            aspida(axios, {
                baseURL: baseURLForUser,
                ...fetchOptions,
                headers
            })
        );
    }, [headers]);

    return {
        apiClientForEmployeeNotSignedIn,
        apiClientForUserNotSignedIn
    };
};
