import { rest } from 'msw';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const applicationsHandlers = [
    rest.post(`${employeeURL}/applications`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.put(`${employeeURL}/applications`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.get(`${employeeURL}/applications`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json([
                {
                    subjectId: 1,
                    userId: 1,
                    userName: '山田 太郎',
                    phoneticUserName: 'ヤマダ タロウ',
                    phoneNumber: '09012345678',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '朝イチ〜ラスト',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 0,
                    note: '備考です',
                    lastUpdaterName: '田村 花子',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 2,
                    userName: '田山 太郎',
                    phoneticUserName: 'タヤマ タロウ',
                    phoneNumber: '09012345672',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '朝イチ〜ラスト',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 0,
                    note: '備考です',
                    lastUpdaterName: '田村 花子',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 3,
                    userName: '村田 太郎',
                    phoneticUserName: 'ムラタ タロウ',
                    phoneNumber: '09012345673',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '朝イチ〜ラスト',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 1,
                    note: '備考です',
                    lastUpdaterName: '田村 花子',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 4,
                    userName: '村谷 綾香',
                    phoneticUserName: 'ムラタニ アヤカ',
                    phoneNumber: '09012345674',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '朝イチ〜ラスト',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 1,
                    note: '備考です',
                    lastUpdaterName: '田村 花子',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 5,
                    userName: '村山 はる',
                    phoneticUserName: 'ムラヤマ ハル',
                    phoneNumber: '09012345675',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '朝イチ〜ラスト',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 2,
                    note: '備考です',
                    lastUpdaterName: '田村 花子',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 6,
                    userName: '国松 権助',
                    phoneticUserName: 'クニマツ ゴンスケ',
                    phoneNumber: '09012345676',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '朝イチ〜ラスト',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 0,
                    note: '備考です',
                    lastUpdaterName: '田村 花子',
                    isInBlackList: true
                },
                {
                    subjectId: 1,
                    userId: 456,
                    userName: '田中 太郎',
                    phoneticUserName: 'タナカ タロウ',
                    phoneNumber: '09012345456',
                    timeSlot: 2,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '6:30〜ラストまで',
                    beltName: 'A',
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: true,
                    status: 3,
                    assignedTime: '6:30〜ラストまで',
                    note: '特記事項はありません',
                    lastUpdaterName: '田村 花子',
                    isInBlackList: false
                },

                {
                    subjectId: 1,
                    userId: 789,
                    userName: '鈴木 次郎',
                    phoneticUserName: 'スズキ ジロウ',
                    phoneNumber: '08098765432',
                    timeSlot: 1,
                    subjectJobTitleName: '調理補助',
                    preferredTime: '朝イチ〜ラスト（17時予定）まで',
                    beltName: '調理補助',
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 4,
                    assignedTime: '朝イチ〜ラスト（17時予定）まで',
                    note: 'シフト変更希望ありシフト変更希望ありシフト変更希望ありシフト変更希望ありシフト変更希望ありシフト変更希望あり',
                    lastUpdaterName: '伊藤 光男',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 123,
                    userName: '山口 花子',
                    phoneticUserName: 'ヤマグチ ハナコ',
                    phoneNumber: '07054321098',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '朝イチ〜ラスト',
                    beltName: 'C',
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: true,
                    assignedTime: '朝イチ〜ラスト',
                    status: 5,
                    note: '経験豊富',
                    lastUpdaterName: '山田 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 234,
                    userName: '伊藤 健太',
                    phoneticUserName: 'イトウ ケンタ',
                    phoneNumber: '08011112222',
                    timeSlot: 2,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '6:30〜ラストまで',
                    beltName: 'A',
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    assignedTime: '6:30〜ラストまで',
                    status: 6,
                    note: null,
                    lastUpdaterName: '田村 花子',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 890,
                    userName: '斎藤 由美子',
                    phoneticUserName: 'サイトウ ユミコ',
                    phoneNumber: '09077778888',
                    timeSlot: null,
                    subjectJobTitleName: 'おせち',
                    preferredTime: '10:00〜17:00',
                    beltName: null,
                    definedStartTime: null,
                    definedEndTime: null,
                    isManuallyRegistered: false,
                    status: 2,
                    note: 'シフト調整可能',
                    lastUpdaterName: '田中 芳子',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 901,
                    userName: '小林 聡',
                    phoneticUserName: 'コバヤシ サトシ',
                    phoneNumber: '08022223333',
                    timeSlot: 1,
                    subjectJobTitleName: '調理補助',
                    preferredTime: '朝イチ〜ラスト（17時予定）まで',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 6,
                    assignedTime: '朝イチ〜ラスト（17時予定）まで',
                    note: '調理経験あり',
                    lastUpdaterName: '田村 花子',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 567,
                    userName: '佐々木 明子',
                    phoneticUserName: 'ササキ アキコ',
                    phoneNumber: '09044445555',
                    timeSlot: 0,
                    subjectJobTitleName: 'おせち',
                    preferredTime: '11:00〜17:00',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 4,
                    assignedTime: '6:00〜17:00',
                    note: '笑顔が素敵',
                    lastUpdaterName: '田村 花子',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 678,
                    userName: '村田 花子',
                    phoneticUserName: 'ムラタ ハナコ',
                    phoneNumber: '08066667777',
                    timeSlot: 2,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '昼〜ラストまで',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 1,
                    note: null,
                    lastUpdaterName: '田村 花子',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 4567,
                    userName: '加藤 太一',
                    phoneticUserName: 'カトウ タイチ',
                    phoneNumber: '09099998888',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '朝イチ〜ラスト',
                    beltName: 'A',
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: true,
                    status: 5,
                    assignedTime: '朝イチ〜ラスト',
                    note: '学生アルバイト',
                    lastUpdaterName: '田村 花子',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 6789,
                    userName: '高橋 愛子',
                    phoneticUserName: 'タカハシ アイコ',
                    phoneNumber: '08012341234',
                    timeSlot: 1,
                    subjectJobTitleName: '調理補助',
                    preferredTime: '6:30〜ラストまで',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '12:00',
                    isManuallyRegistered: false,
                    status: 6,
                    assignedTime: '6:30〜ラストまで',
                    note: '料理が得意',
                    lastUpdaterName: '田村 花子',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 2345,
                    userName: '高橋 雄一',
                    phoneticUserName: 'タカハシ ユウイチ',
                    phoneNumber: '08012345678',
                    timeSlot: 1,
                    subjectJobTitleName: '調理補助',
                    preferredTime: '6:30〜昼まで',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 3,
                    assignedTime: '6:30〜昼まで',
                    note: null,
                    lastUpdaterName: '山田 太郎',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 45678,
                    userName: '吉田 美咲',
                    phoneticUserName: 'ヨシダ ミサキ',
                    phoneNumber: '09087654321',
                    timeSlot: 2,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '昼〜ラストまで',
                    beltName: 'C',
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: true,
                    status: 4,
                    assignedTime: '昼〜ラストまで',
                    note: 'ベルトC担当',
                    lastUpdaterName: '山田 太郎',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 56789,
                    userName: '中村 優子',
                    phoneticUserName: 'ナカムラ ユウコ',
                    phoneNumber: '08011112222',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '朝イチ〜ラスト',
                    beltName: 'A',
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 6,
                    assignedTime: '朝イチ〜ラスト',
                    note: 'スピード重視',
                    lastUpdaterName: '田中 芳子',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 7890,
                    userName: '木村 智子',
                    phoneticUserName: 'キムラ トモコ',
                    phoneNumber: '09022223333',
                    timeSlot: 1,
                    subjectJobTitleName: '調理補助',
                    preferredTime: '昼〜ラストまで',
                    beltName: 'C',
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 5,
                    assignedTime: '昼〜ラストまで',
                    note: '調理経験豊富',
                    lastUpdaterName: '山田 太郎',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 8901,
                    userName: '岡田 勇気',
                    phoneticUserName: 'オカダ ユウキ',
                    phoneNumber: '08033334444',
                    timeSlot: 2,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '昼〜ラストまで',
                    beltName: 'B',
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 2,
                    note: 'シフト変更希望あり',
                    lastUpdaterName: '伊藤 光男',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 12348,
                    userName: '小川 美月',
                    phoneticUserName: 'オガワ ミヅキ',
                    phoneNumber: '09055556666',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '朝イチ〜ラスト',
                    beltName: 'A',
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: true,
                    status: 4,
                    assignedTime: '朝イチ〜ラスト',
                    note: '朝型',
                    lastUpdaterName: '山田 太郎',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 2345,
                    userName: '渡辺 明日香',
                    phoneticUserName: 'ワタナベ アスカ',
                    phoneNumber: '08077778888',
                    timeSlot: 1,
                    subjectJobTitleName: '調理補助',
                    preferredTime: '6:30〜昼まで',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 1,
                    note: null,
                    lastUpdaterName: '山田 太郎',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 67890,
                    userName: '吉田 恵',
                    phoneticUserName: 'ヨシダ メグミ',
                    phoneNumber: '09088889999',
                    timeSlot: null,
                    subjectJobTitleName: 'おせち',
                    preferredTime: '9:00〜17:00',
                    beltName: null,
                    definedStartTime: '9:00',
                    definedEndTime: '16:00',
                    isManuallyRegistered: true,
                    status: 6,
                    note: 'おせちの経験あり',
                    lastUpdaterName: '山田 太郎',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 67800,
                    userName: '保坂 慶太',
                    phoneticUserName: 'ホサカ ケイタ',
                    phoneNumber: '09088880000',
                    timeSlot: null,
                    subjectJobTitleName: 'おせち',
                    preferredTime: '9:00〜17:00',
                    beltName: null,
                    definedStartTime: '9:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: true,
                    status: 5,
                    note: 'おせちの経験あり',
                    lastUpdaterName: '山田 太郎',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 7890,
                    userName: '山本 美晴',
                    phoneticUserName: 'ヤマモト ミハル',
                    phoneNumber: '08099991111',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '朝イチ〜ラスト',
                    beltName: 'C',
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 4,
                    assignedTime: '朝イチ〜ラスト',
                    note: 'ベルトC専属',
                    lastUpdaterName: '山田 太郎'
                },
                {
                    subjectId: 1,
                    userId: 1234,
                    userName: '川田 正治',
                    phoneticUserName: 'カワダ ショウジ',
                    phoneNumber: '09011112222',
                    timeSlot: 1,
                    subjectJobTitleName: '調理補助',
                    preferredTime: '6:30〜昼まで',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 2,
                    note: '新人',
                    lastUpdaterName: '山田 太郎',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 12345,
                    userName: '佐藤 花子',
                    phoneticUserName: 'サトウ ハナコ',
                    phoneNumber: '08011111111',
                    timeSlot: 0,
                    subjectJobTitleName: 'おせち',
                    preferredTime: '10:00〜17:00',
                    beltName: null,
                    definedStartTime: null,
                    definedEndTime: null,
                    isManuallyRegistered: false,
                    status: 0,
                    note: null,
                    lastUpdaterName: null,
                    isInBlackList: true
                },
                {
                    subjectId: 1,
                    userId: 23456,
                    userName: '田中 花子',
                    phoneticUserName: 'タナカ ハナコ',
                    phoneNumber: '09022222222',
                    timeSlot: 1,
                    subjectJobTitleName: '調理補助',
                    preferredTime: '朝イチ〜ラスト（17時予定）まで',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 0,
                    note: null,
                    lastUpdaterName: '田中 花子',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 34567,
                    userName: '渡辺 花子',
                    phoneticUserName: 'ワタナベ ハナコ',
                    phoneNumber: '08033333333',
                    timeSlot: 2,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '昼〜ラストまで',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: true,
                    status: 0,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 44567,
                    userName: '三田 花子',
                    phoneticUserName: 'ミタ ハナコ',
                    phoneNumber: '08033334333',
                    timeSlot: 2,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '昼〜ラストまで',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 0,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 54567,
                    userName: '五反田 花子',
                    phoneticUserName: 'ゴタンダ ハナコ',
                    phoneNumber: '08033354333',
                    timeSlot: 2,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '昼〜ラストまで',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 0,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 45678,
                    userName: '伊藤 花子',
                    phoneticUserName: 'イトウ ハナコ',
                    phoneNumber: '09044444444',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '朝イチ〜ラスト',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 0,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 56789,
                    userName: '加藤 花子',
                    phoneticUserName: 'カトウ ハナコ',
                    phoneNumber: '08055555555',
                    timeSlot: 1,
                    subjectJobTitleName: '調理補助',
                    preferredTime: '6:30〜昼まで',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 0,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 67890,
                    userName: '木村 花子',
                    phoneticUserName: 'キムラ ハナコ',
                    phoneNumber: '09066666666',
                    timeSlot: 2,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '6:30〜昼まで',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 0,
                    note: null,
                    lastUpdaterName: '田村 花子',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 57890,
                    userName: '木村 華子',
                    phoneticUserName: 'キムラ ハナコ',
                    phoneNumber: '09066667666',
                    timeSlot: 2,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '6:30〜昼まで',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 0,
                    note: null,
                    lastUpdaterName: '田村 花子',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 78901,
                    userName: '山田 花子',
                    phoneticUserName: 'ヤマダ ハナコ',
                    phoneNumber: '08077777777',
                    timeSlot: null,
                    subjectJobTitleName: 'おせち',
                    preferredTime: '9:00〜17:00',
                    beltName: null,
                    definedStartTime: null,
                    definedEndTime: null,
                    isManuallyRegistered: true,
                    status: 0,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 89018,
                    userName: '中島 花子',
                    phoneticUserName: 'ナカジマ ハナコ',
                    phoneNumber: '09088888888',
                    timeSlot: 1,
                    subjectJobTitleName: '調理補助',
                    preferredTime: '朝イチ〜ラスト（17時予定）まで',
                    beltName: null,
                    definedStartTime: '09:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: true,
                    status: 0,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 89019,
                    userName: '中山 花子',
                    phoneticUserName: 'ナカヤマ ハナコ',
                    phoneNumber: '09088888345',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '6:30〜ラストまで',
                    beltName: null,
                    definedStartTime: null,
                    definedEndTime: null,
                    isManuallyRegistered: false,
                    status: 0,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 89012,
                    userName: '中間 花子',
                    phoneticUserName: 'ナカマ ハナコ',
                    phoneNumber: '09088888346',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '6:30〜ラストまで',
                    beltName: null,
                    definedStartTime: null,
                    definedEndTime: null,
                    isManuallyRegistered: false,
                    status: 0,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 111,
                    userName: '東方 次郎',
                    phoneticUserName: 'ヒガシカタ ジロウ',
                    phoneNumber: '09088888111',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '朝イチ〜ラスト',
                    beltName: 'A',
                    definedStartTime: null,
                    definedEndTime: null,
                    isManuallyRegistered: false,
                    status: 3,
                    assignedTime: '朝イチ〜ラスト',
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 222,
                    userName: '南野 三郎',
                    phoneticUserName: 'ミナミノ ジロウ',
                    phoneNumber: '09088888222',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '朝イチ〜ラスト',
                    beltName: 'C',
                    definedStartTime: null,
                    definedEndTime: null,
                    isManuallyRegistered: false,
                    status: 3,
                    assignedTime: '朝イチ〜ラスト',
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 333,
                    userName: '北条 三郎',
                    phoneticUserName: 'ホウジョウ サブロウ',
                    phoneNumber: '09088888333',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '朝イチ〜ラスト',
                    beltName: 'B',
                    definedStartTime: null,
                    definedEndTime: null,
                    isManuallyRegistered: false,
                    status: 3,
                    assignedTime: '朝イチ〜ラスト',
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 444,
                    userName: '西成 四郎',
                    phoneticUserName: 'ニシナリ シロウ',
                    phoneNumber: '09088888444',
                    timeSlot: 0,
                    subjectJobTitleName: 'ベルト盛り付け',
                    preferredTime: '昼〜ラスト',
                    beltName: 'A',
                    definedStartTime: null,
                    definedEndTime: null,
                    isManuallyRegistered: false,
                    status: 4,
                    assignedTime: '朝イチ〜ラスト',
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 89013,
                    userName: '本田 美恵',
                    phoneticUserName: 'ホンダ ミエ',
                    phoneNumber: '09088888346',
                    timeSlot: 0,
                    subjectJobTitleName: 'おせち',
                    preferredTime: '12:00〜17:00',
                    beltName: null,
                    definedStartTime: null,
                    definedEndTime: null,
                    isManuallyRegistered: false,
                    status: 1,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 89014,
                    userName: '山村 佐恵子',
                    phoneticUserName: 'ヤマムラ サエコ',
                    phoneNumber: '09088888345',
                    timeSlot: 0,
                    subjectJobTitleName: 'おせち',
                    preferredTime: '11:00〜16:00',
                    beltName: null,
                    definedStartTime: '11:00',
                    definedEndTime: '16:00',
                    isManuallyRegistered: false,
                    status: 4,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 89015,
                    userName: '二階堂 太郎',
                    phoneticUserName: 'ニカイドウ タロウ',
                    phoneNumber: '09088888346',
                    timeSlot: 0,
                    subjectJobTitleName: 'おせち',
                    preferredTime: '9:00〜13:00',
                    beltName: null,
                    definedStartTime: null,
                    definedEndTime: null,
                    isManuallyRegistered: false,
                    status: 3,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 89016,
                    userName: '竹中 雄介',
                    phoneticUserName: 'タケナカ ユウスケ',
                    phoneNumber: '09088888347',
                    timeSlot: 0,
                    subjectJobTitleName: 'おせち',
                    preferredTime: '12:00〜17:00',
                    beltName: null,
                    definedStartTime: null,
                    definedEndTime: null,
                    isManuallyRegistered: false,
                    status: 3,
                    note: null,
                    lastUpdaterName: null,
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 89017,
                    userName: '河岸 隆',
                    phoneticUserName: 'カワギシ タカシ',
                    phoneNumber: '09088888348',
                    timeSlot: 0,
                    subjectJobTitleName: 'おせち',
                    preferredTime: '9:00〜13:00',
                    beltName: null,
                    definedStartTime: '9:00',
                    definedEndTime: '13:00',
                    isManuallyRegistered: false,
                    status: 4,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 99001,
                    userName: '幸田 邦明',
                    phoneticUserName: 'コウダ クニアキ',
                    phoneNumber: '09099990001',
                    timeSlot: 0,
                    subjectJobTitleName: '調理補助',
                    preferredTime: '朝イチ〜ラスト（17時予定）まで',
                    assignedTime: '朝イチ〜ラスト（17時予定）まで',
                    beltName: null,
                    definedStartTime: '9:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 3,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 99002,
                    userName: '多田山 茂',
                    phoneticUserName: 'タダヤマ シゲル',
                    phoneNumber: '09099990002',
                    timeSlot: 0,
                    subjectJobTitleName: '調理補助',
                    preferredTime: '朝イチ〜ラスト（17時予定）まで',
                    assignedTime: '朝イチ〜ラスト（17時予定）まで',
                    beltName: null,
                    definedStartTime: '9:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 3,
                    note: null,
                    lastUpdaterName: '山本 健太'
                },
                {
                    subjectId: 1,
                    userId: 99003,
                    userName: '原田 泰子',
                    phoneticUserName: 'ハラダ ヤスコ',
                    phoneNumber: '09099990003',
                    timeSlot: 0,
                    subjectJobTitleName: '調理補助',
                    preferredTime: '朝イチ〜ラスト（17時予定）まで',
                    assignedTime: '朝イチ〜ラスト（17時予定）まで',
                    beltName: null,
                    definedStartTime: '9:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 6,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 99004,
                    userName: '前田 真由美',
                    phoneticUserName: 'マエダ マユミ',
                    phoneNumber: '09099990004',
                    timeSlot: 0,
                    subjectJobTitleName: '調理補助',
                    preferredTime: '朝イチ〜ラスト（17時予定）まで',
                    assignedTime: '朝イチ〜ラスト（17時予定）まで',
                    beltName: null,
                    definedStartTime: '9:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 1,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 99005,
                    userName: '梶谷 美晴',
                    phoneticUserName: 'カジタニ ミハル',
                    phoneNumber: '09099990005',
                    timeSlot: 0,
                    subjectJobTitleName: '調理補助',
                    preferredTime: '朝イチ〜ラスト（17時予定）まで',
                    assignedTime: '朝イチ〜ラスト（17時予定）まで',
                    beltName: null,
                    definedStartTime: '9:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 2,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                },
                {
                    subjectId: 1,
                    userId: 99006,
                    userName: '早川 雅美',
                    phoneticUserName: 'ハヤカワ マサミ',
                    phoneNumber: '09099990006',
                    timeSlot: 0,
                    subjectJobTitleName: '調理補助',
                    preferredTime: '朝イチ〜ラスト（17時予定）まで',
                    assignedTime: '朝イチ〜ラスト（17時予定）まで',
                    beltName: null,
                    definedStartTime: '9:00',
                    definedEndTime: '17:00',
                    isManuallyRegistered: false,
                    status: 5,
                    note: null,
                    lastUpdaterName: '山本 健太',
                    isInBlackList: false
                }
            ])
        );
    }),
    rest.patch(
        `${employeeURL}/applications/:subjectId/:userId/assign`,
        (_req, res, ctx) => {
            return res(ctx.status(200));
        }
    ),
    rest.patch(
        `${employeeURL}/applications/:subjectId/:userId/unassign`,
        (_req, res, ctx) => {
            return res(ctx.status(200));
        }
    ),
    rest.patch(
        `${employeeURL}/applications/:subjectId/:userId/confirm`,
        (_req, res, ctx) => {
            return res(ctx.status(200));
        }
    ),
    rest.patch(
        `${employeeURL}/applications/:subjectId/:userId/hold`,
        (_req, res, ctx) => {
            return res(ctx.status(200));
        }
    ),
    rest.patch(
        `${employeeURL}/applications/:subjectId/:userId/unhold`,
        (_req, res, ctx) => {
            return res(ctx.status(200));
        }
    ),
    rest.put(
        `${employeeURL}/applications/:subjectId/:userId`,
        (_req, res, ctx) => {
            return res(ctx.status(200));
        }
    )
];
