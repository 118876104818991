import { useAspidaQuery } from '@aspida/react-query';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import qs from 'qs';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { AlertObj } from './Alert.types';
import { Caution } from '@/components/modules';
import { useApiClient } from '@/hooks';

export const Alert: FC = () => {
    dayjs.locale(ja);

    const { apiClientForEmployee } = useApiClient();
    const oneMonthAfter = dayjs().add(1, 'month').subtract(1, 'day');

    // 在留カード期限切れスタッフ
    const expirationOfResidenceCardUserQuery = {
        page: '1',
        leaveStatus: '0',
        expiredResidenceCardTo: oneMonthAfter.format('YYYY/MM/DD')
    } as const;

    const { data: expirationOfResidenceCardUser } = useAspidaQuery(
        apiClientForEmployee().users,
        '$get',
        {
            query: expirationOfResidenceCardUserQuery
        }
    );

    // 新規登録スタッフ
    const newStaffUserQuery = {
        page: '1',
        leaveStatus: '0',
        checkStatus: '0'
    } as const;

    const { data: newStaffUser } = useAspidaQuery(
        apiClientForEmployee().users,
        '$get',
        {
            query: newStaffUserQuery
        }
    );

    // 退職申請スタッフ
    const leaveRequestedUserQuery = {
        page: '1',
        leaveStatus: '1'
    } as const;

    const { data: leaveRequestedUser } = useAspidaQuery(
        apiClientForEmployee().users,
        '$get',
        {
            query: leaveRequestedUserQuery
        }
    );

    const alerts: AlertObj[] = [];

    if (
        expirationOfResidenceCardUser?.totalItems != null &&
        expirationOfResidenceCardUser.totalItems !== 0
    ) {
        alerts.push({
            message: '在留カードの有効期限を確認してください',
            count: expirationOfResidenceCardUser?.totalItems,
            query: qs.stringify(expirationOfResidenceCardUserQuery)
        });
    }

    if (newStaffUser?.totalItems != null && newStaffUser.totalItems !== 0) {
        alerts.push({
            message: '新規登録したスタッフの情報を確認してください',
            count: newStaffUser?.totalItems,
            query: qs.stringify(newStaffUserQuery)
        });
    }

    if (
        leaveRequestedUser?.totalItems != null &&
        leaveRequestedUser.totalItems !== 0
    ) {
        alerts.push({
            message: '退職申請したスタッフの申請を確認してください',
            count: leaveRequestedUser?.totalItems,
            query: qs.stringify(leaveRequestedUserQuery)
        });
    }

    return (
        <div className="flex items-center w-full h-full gap-4">
            {alerts.length > 0 ? (
                alerts.map((alert, i) => (
                    <Link
                        key={'alert' + i}
                        to={{
                            pathname: '/staff',
                            search: new URLSearchParams(alert.query).toString()
                        }}
                        className="flex items-center justify-between p-3 rounded-md bg-primary-light text-primary"
                    >
                        <div className="flex w-5/6 gap-1 underline-offset-2 hover:underline">
                            <div>
                                <Caution size="small" />
                            </div>
                            <p className="break-normal text-2xs">
                                {alert.message}
                            </p>
                        </div>
                        <div className="flex items-center justify-center font-semibold text-center text-white rounded-lg h-7 w-7 bg-primary">
                            {alert.count}
                        </div>
                    </Link>
                ))
            ) : (
                <p className="text-sm font-bold text-primary">
                    未確認の申請はありません
                </p>
            )}
        </div>
    );
};
