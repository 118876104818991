import { RadioGroup as _RadioGroup } from '@headlessui/react';
import { ReactComponent as RadioGroupChecked } from '@material-design-icons/svg/filled/radio_button_checked.svg';
import { ReactComponent as RadioGroupUnchecked } from '@material-design-icons/svg/filled/radio_button_unchecked.svg';
import { FC, useReducer } from 'react';
import { RadioGroupProps } from './RadioGroup.types';
import { cn } from '@/utils';

export const RadioGroup: FC<RadioGroupProps> = ({
    className,
    options,
    onChange,
    defaultValue,
    uniqueKey,
    disabled
}) => {
    const reducer = (
        state: { value: string; uniqKeyOld: string },
        action: {
            type: 'SET_VALUE' | 'SET_KEY';
            value: string;
            uniqKeyOld?: string;
        }
    ): { value: string; uniqKeyOld: string } => {
        switch (action.type) {
            case 'SET_VALUE':
                return { value: action.value, uniqKeyOld: state.uniqKeyOld };
            case 'SET_KEY':
                return { value: defaultValue, uniqKeyOld: action.uniqKeyOld! };
            default:
                return state;
        }
    };

    const [{ value, uniqKeyOld }, dispatch] = useReducer(reducer, {
        value: defaultValue,
        uniqKeyOld: ''
    });

    if (uniqueKey !== undefined && uniqKeyOld !== uniqueKey) {
        dispatch({
            type: 'SET_KEY',
            value: defaultValue,
            uniqKeyOld: uniqueKey ?? ''
        });
    }

    return (
        // eslint-disable-next-line react/jsx-pascal-case
        <_RadioGroup
            value={value}
            onChange={(e) => {
                dispatch({ type: 'SET_VALUE', value: e });
                onChange(e);
            }}
            className={cn('flex gap-8', className)}
            disabled={disabled}
        >
            {options.map((option) => (
                // eslint-disable-next-line react/jsx-pascal-case
                <_RadioGroup.Option key={option.value} value={option.value}>
                    {({ checked }) => (
                        <span
                            data-checked={checked}
                            data-disabled={disabled}
                            className={cn(
                                'flex justify-center items-center gap-1.5 cursor-pointer fill-gray-neutral ',
                                'data-[checked=true]:fill-primary',
                                'data-[disabled=true]:fill-gray-neutral'
                            )}
                        >
                            {!checked && (
                                <RadioGroupUnchecked className="w-5 h-5" />
                            )}
                            {checked && (
                                <RadioGroupChecked className="w-5 h-5" />
                            )}
                            {option.label}
                        </span>
                    )}
                </_RadioGroup.Option>
            ))}
        </_RadioGroup>
    );
};
