import { rest } from 'msw';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const subjectJobTitlesHandlers = [
    rest.patch(
        `${employeeURL}/subject_job_titles/:subjectId/:subjectJobTitle/close`,
        (_req, res, ctx) => {
            return res(ctx.status(200));
        }
    )
];
