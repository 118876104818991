import { rest } from 'msw';
import { getTokenFromCookie } from '@/utils';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const authHandlers = [
    rest.get(`${employeeURL}/auth/check`, (_req, res, ctx) => {
        const token = getTokenFromCookie({});
        if (token != null && token !== '') {
            return res(ctx.delay(1000), ctx.status(200));
        } else {
            return res(ctx.delay(1000), ctx.status(401));
        }
    }),
    rest.post(`${employeeURL}/auth`, (req, res, ctx) => {
        document.cookie =
            (req.body! as { email: string; passward: string }).email ===
            'aa@aa.com'
                ? 'idToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI3MmEwZjg1OC1kOTgzLTQxYjYtOTI5Mi1kNGM5NTliNDk4NmUiLCJpc3MiOiJodHRwczovL2NvZ25pdG8taWRwLmFwLW5vcnRoZWFzdC0xLmFtYXpvbmF3cy5jb20vYXAtbm9ydGhlYXN0LTFfZDZ1TEZJNmRaIiwiY29nbml0bzp1c2VybmFtZSI6InRlc3QxIiwib3JpZ2luX2p0aSI6Ijk1N2RkNWVkLTU2ZTQtNDRkOS04NDlkLWVhZmFkNTIyYjI5NSIsImF1ZCI6IjRraGMxOTc5MDVzbnRhNTI2MGFuYmJyZmlvIiwiZXZlbnRfaWQiOiI4OTEwZjJiZC1mYjRmLTQ3MGMtOWExZS03NTIzNDVlZDU1M2IiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY2ODQxODc3OCwiZXhwIjoxNjY4NDIyMzc4LCJpYXQiOjE2Njg0MTg3NzgsImp0aSI6ImVhYTdjOWU1LTI5NDMtNGEyOS04NDI2LTgyNzMzMTQ1Y2M5ZSIsImVtYWlsIjoic25zbG9naW4rdGVzdDFAeC1wb2ludC0xLm5ldCJ9.aSR2nlprePnrSH2VDShVgTdMbJeFcBVPQU1JZMddE5Y'
                : 'idToken=eyJraWQiOiJJUnZmMkNHMEg0QnIyNTVHZGY4Mm4zaEl3V1wvN3hBR1dNeDg3SXJ6RFNDND0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI4NzI0N2M5OC02NWFlLTQwMzAtODllMC1lODgwOThlYjcxYmMiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtbm9ydGhlYXN0LTEuYW1hem9uYXdzLmNvbVwvYXAtbm9ydGhlYXN0LTFfWmtLbGhaQ3pwIiwiY29nbml0bzp1c2VybmFtZSI6IjEiLCJvcmlnaW5fanRpIjoiOWQwOGUyOWEtN2QzMC00Y2Q4LTk0ZDYtY2RhYzFmZTE5Y2M4IiwiYXVkIjoidDNraG5vY2ozaHBpc2hnbjlxbmk0aDBsNSIsImV2ZW50X2lkIjoiNGI3NGM3YTgtZDFmOS00YTAxLTllNTAtYjAxMTQwYmVhZDQ5IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2OTM4MjIwOTMsImV4cCI6MTY5MzgyNTY5MywiaWF0IjoxNjkzODIyMDkzLCJqdGkiOiI5MWUzZDgyZS1iYzRjLTRhZWQtOGZkZC1iMjZlMmE1N2VlYzIiLCJlbWFpbCI6InNuc2xvZ2luK2VtcDFAeC1wb2ludC0xLm5ldCJ9.u1R9duTi1Yv-jsMBpPvRkQmdlZfOVlojzFBxFcNiwerV4RkLy4nxCbrAtbuRVzv3UhVufajyF2OXmZW8Tz8h3UXBR-hY0cwn_hGq0Kzf_QsUhKmNGE3cWwCngU_MvFdBY9X9in3gvP92ThehYWPPd5lcfqyUqwmiGlHlmfnueSsDdj98x80YvmiRG2F9cp-SDsPoV_xdEVQvErEXe6sG56Anl0bql_FOKc0V2_0cxiQeal7QJbdBtg5ppZcZ_-ruZkYraRXUyU09TPk-849TSpeuuaQ2IUU2zOBQyAPJmmqZr06ZQAgTn0PH0N0T-347GGUQwtxPSJQDwQpuvEm6IQ';

        return res(ctx.status(200), ctx.json({ signInStatus: 'SUCCESS' }));
    }),
    rest.delete(`${employeeURL}/auth`, (_req, res, ctx) => {
        document.cookie = 'idToken=';
        return res(ctx.status(200));
    })
];
