import { rest } from 'msw';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const noticeTargetsHandlers = [
    rest.get(`${employeeURL}/notice_targets`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                totalItems: 66,
                currentPage: 1,
                items: [
                    {
                        notificationId: 1,
                        subjectId: 1,
                        userId: 1,
                        userName: '山田 太郎',
                        phoneticUserName: 'ヤマダ タロウ',
                        gender: 0,
                        isInBlackList: false,
                        leaveStatus: 0
                    },
                    {
                        notificationId: 1,
                        subjectId: 1,
                        userId: 2,
                        userName: '山田 花子',
                        phoneticUserName: 'ヤマダ ハナコ',
                        gender: 1,
                        isInBlackList: false,
                        leaveStatus: 0
                    },
                    {
                        notificationId: 1,
                        subjectId: 1,
                        userId: 3,
                        userName: '佐藤 三郎',
                        phoneticUserName: 'サトウ サブロウ',
                        gender: 0,
                        isInBlackList: true,
                        leaveStatus: 0
                    },
                    {
                        notificationId: 1,
                        subjectId: 1,
                        userId: 4,
                        userName: '田中 四郎',
                        phoneticUserName: 'タナカ シロウ',
                        gender: 0,
                        isInBlackList: false,
                        leaveStatus: 0
                    },
                    {
                        notificationId: 1,
                        subjectId: 1,
                        userId: 5,
                        userName: '齋藤 五郎',
                        phoneticUserName: 'サイトウ ゴロウ',
                        gender: 0,
                        isInBlackList: false,
                        leaveStatus: 2
                    },
                    {
                        notificationId: 1,
                        subjectId: 1,
                        userId: 6,
                        userName: '加藤 六実',
                        phoneticUserName: 'カトウ ムツミ',
                        gender: 1,
                        isInBlackList: false,
                        leaveStatus: 1
                    }
                ]
            })
        );
    })
];
