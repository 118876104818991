import { ComponentPropsWithRef, FC, forwardRef } from 'react';
import { cn } from '@/utils';

type TextareaProps = ComponentPropsWithRef<'textarea'>;

export const Textarea: FC<TextareaProps> = forwardRef(({ ...props }, ref) => {
    return (
        <textarea
            {...props}
            className={cn(
                'h-full w-full rounded border-2 border-gray-light bg-white text-sm  disabled:bg-gray-light disabled:placeholder:text-gray-middle disabled:border-gray-neutral',
                props.className
            )}
            ref={ref}
        />
    );
});
