import { rest } from 'msw';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const noticesHandlers = [
    rest.get(`${employeeURL}/notices`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                currentPage: 1,
                totalItems: 25,
                items: [
                    {
                        id: 1,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2022/04/01'
                    },
                    {
                        id: 2,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2022/04/11'
                    },
                    {
                        id: 3,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2022/04/21'
                    },
                    {
                        id: 4,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2022/05/01'
                    },
                    {
                        id: 5,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2022/05/04'
                    },
                    {
                        id: 6,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2022/05/05'
                    },
                    {
                        id: 7,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2022/06/01'
                    },
                    {
                        id: 8,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2022/07/01'
                    },
                    {
                        id: 9,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2022/07/10'
                    },
                    {
                        id: 10,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2022/07/11'
                    },
                    {
                        id: 11,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2022/07/21'
                    },
                    {
                        id: 12,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2022/07/23'
                    },
                    {
                        id: 13,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2023/08/01'
                    },
                    {
                        id: 14,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2022/09/01'
                    },
                    {
                        id: 15,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2022/10/01'
                    },
                    {
                        id: 16,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2022/11/01'
                    },
                    {
                        id: 17,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2022/12/01'
                    },
                    {
                        id: 18,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2023/01/01'
                    },
                    {
                        id: 19,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2023/02/01'
                    },
                    {
                        id: 20,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2023/03/01'
                    },
                    {
                        id: 21,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2023/04/01'
                    },
                    {
                        id: 22,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2023/05/01'
                    },
                    {
                        id: 23,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2023/06/01'
                    },
                    {
                        id: 24,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2023/07/01'
                    },
                    {
                        id: 25,
                        title: '割引クーポン配布のお知らせ',
                        sendedDate: '2023/08/01'
                    }
                ]
            })
        );
    }),
    rest.post(`${employeeURL}/notices`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.get(`${employeeURL}/notices/:id`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                noticeTargetNumber: 200,
                title: '【スタッフ限定】割引クーポン配布のお知らせ',
                body: 'お疲れさまです。株式会社 八百彦です。\n下記クーポン番号を配布します。',
                signature: '八尾彦アルバイト担当者'
            })
        );
    })
];
