import { useAspidaQuery } from '@aspida/react-query';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LayoutProps } from './Layout.types';
import { Header } from '@/components/modules';
import { useApiClient, useAuth } from '@/hooks';

export const Layout: FC<LayoutProps> = ({ children }) => {
    const { id } = useAuth();
    const { apiClientForEmployee } = useApiClient();

    const { data: employeeData } = useAspidaQuery(
        apiClientForEmployee().employees._id(id!),
        '$get'
    );

    const location = useLocation();

    useEffect(() => {
        // ページ遷移時にスクロール位置をリセット
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <div className="w-full h-full min-w-6xl">
            <Header employeeType={employeeData?.employeeType} />
            <div className="w-full h-full pt-16">{children}</div>
        </div>
    );
};
