export const zeroPad = ({
    num,
    digit
}: {
    num: number;
    digit: number;
}): string => {
    if (num < 0) throw new Error('num must be non-negative');
    if (digit < 0) throw new Error('digit must be non-negative');

    let str = num.toString();
    while (str.length < digit) {
        str = '0' + str;
    }
    return str;
};
