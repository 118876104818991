import type { AspidaClient, BasicHeaders } from 'aspida'
import { dataToURLString } from 'aspida'
import type { Methods as Methods0 } from './applications'
import type { Methods as Methods1 } from './applications/_subjectId@number/_userId@number'
import type { Methods as Methods2 } from './applications/_subjectId@number/_userId@number/assign'
import type { Methods as Methods3 } from './applications/_subjectId@number/_userId@number/confirm'
import type { Methods as Methods4 } from './applications/_subjectId@number/_userId@number/hold'
import type { Methods as Methods5 } from './applications/_subjectId@number/_userId@number/unassign'
import type { Methods as Methods6 } from './applications/_subjectId@number/_userId@number/unhold'
import type { Methods as Methods7 } from './auth'
import type { Methods as Methods8 } from './auth/check'
import type { Methods as Methods9 } from './calendar'
import type { Methods as Methods10 } from './email_templates/_type'
import type { Methods as Methods11 } from './employees'
import type { Methods as Methods12 } from './employees/_id@number'
import type { Methods as Methods13 } from './employees/_id@number/password'
import type { Methods as Methods14 } from './notice_targets'
import type { Methods as Methods15 } from './notice_templates'
import type { Methods as Methods16 } from './notice_templates/_id@number'
import type { Methods as Methods17 } from './notices'
import type { Methods as Methods18 } from './notices/_id@number'
import type { Methods as Methods19 } from './notification_templates/apply'
import type { Methods as Methods20 } from './notification_templates/batch'
import type { Methods as Methods21 } from './notification_templates/cancel'
import type { Methods as Methods22 } from './notification_templates/hire'
import type { Methods as Methods23 } from './notification_templates/reject'
import type { Methods as Methods24 } from './notifications'
import type { Methods as Methods25 } from './notifications/_id@number'
import type { Methods as Methods26 } from './notifications/_subjectId@number/senders'
import type { Methods as Methods27 } from './notifications/_subjectId@number/titles'
import type { Methods as Methods28 } from './notifications/apply'
import type { Methods as Methods29 } from './notifications/cancel'
import type { Methods as Methods30 } from './notifications/hire'
import type { Methods as Methods31 } from './notifications/reject'
import type { Methods as Methods32 } from './password/reset'
import type { Methods as Methods33 } from './password/reset/_uuid@string'
import type { Methods as Methods34 } from './subject_belt_capacities'
import type { Methods as Methods35 } from './subject_belt_capacities/_subjectBeltId@number/_timeSlot'
import type { Methods as Methods36 } from './subject_job_capacities/_subjectId@number/_subjectJobTitle'
import type { Methods as Methods37 } from './subject_job_titles/_subjectId@number/_subjectJobTitle/close'
import type { Methods as Methods38 } from './subject_slot_times/_subjectSlotLabelId@number/_subjectBeltId@number'
import type { Methods as Methods39 } from './subjects'
import type { Methods as Methods40 } from './subjects/_id@number'
import type { Methods as Methods41 } from './subjects/_id@number/csv'
import type { Methods as Methods42 } from './subjects/_id@number/time_records'
import type { Methods as Methods43 } from './template_metadata'
import type { Methods as Methods44 } from './users'
import type { Methods as Methods45 } from './users/_id@number'
import type { Methods as Methods46 } from './users/_id@number/approve'
import type { Methods as Methods47 } from './users/_id@number/leave/approve'
import type { Methods as Methods48 } from './users/_id@number/leave/reject'
import type { Methods as Methods49 } from './users/_id@number/reject'
import type { Methods as Methods50 } from './users/_id@number/revive'

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'https://staff.yaohiko.net/employee-api' : baseURL).replace(/\/$/, '')
  const PATH0 = '/applications'
  const PATH1 = '/assign'
  const PATH2 = '/confirm'
  const PATH3 = '/hold'
  const PATH4 = '/unassign'
  const PATH5 = '/unhold'
  const PATH6 = '/auth'
  const PATH7 = '/auth/check'
  const PATH8 = '/calendar'
  const PATH9 = '/email_templates'
  const PATH10 = '/employees'
  const PATH11 = '/password'
  const PATH12 = '/notice_targets'
  const PATH13 = '/notice_templates'
  const PATH14 = '/notices'
  const PATH15 = '/notification_templates/apply'
  const PATH16 = '/notification_templates/batch'
  const PATH17 = '/notification_templates/cancel'
  const PATH18 = '/notification_templates/hire'
  const PATH19 = '/notification_templates/reject'
  const PATH20 = '/notifications'
  const PATH21 = '/senders'
  const PATH22 = '/titles'
  const PATH23 = '/notifications/apply'
  const PATH24 = '/notifications/cancel'
  const PATH25 = '/notifications/hire'
  const PATH26 = '/notifications/reject'
  const PATH27 = '/password/reset'
  const PATH28 = '/subject_belt_capacities'
  const PATH29 = '/subject_job_capacities'
  const PATH30 = '/subject_job_titles'
  const PATH31 = '/close'
  const PATH32 = '/subject_slot_times'
  const PATH33 = '/subjects'
  const PATH34 = '/csv'
  const PATH35 = '/time_records'
  const PATH36 = '/template_metadata'
  const PATH37 = '/users'
  const PATH38 = '/approve'
  const PATH39 = '/leave/approve'
  const PATH40 = '/leave/reject'
  const PATH41 = '/reject'
  const PATH42 = '/revive'
  const GET = 'GET'
  const POST = 'POST'
  const PUT = 'PUT'
  const DELETE = 'DELETE'
  const PATCH = 'PATCH'

  return {
    applications: {
      _subjectId: (val1: number) => {
        const prefix1 = `${PATH0}/${val1}`

        return {
          _userId: (val2: number) => {
            const prefix2 = `${prefix1}/${val2}`

            return {
              assign: {
                /**
                 * 応募者を配置する
                 * @returns 成功
                 */
                patch: (option: { body: Methods2['patch']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods2['patch']['resBody'], BasicHeaders, Methods2['patch']['status']>(prefix, `${prefix2}${PATH1}`, PATCH, option).json(),
                /**
                 * 応募者を配置する
                 * @returns 成功
                 */
                $patch: (option: { body: Methods2['patch']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods2['patch']['resBody'], BasicHeaders, Methods2['patch']['status']>(prefix, `${prefix2}${PATH1}`, PATCH, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix2}${PATH1}`
              },
              confirm: {
                /**
                 * 応募者を確定する
                 * @returns 成功
                 */
                patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods3['patch']['resBody'], BasicHeaders, Methods3['patch']['status']>(prefix, `${prefix2}${PATH2}`, PATCH, option).json(),
                /**
                 * 応募者を確定する
                 * @returns 成功
                 */
                $patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods3['patch']['resBody'], BasicHeaders, Methods3['patch']['status']>(prefix, `${prefix2}${PATH2}`, PATCH, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix2}${PATH2}`
              },
              hold: {
                /**
                 * 応募者を保留にする
                 * @returns 成功
                 */
                patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods4['patch']['resBody'], BasicHeaders, Methods4['patch']['status']>(prefix, `${prefix2}${PATH3}`, PATCH, option).json(),
                /**
                 * 応募者を保留にする
                 * @returns 成功
                 */
                $patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods4['patch']['resBody'], BasicHeaders, Methods4['patch']['status']>(prefix, `${prefix2}${PATH3}`, PATCH, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix2}${PATH3}`
              },
              unassign: {
                /**
                 * 応募者を未配置に戻す
                 * @returns 成功
                 */
                patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods5['patch']['resBody'], BasicHeaders, Methods5['patch']['status']>(prefix, `${prefix2}${PATH4}`, PATCH, option).json(),
                /**
                 * 応募者を未配置に戻す
                 * @returns 成功
                 */
                $patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods5['patch']['resBody'], BasicHeaders, Methods5['patch']['status']>(prefix, `${prefix2}${PATH4}`, PATCH, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix2}${PATH4}`
              },
              unhold: {
                /**
                 * 応募者の保留を解除する
                 * @returns 成功
                 */
                patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods6['patch']['resBody'], BasicHeaders, Methods6['patch']['status']>(prefix, `${prefix2}${PATH5}`, PATCH, option).json(),
                /**
                 * 応募者の保留を解除する
                 * @returns 成功
                 */
                $patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods6['patch']['resBody'], BasicHeaders, Methods6['patch']['status']>(prefix, `${prefix2}${PATH5}`, PATCH, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix2}${PATH5}`
              },
              /**
               * 応募情報を1件更新します
               * @returns 成功
               */
              put: (option: { body: Methods1['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods1['put']['resBody'], BasicHeaders, Methods1['put']['status']>(prefix, prefix2, PUT, option).json(),
              /**
               * 応募情報を1件更新します
               * @returns 成功
               */
              $put: (option: { body: Methods1['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods1['put']['resBody'], BasicHeaders, Methods1['put']['status']>(prefix, prefix2, PUT, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}`
            }
          }
        }
      },
      /**
       * 応募情報の一覧を取得します
       * @returns 成功
       */
      get: (option: { query: Methods0['get']['query'], config?: T | undefined }) =>
        fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json(),
      /**
       * 応募情報の一覧を取得します
       * @returns 成功
       */
      $get: (option: { query: Methods0['get']['query'], config?: T | undefined }) =>
        fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json().then(r => r.body),
      /**
       * スタッフを案件に手動登録します
       * @returns 成功
       */
      post: (option: { body: Methods0['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json(),
      /**
       * スタッフを案件に手動登録します
       * @returns 成功
       */
      $post: (option: { body: Methods0['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods0['get']['query'] } | undefined) =>
        `${prefix}${PATH0}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    auth: {
      check: {
        /**
         * IDトークンが有効かどうかを判定します
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<void, BasicHeaders, Methods8['get']['status']>(prefix, PATH7, GET, option).send(),
        /**
         * IDトークンが有効かどうかを判定します
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<void, BasicHeaders, Methods8['get']['status']>(prefix, PATH7, GET, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH7}`
      },
      /**
       * Cookieに入っているリフレッシュトークンを元にIDトークンを更新します
       */
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<void, BasicHeaders, Methods7['get']['status']>(prefix, PATH6, GET, option).send(),
      /**
       * Cookieに入っているリフレッシュトークンを元にIDトークンを更新します
       */
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<void, BasicHeaders, Methods7['get']['status']>(prefix, PATH6, GET, option).send().then(r => r.body),
      /**
       * 従業員のCognitoへのサインイン
       * @returns 成功
       */
      post: (option: { body: Methods7['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods7['post']['resBody'], BasicHeaders, Methods7['post']['status']>(prefix, PATH6, POST, option).json(),
      /**
       * 従業員のCognitoへのサインイン
       * @returns 成功
       */
      $post: (option: { body: Methods7['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods7['post']['resBody'], BasicHeaders, Methods7['post']['status']>(prefix, PATH6, POST, option).json().then(r => r.body),
      /**
       * Cognitoからサインアウト
       */
      delete: (option?: { config?: T | undefined } | undefined) =>
        fetch<void, BasicHeaders, Methods7['delete']['status']>(prefix, PATH6, DELETE, option).send(),
      /**
       * Cognitoからサインアウト
       */
      $delete: (option?: { config?: T | undefined } | undefined) =>
        fetch<void, BasicHeaders, Methods7['delete']['status']>(prefix, PATH6, DELETE, option).send().then(r => r.body),
      $path: () => `${prefix}${PATH6}`
    },
    calendar: {
      /**
       * カレンダーを取得します
       * @returns 成功
       */
      get: (option: { query: Methods9['get']['query'], config?: T | undefined }) =>
        fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, PATH8, GET, option).json(),
      /**
       * カレンダーを取得します
       * @returns 成功
       */
      $get: (option: { query: Methods9['get']['query'], config?: T | undefined }) =>
        fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, PATH8, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods9['get']['query'] } | undefined) =>
        `${prefix}${PATH8}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    email_templates: {
      _type: (val1: number | string) => {
        const prefix1 = `${PATH9}/${val1}`

        return {
          /**
           * 自動返信メールテンプレートを取得します
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods10['get']['resBody'], BasicHeaders, Methods10['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * 自動返信メールテンプレートを取得します
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods10['get']['resBody'], BasicHeaders, Methods10['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          /**
           * 自動返信メールテンプレートを更新します
           * @returns 成功
           */
          put: (option: { body: Methods10['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods10['put']['resBody'], BasicHeaders, Methods10['put']['status']>(prefix, prefix1, PUT, option).json(),
          /**
           * 自動返信メールテンプレートを更新します
           * @returns 成功
           */
          $put: (option: { body: Methods10['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods10['put']['resBody'], BasicHeaders, Methods10['put']['status']>(prefix, prefix1, PUT, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      }
    },
    employees: {
      _id: (val1: number) => {
        const prefix1 = `${PATH10}/${val1}`

        return {
          password: {
            /**
             * パスワード変更(ログイン中)
             */
            put: (option: { body: Methods13['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods13['put']['status']>(prefix, `${prefix1}${PATH11}`, PUT, option).send(),
            /**
             * パスワード変更(ログイン中)
             */
            $put: (option: { body: Methods13['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods13['put']['status']>(prefix, `${prefix1}${PATH11}`, PUT, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH11}`
          },
          /**
           * 指定の従業員を取得します
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods12['get']['resBody'], BasicHeaders, Methods12['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * 指定の従業員を取得します
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods12['get']['resBody'], BasicHeaders, Methods12['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          /**
           * 従業員情報を更新します
           * @returns 成功
           */
          put: (option: { body: Methods12['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods12['put']['resBody'], BasicHeaders, Methods12['put']['status']>(prefix, prefix1, PUT, option).json(),
          /**
           * 従業員情報を更新します
           * @returns 成功
           */
          $put: (option: { body: Methods12['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods12['put']['resBody'], BasicHeaders, Methods12['put']['status']>(prefix, prefix1, PUT, option).json().then(r => r.body),
          /**
           * 従業員を削除します
           */
          delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods12['delete']['status']>(prefix, prefix1, DELETE, option).send(),
          /**
           * 従業員を削除します
           */
          $delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods12['delete']['status']>(prefix, prefix1, DELETE, option).send().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      /**
       * 従業員情報の一覧を取得します
       * @returns 成功
       */
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods11['get']['resBody'], BasicHeaders, Methods11['get']['status']>(prefix, PATH10, GET, option).json(),
      /**
       * 従業員情報の一覧を取得します
       * @returns 成功
       */
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods11['get']['resBody'], BasicHeaders, Methods11['get']['status']>(prefix, PATH10, GET, option).json().then(r => r.body),
      /**
       * 従業員を新規作成します
       * @returns 成功
       */
      post: (option: { body: Methods11['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods11['post']['resBody'], BasicHeaders, Methods11['post']['status']>(prefix, PATH10, POST, option).json(),
      /**
       * 従業員を新規作成します
       * @returns 成功
       */
      $post: (option: { body: Methods11['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods11['post']['resBody'], BasicHeaders, Methods11['post']['status']>(prefix, PATH10, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH10}`
    },
    notice_targets: {
      /**
       * お知らせ送付先一覧を取得します
       * @returns 成功
       */
      get: (option: { query: Methods14['get']['query'], config?: T | undefined }) =>
        fetch<Methods14['get']['resBody'], BasicHeaders, Methods14['get']['status']>(prefix, PATH12, GET, option).json(),
      /**
       * お知らせ送付先一覧を取得します
       * @returns 成功
       */
      $get: (option: { query: Methods14['get']['query'], config?: T | undefined }) =>
        fetch<Methods14['get']['resBody'], BasicHeaders, Methods14['get']['status']>(prefix, PATH12, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods14['get']['query'] } | undefined) =>
        `${prefix}${PATH12}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    notice_templates: {
      _id: (val1: number) => {
        const prefix1 = `${PATH13}/${val1}`

        return {
          /**
           * 指定のお知らせテンプレートを取得します
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods16['get']['resBody'], BasicHeaders, Methods16['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * 指定のお知らせテンプレートを取得します
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods16['get']['resBody'], BasicHeaders, Methods16['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          /**
           * 指定のお知らせテンプレートを更新します
           * @returns 成功
           */
          put: (option: { body: Methods16['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods16['put']['resBody'], BasicHeaders, Methods16['put']['status']>(prefix, prefix1, PUT, option).json(),
          /**
           * 指定のお知らせテンプレートを更新します
           * @returns 成功
           */
          $put: (option: { body: Methods16['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods16['put']['resBody'], BasicHeaders, Methods16['put']['status']>(prefix, prefix1, PUT, option).json().then(r => r.body),
          /**
           * 指定のお知らせテンプレートを削除します
           */
          delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods16['delete']['status']>(prefix, prefix1, DELETE, option).send(),
          /**
           * 指定のお知らせテンプレートを削除します
           */
          $delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods16['delete']['status']>(prefix, prefix1, DELETE, option).send().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      /**
       * お知らせテンプレート一覧を取得します
       * @returns 成功
       */
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods15['get']['resBody'], BasicHeaders, Methods15['get']['status']>(prefix, PATH13, GET, option).json(),
      /**
       * お知らせテンプレート一覧を取得します
       * @returns 成功
       */
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods15['get']['resBody'], BasicHeaders, Methods15['get']['status']>(prefix, PATH13, GET, option).json().then(r => r.body),
      /**
       * お知らせテンプレートを作成します
       * @returns 成功
       */
      post: (option: { body: Methods15['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods15['post']['resBody'], BasicHeaders, Methods15['post']['status']>(prefix, PATH13, POST, option).json(),
      /**
       * お知らせテンプレートを作成します
       * @returns 成功
       */
      $post: (option: { body: Methods15['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods15['post']['resBody'], BasicHeaders, Methods15['post']['status']>(prefix, PATH13, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH13}`
    },
    notices: {
      _id: (val1: number) => {
        const prefix1 = `${PATH14}/${val1}`

        return {
          /**
           * 指定のお知らせを取得します
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods18['get']['resBody'], BasicHeaders, Methods18['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * 指定のお知らせを取得します
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods18['get']['resBody'], BasicHeaders, Methods18['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      /**
       * お知らせを一覧取得します
       * @returns 成功
       */
      get: (option: { query: Methods17['get']['query'], config?: T | undefined }) =>
        fetch<Methods17['get']['resBody'], BasicHeaders, Methods17['get']['status']>(prefix, PATH14, GET, option).json(),
      /**
       * お知らせを一覧取得します
       * @returns 成功
       */
      $get: (option: { query: Methods17['get']['query'], config?: T | undefined }) =>
        fetch<Methods17['get']['resBody'], BasicHeaders, Methods17['get']['status']>(prefix, PATH14, GET, option).json().then(r => r.body),
      /**
       * お知らせを送信します
       * @returns 成功
       */
      post: (option: { body: Methods17['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods17['post']['resBody'], BasicHeaders, Methods17['post']['status']>(prefix, PATH14, POST, option).json(),
      /**
       * お知らせを送信します
       * @returns 成功
       */
      $post: (option: { body: Methods17['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods17['post']['resBody'], BasicHeaders, Methods17['post']['status']>(prefix, PATH14, POST, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods17['get']['query'] } | undefined) =>
        `${prefix}${PATH14}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    notification_templates: {
      apply: {
        /**
         * 案件募集通知テンプレートを取得します
         * @returns 成功
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods19['get']['resBody'], BasicHeaders, Methods19['get']['status']>(prefix, PATH15, GET, option).json(),
        /**
         * 案件募集通知テンプレートを取得します
         * @returns 成功
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods19['get']['resBody'], BasicHeaders, Methods19['get']['status']>(prefix, PATH15, GET, option).json().then(r => r.body),
        /**
         * 案件募集通知テンプレートを更新します
         * @returns 成功
         */
        put: (option: { body: Methods19['put']['reqBody'], config?: T | undefined }) =>
          fetch<Methods19['put']['resBody'], BasicHeaders, Methods19['put']['status']>(prefix, PATH15, PUT, option).json(),
        /**
         * 案件募集通知テンプレートを更新します
         * @returns 成功
         */
        $put: (option: { body: Methods19['put']['reqBody'], config?: T | undefined }) =>
          fetch<Methods19['put']['resBody'], BasicHeaders, Methods19['put']['status']>(prefix, PATH15, PUT, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH15}`
      },
      batch: {
        /**
         * 案件募集バッチ通知テンプレートを取得します
         * @returns 成功
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods20['get']['resBody'], BasicHeaders, Methods20['get']['status']>(prefix, PATH16, GET, option).json(),
        /**
         * 案件募集バッチ通知テンプレートを取得します
         * @returns 成功
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods20['get']['resBody'], BasicHeaders, Methods20['get']['status']>(prefix, PATH16, GET, option).json().then(r => r.body),
        /**
         * 案件募集バッチ通知テンプレートを更新します
         * @returns 成功
         */
        put: (option: { body: Methods20['put']['reqBody'], config?: T | undefined }) =>
          fetch<Methods20['put']['resBody'], BasicHeaders, Methods20['put']['status']>(prefix, PATH16, PUT, option).json(),
        /**
         * 案件募集バッチ通知テンプレートを更新します
         * @returns 成功
         */
        $put: (option: { body: Methods20['put']['reqBody'], config?: T | undefined }) =>
          fetch<Methods20['put']['resBody'], BasicHeaders, Methods20['put']['status']>(prefix, PATH16, PUT, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH16}`
      },
      cancel: {
        /**
         * 案件取消通知テンプレートを取得します
         * @returns 成功
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods21['get']['resBody'], BasicHeaders, Methods21['get']['status']>(prefix, PATH17, GET, option).json(),
        /**
         * 案件取消通知テンプレートを取得します
         * @returns 成功
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods21['get']['resBody'], BasicHeaders, Methods21['get']['status']>(prefix, PATH17, GET, option).json().then(r => r.body),
        /**
         * 案件取消通知テンプレートを更新します
         * @returns 成功
         */
        put: (option: { body: Methods21['put']['reqBody'], config?: T | undefined }) =>
          fetch<Methods21['put']['resBody'], BasicHeaders, Methods21['put']['status']>(prefix, PATH17, PUT, option).json(),
        /**
         * 案件取消通知テンプレートを更新します
         * @returns 成功
         */
        $put: (option: { body: Methods21['put']['reqBody'], config?: T | undefined }) =>
          fetch<Methods21['put']['resBody'], BasicHeaders, Methods21['put']['status']>(prefix, PATH17, PUT, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH17}`
      },
      hire: {
        /**
         * 採用通知テンプレートを取得します
         * @returns 成功
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods22['get']['resBody'], BasicHeaders, Methods22['get']['status']>(prefix, PATH18, GET, option).json(),
        /**
         * 採用通知テンプレートを取得します
         * @returns 成功
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods22['get']['resBody'], BasicHeaders, Methods22['get']['status']>(prefix, PATH18, GET, option).json().then(r => r.body),
        /**
         * 採用通知テンプレートを更新します
         * @returns 成功
         */
        put: (option: { body: Methods22['put']['reqBody'], config?: T | undefined }) =>
          fetch<Methods22['put']['resBody'], BasicHeaders, Methods22['put']['status']>(prefix, PATH18, PUT, option).json(),
        /**
         * 採用通知テンプレートを更新します
         * @returns 成功
         */
        $put: (option: { body: Methods22['put']['reqBody'], config?: T | undefined }) =>
          fetch<Methods22['put']['resBody'], BasicHeaders, Methods22['put']['status']>(prefix, PATH18, PUT, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH18}`
      },
      reject: {
        /**
         * 不採用通知テンプレートを取得します
         * @returns 成功
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods23['get']['resBody'], BasicHeaders, Methods23['get']['status']>(prefix, PATH19, GET, option).json(),
        /**
         * 不採用通知テンプレートを取得します
         * @returns 成功
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods23['get']['resBody'], BasicHeaders, Methods23['get']['status']>(prefix, PATH19, GET, option).json().then(r => r.body),
        /**
         * 不採用通知テンプレートを更新します
         * @returns 成功
         */
        put: (option: { body: Methods23['put']['reqBody'], config?: T | undefined }) =>
          fetch<Methods23['put']['resBody'], BasicHeaders, Methods23['put']['status']>(prefix, PATH19, PUT, option).json(),
        /**
         * 不採用通知テンプレートを更新します
         * @returns 成功
         */
        $put: (option: { body: Methods23['put']['reqBody'], config?: T | undefined }) =>
          fetch<Methods23['put']['resBody'], BasicHeaders, Methods23['put']['status']>(prefix, PATH19, PUT, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH19}`
      }
    },
    notifications: {
      _id: (val1: number) => {
        const prefix1 = `${PATH20}/${val1}`

        return {
          /**
           * 指定の通知情報を取得します
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods25['get']['resBody'], BasicHeaders, Methods25['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * 指定の通知情報を取得します
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods25['get']['resBody'], BasicHeaders, Methods25['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      _subjectId: (val1: number) => {
        const prefix1 = `${PATH20}/${val1}`

        return {
          senders: {
            /**
             * 通知の送信者一覧を取得します
             * @returns 成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods26['get']['resBody'], BasicHeaders, Methods26['get']['status']>(prefix, `${prefix1}${PATH21}`, GET, option).json(),
            /**
             * 通知の送信者一覧を取得します
             * @returns 成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods26['get']['resBody'], BasicHeaders, Methods26['get']['status']>(prefix, `${prefix1}${PATH21}`, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH21}`
          },
          titles: {
            /**
             * 通知のタイトル一覧を取得します
             * @returns 成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods27['get']['resBody'], BasicHeaders, Methods27['get']['status']>(prefix, `${prefix1}${PATH22}`, GET, option).json(),
            /**
             * 通知のタイトル一覧を取得します
             * @returns 成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods27['get']['resBody'], BasicHeaders, Methods27['get']['status']>(prefix, `${prefix1}${PATH22}`, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH22}`
          }
        }
      },
      apply: {
        /**
         * 案件募集通知を送信します
         */
        post: (option: { body: Methods28['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods28['post']['status']>(prefix, PATH23, POST, option).send(),
        /**
         * 案件募集通知を送信します
         */
        $post: (option: { body: Methods28['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods28['post']['status']>(prefix, PATH23, POST, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH23}`
      },
      cancel: {
        /**
         * 案件取消通知を送信します
         * @returns 成功
         */
        post: (option: { body: Methods29['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods29['post']['resBody'], BasicHeaders, Methods29['post']['status']>(prefix, PATH24, POST, option).json(),
        /**
         * 案件取消通知を送信します
         * @returns 成功
         */
        $post: (option: { body: Methods29['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods29['post']['resBody'], BasicHeaders, Methods29['post']['status']>(prefix, PATH24, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH24}`
      },
      hire: {
        /**
         * 採用通知を送信し、スタッフが仮採用ステータスになる
         */
        post: (option: { body: Methods30['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods30['post']['status']>(prefix, PATH25, POST, option).send(),
        /**
         * 採用通知を送信し、スタッフが仮採用ステータスになる
         */
        $post: (option: { body: Methods30['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods30['post']['status']>(prefix, PATH25, POST, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH25}`
      },
      reject: {
        /**
         * 不採用通知を送信し、スタッフが不採用ステータスになる
         */
        post: (option: { body: Methods31['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods31['post']['status']>(prefix, PATH26, POST, option).send(),
        /**
         * 不採用通知を送信し、スタッフが不採用ステータスになる
         */
        $post: (option: { body: Methods31['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods31['post']['status']>(prefix, PATH26, POST, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH26}`
      },
      /**
       * 通知の一覧を取得します
       * @returns 成功
       */
      get: (option: { query: Methods24['get']['query'], config?: T | undefined }) =>
        fetch<Methods24['get']['resBody'], BasicHeaders, Methods24['get']['status']>(prefix, PATH20, GET, option).json(),
      /**
       * 通知の一覧を取得します
       * @returns 成功
       */
      $get: (option: { query: Methods24['get']['query'], config?: T | undefined }) =>
        fetch<Methods24['get']['resBody'], BasicHeaders, Methods24['get']['status']>(prefix, PATH20, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods24['get']['query'] } | undefined) =>
        `${prefix}${PATH20}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    password: {
      reset: {
        _uuid: (val2: string) => {
          const prefix2 = `${PATH27}/${val2}`

          return {
            /**
             * パスワード変更(ログイン前)
             */
            put: (option: { body: Methods33['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods33['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * パスワード変更(ログイン前)
             */
            $put: (option: { body: Methods33['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods33['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        /**
         * 従業員のパスワード変更用URLのメール送付を受け付けます
         */
        post: (option: { body: Methods32['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods32['post']['status']>(prefix, PATH27, POST, option).send(),
        /**
         * 従業員のパスワード変更用URLのメール送付を受け付けます
         */
        $post: (option: { body: Methods32['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods32['post']['status']>(prefix, PATH27, POST, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH27}`
      }
    },
    subject_belt_capacities: {
      _subjectBeltId: (val1: number) => {
        const prefix1 = `${PATH28}/${val1}`

        return {
          _timeSlot: (val2: number | string) => {
            const prefix2 = `${prefix1}/${val2}`

            return {
              /**
               * 職種がベルト盛り付けや調理補助の時の配置上限人数を更新します
               * @returns 成功
               */
              put: (option: { body: Methods35['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods35['put']['resBody'], BasicHeaders, Methods35['put']['status']>(prefix, prefix2, PUT, option).json(),
              /**
               * 職種がベルト盛り付けや調理補助の時の配置上限人数を更新します
               * @returns 成功
               */
              $put: (option: { body: Methods35['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods35['put']['resBody'], BasicHeaders, Methods35['put']['status']>(prefix, prefix2, PUT, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}`
            }
          }
        }
      },
      /**
       * 職種がベルト盛り付けか調理補助の時の配置上限人数、配置/確定/仮採用/採用の内訳人数を取得します
       * @returns 成功
       */
      get: (option: { query: Methods34['get']['query'], config?: T | undefined }) =>
        fetch<Methods34['get']['resBody'], BasicHeaders, Methods34['get']['status']>(prefix, PATH28, GET, option).json(),
      /**
       * 職種がベルト盛り付けか調理補助の時の配置上限人数、配置/確定/仮採用/採用の内訳人数を取得します
       * @returns 成功
       */
      $get: (option: { query: Methods34['get']['query'], config?: T | undefined }) =>
        fetch<Methods34['get']['resBody'], BasicHeaders, Methods34['get']['status']>(prefix, PATH28, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods34['get']['query'] } | undefined) =>
        `${prefix}${PATH28}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    subject_job_capacities: {
      _subjectId: (val1: number) => {
        const prefix1 = `${PATH29}/${val1}`

        return {
          _subjectJobTitle: (val2: number | string) => {
            const prefix2 = `${prefix1}/${val2}`

            return {
              /**
               * 職種がおせちの時の配置上限人数、配置/確定/仮採用/採用の内訳人数を取得します
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods36['get']['resBody'], BasicHeaders, Methods36['get']['status']>(prefix, prefix2, GET, option).json(),
              /**
               * 職種がおせちの時の配置上限人数、配置/確定/仮採用/採用の内訳人数を取得します
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods36['get']['resBody'], BasicHeaders, Methods36['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
              /**
               * 職種がおせちの時の配置上限人数の更新
               * @returns 成功
               */
              patch: (option: { body: Methods36['patch']['reqBody'], config?: T | undefined }) =>
                fetch<Methods36['patch']['resBody'], BasicHeaders, Methods36['patch']['status']>(prefix, prefix2, PATCH, option).json(),
              /**
               * 職種がおせちの時の配置上限人数の更新
               * @returns 成功
               */
              $patch: (option: { body: Methods36['patch']['reqBody'], config?: T | undefined }) =>
                fetch<Methods36['patch']['resBody'], BasicHeaders, Methods36['patch']['status']>(prefix, prefix2, PATCH, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}`
            }
          }
        }
      }
    },
    subject_job_titles: {
      _subjectId: (val1: number) => {
        const prefix1 = `${PATH30}/${val1}`

        return {
          _subjectJobTitle: (val2: number | string) => {
            const prefix2 = `${prefix1}/${val2}`

            return {
              close: {
                /**
                 * 職種を募集終了する
                 */
                patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<void, BasicHeaders, Methods37['patch']['status']>(prefix, `${prefix2}${PATH31}`, PATCH, option).send(),
                /**
                 * 職種を募集終了する
                 */
                $patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<void, BasicHeaders, Methods37['patch']['status']>(prefix, `${prefix2}${PATH31}`, PATCH, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix2}${PATH31}`
              }
            }
          }
        }
      }
    },
    subject_slot_times: {
      _subjectSlotLabelId: (val1: number) => {
        const prefix1 = `${PATH32}/${val1}`

        return {
          _subjectBeltId: (val2: number) => {
            const prefix2 = `${prefix1}/${val2}`

            return {
              /**
               * 職種がベルト盛り付けや調理補助の時の詳細時間を取得します
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods38['get']['resBody'], BasicHeaders, Methods38['get']['status']>(prefix, prefix2, GET, option).json(),
              /**
               * 職種がベルト盛り付けや調理補助の時の詳細時間を取得します
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods38['get']['resBody'], BasicHeaders, Methods38['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
              /**
               * 職種がベルト盛り付けや調理補助の時の詳細時間を更新します
               * @returns 成功
               */
              put: (option: { body: Methods38['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods38['put']['resBody'], BasicHeaders, Methods38['put']['status']>(prefix, prefix2, PUT, option).json(),
              /**
               * 職種がベルト盛り付けや調理補助の時の詳細時間を更新します
               * @returns 成功
               */
              $put: (option: { body: Methods38['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods38['put']['resBody'], BasicHeaders, Methods38['put']['status']>(prefix, prefix2, PUT, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}`
            }
          }
        }
      }
    },
    subjects: {
      _id: (val1: number) => {
        const prefix1 = `${PATH33}/${val1}`

        return {
          csv: {
            /**
             * 指定案件の応募者情報のCSVを出力します
             * @returns 成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods41['get']['resBody'], BasicHeaders, Methods41['get']['status']>(prefix, `${prefix1}${PATH34}`, GET, option).text(),
            /**
             * 指定案件の応募者情報のCSVを出力します
             * @returns 成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods41['get']['resBody'], BasicHeaders, Methods41['get']['status']>(prefix, `${prefix1}${PATH34}`, GET, option).text().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH34}`
          },
          time_records: {
            /**
             * 指定案件の勤怠表を出力します
             * @returns 成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods42['get']['resBody'], BasicHeaders, Methods42['get']['status']>(prefix, `${prefix1}${PATH35}`, GET, option).text(),
            /**
             * 指定案件の勤怠表を出力します
             * @returns 成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods42['get']['resBody'], BasicHeaders, Methods42['get']['status']>(prefix, `${prefix1}${PATH35}`, GET, option).text().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH35}`
          },
          /**
           * 指定の案件情報を取得します
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods40['get']['resBody'], BasicHeaders, Methods40['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * 指定の案件情報を取得します
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods40['get']['resBody'], BasicHeaders, Methods40['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          /**
           * 案件情報を更新します
           * @returns 成功
           */
          put: (option: { body: Methods40['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods40['put']['resBody'], BasicHeaders, Methods40['put']['status']>(prefix, prefix1, PUT, option).json(),
          /**
           * 案件情報を更新します
           * @returns 成功
           */
          $put: (option: { body: Methods40['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods40['put']['resBody'], BasicHeaders, Methods40['put']['status']>(prefix, prefix1, PUT, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      /**
       * 案件情報の一覧を取得します
       * @returns 成功
       */
      get: (option: { query: Methods39['get']['query'], config?: T | undefined }) =>
        fetch<Methods39['get']['resBody'], BasicHeaders, Methods39['get']['status']>(prefix, PATH33, GET, option).json(),
      /**
       * 案件情報の一覧を取得します
       * @returns 成功
       */
      $get: (option: { query: Methods39['get']['query'], config?: T | undefined }) =>
        fetch<Methods39['get']['resBody'], BasicHeaders, Methods39['get']['status']>(prefix, PATH33, GET, option).json().then(r => r.body),
      /**
       * 案件を新規作成します
       * @returns 成功
       */
      post: (option: { body: Methods39['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods39['post']['resBody'], BasicHeaders, Methods39['post']['status']>(prefix, PATH33, POST, option).json(),
      /**
       * 案件を新規作成します
       * @returns 成功
       */
      $post: (option: { body: Methods39['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods39['post']['resBody'], BasicHeaders, Methods39['post']['status']>(prefix, PATH33, POST, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods39['get']['query'] } | undefined) =>
        `${prefix}${PATH33}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    template_metadata: {
      /**
       * テンプレートメタデータを取得します
       * @returns 成功
       */
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods43['get']['resBody'], BasicHeaders, Methods43['get']['status']>(prefix, PATH36, GET, option).json(),
      /**
       * テンプレートメタデータを取得します
       * @returns 成功
       */
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods43['get']['resBody'], BasicHeaders, Methods43['get']['status']>(prefix, PATH36, GET, option).json().then(r => r.body),
      /**
       * テンプレートメタデータを更新します
       * @returns 成功
       */
      put: (option: { body: Methods43['put']['reqBody'], config?: T | undefined }) =>
        fetch<Methods43['put']['resBody'], BasicHeaders, Methods43['put']['status']>(prefix, PATH36, PUT, option).json(),
      /**
       * テンプレートメタデータを更新します
       * @returns 成功
       */
      $put: (option: { body: Methods43['put']['reqBody'], config?: T | undefined }) =>
        fetch<Methods43['put']['resBody'], BasicHeaders, Methods43['put']['status']>(prefix, PATH36, PUT, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH36}`
    },
    users: {
      _id: (val1: number) => {
        const prefix1 = `${PATH37}/${val1}`

        return {
          approve: {
            /**
             * スタッフ確認を承認する
             * @returns 成功
             */
            put: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods46['put']['resBody'], BasicHeaders, Methods46['put']['status']>(prefix, `${prefix1}${PATH38}`, PUT, option).json(),
            /**
             * スタッフ確認を承認する
             * @returns 成功
             */
            $put: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods46['put']['resBody'], BasicHeaders, Methods46['put']['status']>(prefix, `${prefix1}${PATH38}`, PUT, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH38}`
          },
          leave: {
            approve: {
              /**
               * スタッフからの退会申請を承認する
               * @returns 成功
               */
              put: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods47['put']['resBody'], BasicHeaders, Methods47['put']['status']>(prefix, `${prefix1}${PATH39}`, PUT, option).json(),
              /**
               * スタッフからの退会申請を承認する
               * @returns 成功
               */
              $put: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods47['put']['resBody'], BasicHeaders, Methods47['put']['status']>(prefix, `${prefix1}${PATH39}`, PUT, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix1}${PATH39}`
            },
            reject: {
              /**
               * スタッフからの退会申請を却下する
               * @returns 成功
               */
              put: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods48['put']['resBody'], BasicHeaders, Methods48['put']['status']>(prefix, `${prefix1}${PATH40}`, PUT, option).json(),
              /**
               * スタッフからの退会申請を却下する
               * @returns 成功
               */
              $put: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods48['put']['resBody'], BasicHeaders, Methods48['put']['status']>(prefix, `${prefix1}${PATH40}`, PUT, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix1}${PATH40}`
            }
          },
          reject: {
            /**
             * スタッフ確認却下を却下する
             * @returns 成功
             */
            put: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods49['put']['resBody'], BasicHeaders, Methods49['put']['status']>(prefix, `${prefix1}${PATH41}`, PUT, option).json(),
            /**
             * スタッフ確認却下を却下する
             * @returns 成功
             */
            $put: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods49['put']['resBody'], BasicHeaders, Methods49['put']['status']>(prefix, `${prefix1}${PATH41}`, PUT, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH41}`
          },
          revive: {
            /**
             * 退職済のスタッフを在職中に戻す
             * @returns 成功
             */
            put: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods50['put']['resBody'], BasicHeaders, Methods50['put']['status']>(prefix, `${prefix1}${PATH42}`, PUT, option).json(),
            /**
             * 退職済のスタッフを在職中に戻す
             * @returns 成功
             */
            $put: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods50['put']['resBody'], BasicHeaders, Methods50['put']['status']>(prefix, `${prefix1}${PATH42}`, PUT, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH42}`
          },
          /**
           * 指定のスタッフを取得します
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods45['get']['resBody'], BasicHeaders, Methods45['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * 指定のスタッフを取得します
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods45['get']['resBody'], BasicHeaders, Methods45['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          /**
           * スタッフ情報を更新します
           * @returns 成功
           */
          put: (option: { body: Methods45['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods45['put']['resBody'], BasicHeaders, Methods45['put']['status']>(prefix, prefix1, PUT, option).json(),
          /**
           * スタッフ情報を更新します
           * @returns 成功
           */
          $put: (option: { body: Methods45['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods45['put']['resBody'], BasicHeaders, Methods45['put']['status']>(prefix, prefix1, PUT, option).json().then(r => r.body),
          /**
           * 指定のスタッフを削除します
           */
          delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods45['delete']['status']>(prefix, prefix1, DELETE, option).send(),
          /**
           * 指定のスタッフを削除します
           */
          $delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods45['delete']['status']>(prefix, prefix1, DELETE, option).send().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      /**
       * スタッフ情報の一覧を取得します
       * @returns 成功
       */
      get: (option?: { query?: Methods44['get']['query'] | undefined, config?: T | undefined } | undefined) =>
        fetch<Methods44['get']['resBody'], BasicHeaders, Methods44['get']['status']>(prefix, PATH37, GET, option).json(),
      /**
       * スタッフ情報の一覧を取得します
       * @returns 成功
       */
      $get: (option?: { query?: Methods44['get']['query'] | undefined, config?: T | undefined } | undefined) =>
        fetch<Methods44['get']['resBody'], BasicHeaders, Methods44['get']['status']>(prefix, PATH37, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods44['get']['query'] } | undefined) =>
        `${prefix}${PATH37}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    }
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
