export const getTokenFromCookie = ({
    type = 'idToken'
}: {
    type?: 'idToken' | 'accessToken';
}): string | undefined => {
    const cookieToken = document!
        .cookie!.replaceAll(' ', '')
        .split(';')
        .find((row) => row.startsWith(type));

    return cookieToken?.split('=')[1];
};
