import { rest } from 'msw';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const notificationTemplatesHandlers = [
    rest.get(
        `${employeeURL}/notification_templates/apply`,
        (_req, res, ctx) => {
            return res(
                ctx.status(200),
                ctx.json({
                    id: 1,
                    type: 0,
                    title: '弁当作成のアルバイト募集',
                    body: '4月1日のアルバイトを募集します。'
                })
            );
        }
    ),
    rest.put(
        `${employeeURL}/notification_templates/apply`,
        (_req, res, ctx) => {
            return res(ctx.status(200));
        }
    ),
    rest.get(
        `${employeeURL}/notification_templates/reject`,
        (_req, res, ctx) => {
            return res(
                ctx.status(200),
                ctx.json({
                    id: 1,
                    type: 1,
                    title: '弁当作成のアルバイト募集採用について',
                    body: '申し訳ありません。不採用です。'
                })
            );
        }
    ),
    rest.put(
        `${employeeURL}/notification_templates/reject`,
        (_req, res, ctx) => {
            return res(ctx.status(200));
        }
    ),
    rest.get(`${employeeURL}/notification_templates/hire`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                type: 2,
                title: '弁当作成のアルバイト　前日通知',
                body: '4月1日のアルバイトの詳細をお送りします。',
                note: '備考です',
                timeNote: '仕事量により、終業時刻の長短があります',
                breakTime: '法令に従う',
                salaryNote: '8時間以上の勤務がある場合は25%割増'
            })
        );
    }),
    rest.put(`${employeeURL}/notification_templates/hire`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.get(
        `${employeeURL}/notification_templates/cancel`,
        (_req, res, ctx) => {
            return res(
                ctx.status(200),
                ctx.json({
                    id: 3,
                    type: 0,
                    title: '弁当作成のアルバイト募集　取り消し',
                    body: '4月1日の案件取り消します。'
                })
            );
        }
    ),
    rest.put(
        `${employeeURL}/notification_templates/cancel`,
        (_req, res, ctx) => {
            return res(ctx.status(200));
        }
    ),
    rest.get(
        `${employeeURL}/notification_templates/batch`,
        (_req, res, ctx) => {
            return res(
                ctx.status(200),
                ctx.json({
                    id: 3,
                    type: 0,
                    title: '案件募集のお知らせ',
                    body: '新しい案件が追加されました',
                    sendingDayOfWeek: null,
                    // '日曜日',
                    sendingTime: null
                    // '9:00'
                })
            );
        }
    ),
    rest.put(
        `${employeeURL}/notification_templates/batch`,
        (_req, res, ctx) => {
            return res(ctx.status(200));
        }
    )
];
