import { Tab } from '@headlessui/react';
import { FC } from 'react';
import { TabsProps } from './Tabs.types';
import { cn } from '@/utils';

export const Tabs: FC<TabsProps> = ({
    categories,
    contents,
    className,
    tabListClassName,
    categoriesClassName,
    contentsClassName,
    defaultIndex,
    onChange,
    onChangeManuallyIndexOperation
}) => {
    return (
        <div className={cn('w-full h-full', className)}>
            <Tab.Group
                defaultIndex={
                    defaultIndex ??
                    Math.max(
                        categories.findIndex(
                            (category) =>
                                // eslint-disable-next-line no-restricted-globals
                                category.name === location.hash.replace('#', '')
                        ),
                        0
                    )
                }
                onChange={(index) => {
                    onChange?.({ index });

                    if (onChangeManuallyIndexOperation != null) {
                        onChangeManuallyIndexOperation({ index });
                    } else {
                        // eslint-disable-next-line no-restricted-globals
                        history.replaceState(
                            // eslint-disable-next-line no-restricted-globals
                            history.state,
                            '',
                            window.location.pathname +
                                '#' +
                                categories[index].name
                        );
                    }
                }}
            >
                <Tab.List
                    className={cn(
                        'flex bg-gray-light rounded-md',
                        tabListClassName
                    )}
                >
                    {categories.map((category) => (
                        <Tab
                            key={category.label}
                            className={({ selected }) =>
                                cn(
                                    'w-full py-2.5 text-sm rounded-md',
                                    selected
                                        ? 'border-primary border-2 font-semibold bg-white'
                                        : 'hover:bg-gray-neutral hover:font-semibold text-gray-dark',
                                    categoriesClassName
                                )
                            }
                        >
                            {category.label}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels className="mt-2">
                    {contents.map((content, idx) => (
                        <Tab.Panel key={idx} className={contentsClassName}>
                            {content}
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};
