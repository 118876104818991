import { applicationsHandlers } from './applications';
import { authHandlers } from './auth';
import { mypageCalendarHandlers } from './mypage_calendar';
import { passwordHandlers } from './password';
import { subjectsHandlers } from './subjects';
import { usersHandlers } from './users';
import { verifyHandlers } from './verify';

export const userHandlers = [
    ...authHandlers,
    ...usersHandlers,
    ...passwordHandlers,
    ...mypageCalendarHandlers,
    ...subjectsHandlers,
    ...applicationsHandlers,
    ...verifyHandlers
];
