import { ReactComponent as UnfoldMore } from '@material-design-icons/svg/outlined/unfold_more.svg';
import { ReactNode, useCallback } from 'react';

export const useSortHandler = <T,>({
    sortKey,
    setSortKey,
    onClick
}: {
    sortKey:
        | {
              key: keyof T;
              isAscending: boolean;
          }
        | undefined;
    setSortKey: (
        value: React.SetStateAction<
            | {
                  key: keyof T;
                  isAscending: boolean;
              }
            | undefined
        >
    ) => void;
    onClick?: () => void;
}) => {
    const sortByKey = useCallback(
        ({ data }: { data: T[] | undefined }) => {
            if (data == null || data.length === 0) return;
            if (sortKey === undefined) return data;

            const { key, isAscending } = sortKey;

            const numericAttendances = data.filter((a: T) => {
                return (
                    a[key] !== null &&
                    a[key] !== undefined &&
                    !isNaN(Number(a[key]))
                );
            });

            const nonNumericAttendances = data.filter((a: T) => {
                return (
                    a[key] === null ||
                    a[key] === undefined ||
                    isNaN(Number(a[key]))
                );
            });

            numericAttendances.sort((a: T, b: T) => {
                if (a[key] === null) return isAscending ? 1 : -1;
                if (b[key] === null) return isAscending ? -1 : 1;
                return (
                    (isAscending ? 1 : -1) * (Number(a[key]) - Number(b[key]))
                );
            });

            nonNumericAttendances.sort((a: T, b: T) => {
                if (a[key] === null) return isAscending ? 1 : -1;
                if (b[key] === null) return isAscending ? -1 : 1;
                if (a[key]! < b[key]!) return isAscending ? -1 : 1;
                if (a[key]! > b[key]!) return isAscending ? 1 : -1;
                return (
                    (isAscending ? 1 : -1) *
                    a[key]!.toString().localeCompare(b[key]!.toString())
                );
            });

            return [...numericAttendances, ...nonNumericAttendances];
        },
        [sortKey]
    );

    const onClickSort = useCallback(
        ({ keyName }: { keyName: keyof T }) => {
            if (sortKey === undefined || sortKey!.key !== keyName) {
                setSortKey({ key: keyName, isAscending: true });
                return;
            } else {
                setSortKey((prev) => ({
                    ...prev!,
                    isAscending: !prev!.isAscending
                }));
            }
        },
        [setSortKey, sortKey]
    );

    const SortHeaderLabel = useCallback(
        ({
            labelName,
            keyName
        }: {
            labelName: ReactNode;
            keyName: keyof T;
        }) => {
            return (
                <div className="flex items-center justify-center">
                    <p>{labelName}</p>
                    <span
                        className="cursor-pointer"
                        onClick={() => {
                            onClickSort({ keyName: keyName });
                            onClick?.();
                        }}
                    >
                        <UnfoldMore className="w-4 h-6 fill-primary" />
                    </span>
                </div>
            );
        },
        [onClickSort, onClick]
    );

    return { sortByKey, onClickSort, SortHeaderLabel };
};
