import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';

export const createMonthlyDateData = ({
    year,
    monthIdx
}: {
    year: number;
    monthIdx: number;
}) => {
    dayjs.locale(ja);

    const targetMonth = dayjs().year(year).month(monthIdx);

    // 月初・月末の曜日
    const weekDayOfFirstDay = targetMonth.date(1).day();
    const weekDayOfEndDay = dayjs(targetMonth).endOf('month').day();

    // カレンダーの一番最初に表示する日
    const startDate = targetMonth.date(1 - weekDayOfFirstDay);

    // カレンダーに表示させる週の合計（前月末・次月初を含む）
    const totalWeek =
        (targetMonth.daysInMonth() +
            weekDayOfFirstDay +
            (6 - weekDayOfEndDay)) /
        7;

    let day = -1;
    const monthlyDateData = new Array(totalWeek).fill([]).map(() =>
        new Array(7).fill(null).map(() => {
            day++;
            return startDate.add(day, 'day').format('YYYY/MM/DD');
        })
    );

    return monthlyDateData;
};
