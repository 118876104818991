import { QueryClient, DefaultOptions } from 'react-query';

const queryConfig: DefaultOptions = {
    queries: {
        useErrorBoundary: (error) =>
            // @ts-expect-error
            error?.response?.status >= 500 ||
            // @ts-expect-error
            error?.response?.status === 400 ||
            // @ts-expect-error
            error?.response?.status === 401,
        refetchOnWindowFocus: false,
        retry: false,
        keepPreviousData: true,
        cacheTime: 1000
    },
    mutations: {
        useErrorBoundary: (error) =>
            // @ts-expect-error
            error?.response?.status >= 500 ||
            // @ts-expect-error
            error?.response?.status === 400 ||
            // @ts-expect-error
            error?.response?.status === 401,
        retry: false
    }
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });
