import { rest } from 'msw';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const templateMetadataHandlers = [
    rest.get(`${employeeURL}/template_metadata`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                signature:
                    '——\n八百彦アルバイト担当者\n担当者：\n電話番号：\n受付時間：'
            })
        );
    }),
    rest.put(`${employeeURL}/template_metadata`, (_req, res, ctx) => {
        return res(ctx.status(200));
    })
];
