import aspida from '@aspida/axios';
import axios from 'axios';
import { useCallback, useMemo } from 'react';
import userApi from '@/apis/bin/user_openapi/$api';
import {
    getTokenFromCookie,
    fetchOptions,
    sanitizeObjectProperties
} from '@/utils';

const baseURLForUser = process.env.REACT_APP_USER_API_URL;

export const useApiClientWithAccessToken = () => {
    const idToken = getTokenFromCookie({});
    const accessToken = getTokenFromCookie({ type: 'accessToken' });

    const headers = useMemo(
        () =>
            sanitizeObjectProperties({
                Authorization: idToken,
                'X-AccessToken': accessToken
            }),
        [idToken, accessToken]
    );

    const apiClientForUserWithAccessToken = useCallback(() => {
        return userApi(
            aspida(axios, { baseURL: baseURLForUser, ...fetchOptions, headers })
        );
    }, [headers]);

    return {
        apiClientForUserWithAccessToken
    };
};
