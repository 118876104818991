import { rest } from 'msw';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const noticeTemplatesHandlers = [
    rest.get(`${employeeURL}/notice_templates`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json([
                {
                    id: 1,
                    templateName: '社割クーポン配布用',
                    title: '社割クーポン配布のお知らせ',
                    body: 'お疲れさまです。株式会社 八百彦です。下記クーポン番号を配布します。'
                },
                {
                    id: 2,
                    templateName: '事務連絡（年末調整）',
                    title: '事務連絡（年末調整）のお知らせ',
                    body: 'お疲れさまです。株式会社 八百彦です。年末調整のお知らせです。'
                },
                {
                    id: 3,
                    templateName: '事務連絡（源泉徴収票）',
                    title: '事務連絡（源泉徴収票）のお知らせ',
                    body: 'お疲れさまです。株式会社 八百彦です。源泉徴収票を提出してください。'
                },
                {
                    id: 4,
                    templateName: '台風,災害対応',
                    title: '台風,災害対応のお知らせ',
                    body: 'お疲れさまです。株式会社 八百彦です。災害時の対応について〜〜〜。'
                }
            ])
        );
    }),
    rest.post(`${employeeURL}/notice_templates`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.put(`${employeeURL}/notice_templates/:id`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.delete(`${employeeURL}/notice_templates/:id`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.get(`${employeeURL}/notice_templates/:id`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                templateName: '社割クーポン配布用',
                title: '社割クーポン配布のお知らせ',
                body: 'お疲れさまです。株式会社 八百彦です。下記クーポン番号を配布します。'
            })
        );
    })
];
