import {
    Outlet,
    Route,
    createBrowserRouter,
    createRoutesFromElements
} from 'react-router-dom';
import { ErrorBoundaryForEmployee } from '@/providers/ErrorBoundaryForEmployee';
import { ErrorBoundaryForUser } from '@/providers/ErrorBoundaryForUser';
import { AuthenticatedRoute } from '@/routes/AuthenticatedRoute';
import { lazyImport } from '@/utils';

const { App } = lazyImport(async () => await import('@/App'), 'App');

const { NotFound } = lazyImport(
    async () => await import('@/features/misc/pages/NotFound'),
    'NotFound'
);

const { AuthRoutes } = lazyImport(
    async () => await import('@/features/auth/routes'),
    'AuthRoutes'
);

const { SettingRoutes } = lazyImport(
    async () => await import('@/features/setting/routes'),
    'SettingRoutes'
);

const { AdminRoutes } = lazyImport(
    async () => await import('@/features/admin/routes'),
    'AdminRoutes'
);

const { StaffRoutes } = lazyImport(
    async () => await import('@/features/staff/routes'),
    'StaffRoutes'
);

const { SubjectRoutes } = lazyImport(
    async () => await import('@/features/subject/routes'),
    'SubjectRoutes'
);

const { ApplicantRoutes } = lazyImport(
    async () => await import('@/features/applicant/routes'),
    'ApplicantRoutes'
);

const { TemplateRoutes } = lazyImport(
    async () => await import('@/features/template/routes'),
    'TemplateRoutes'
);

const { NotificationRoutes } = lazyImport(
    async () => await import('@/features/notification/routes'),
    'NotificationRoutes'
);

const { NoticeRoutes } = lazyImport(
    async () => await import('@/features/notice/routes'),
    'NoticeRoutes'
);

const { UserRoutes } = lazyImport(
    async () => await import('@/features/user/routes'),
    'UserRoutes'
);

export const appRouter = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route element={<ErrorBoundaryForEmployee children={<Outlet />} />}>
                <Route path="/" element={<AuthenticatedRoute />}>
                    <Route index element={<App />} />
                    <Route path="setting/*" element={<SettingRoutes />} />
                    <Route path="admin/*" element={<AdminRoutes />} />
                    <Route path="staff/*" element={<StaffRoutes />} />
                    <Route path="subject/*" element={<SubjectRoutes />} />
                    <Route
                        path="subject/applicant/*"
                        element={<ApplicantRoutes />}
                    />
                    <Route path="template/*" element={<TemplateRoutes />} />
                    <Route
                        path="notification/*"
                        element={<NotificationRoutes />}
                    />
                    <Route path="notice/*" element={<NoticeRoutes />} />
                </Route>
                <Route path="auth/*" element={<AuthRoutes />} />
            </Route>
            <Route element={<ErrorBoundaryForUser children={<Outlet />} />}>
                <Route path="user/*" element={<UserRoutes />} />
            </Route>
            <Route path="*" element={<NotFound />} />
        </>
    )
);
