import aspida from '@aspida/axios';
import axios from 'axios';
import { useCallback, useMemo } from 'react';
import employeeApi from '@/apis/bin/employee_openapi/$api';
import userApi from '@/apis/bin/user_openapi/$api';
import { getTokenFromCookie, fetchOptions } from '@/utils';

const baseURLForEmployee = process.env.REACT_APP_EMPLOYEE_API_URL;
const baseURLForUser = process.env.REACT_APP_USER_API_URL;

export const useApiClient = () => {
    const token = getTokenFromCookie({});

    if (!(token != null && token !== '')) {
        throw new Error('useApiClient はログインしていないと使えません');
    }

    const headers = useMemo(
        () => (token != null ? { Authorization: token } : undefined),
        [token]
    );

    const apiClientForEmployee = useCallback(() => {
        return employeeApi(
            aspida(axios, {
                baseURL: baseURLForEmployee,
                ...fetchOptions,
                headers
            })
        );
    }, [headers]);

    const apiClientForUser = useCallback(() => {
        return userApi(
            aspida(axios, { baseURL: baseURLForUser, ...fetchOptions, headers })
        );
    }, [headers]);

    return {
        apiClientForEmployee,
        apiClientForUser
    };
};
