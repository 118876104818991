import { useEffect, useReducer, useState } from 'react';
import {
    // unstable_usePrompt as usePrompt,
    useNavigate,
    unstable_useBlocker as useBlocker
} from 'react-router-dom';
import { Button } from '@/components/bases';

export const useFormGuard = ({ isDirty }: { isDirty: boolean }) => {
    const initialState = {
        isNavigatable: false,
        path: '',
        historyState: null
    };

    const reducer = (
        state: { isNavigatable: boolean; path: string; historyState: unknown },
        { path, historyState }: { path: string; historyState?: unknown }
    ): { isNavigatable: boolean; path: string; historyState: unknown } => {
        return {
            isNavigatable: true,
            path,
            historyState
        };
    };

    const [{ isNavigatable, path, historyState }, forceNavigate] = useReducer(
        reducer,
        initialState
    );
    const [checkStatus, setCheckStatus] = useState<
        'UNCHECKED' | 'NEED' | 'CHECKED_OK' | 'CHECKED_CANCEL'
    >('UNCHECKED');
    const [promptRegistered, setPromptRegistered] = useState(false);

    const isIOSSafari =
        window.navigator.userAgent.includes('iPhone') &&
        window.navigator.userAgent.includes('Safari');

    const navigate = useNavigate();

    const when = isDirty && !isNavigatable;
    const message = 'このページを離れると入力した内容は失われます。';

    const blocker = useBlocker(when);

    // usePrompt start
    // iOS Safariの場合はwindow.confirmが表示されないため、独自のダイアログを表示する処理をusePromptに追加
    useEffect(() => {
        if (blocker.state === 'blocked') {
            if (isIOSSafari) {
                switch (checkStatus) {
                    case 'UNCHECKED':
                        // ダイアログを表示させる
                        setCheckStatus('NEED');
                        break;

                    case 'CHECKED_OK':
                        setTimeout(blocker.proceed, 0);
                        break;

                    case 'CHECKED_CANCEL':
                        blocker.reset();

                        // チェック履歴をリセットする
                        setCheckStatus('UNCHECKED');
                        break;

                    default:
                        // ステータスがNEEDの場合は何もしない（ダイアログを表示する）
                        break;
                }
            } else {
                let proceed = window.confirm(message);

                if (proceed === true) {
                    setTimeout(blocker.proceed, 0);
                } else {
                    blocker.reset();
                }
            }
        }
    }, [blocker, message, isIOSSafari, checkStatus]);

    useEffect(() => {
        if (blocker.state === 'blocked' && !when) {
            blocker.reset();
        }
    }, [blocker, when]);
    // usePrompt end

    useEffect(() => {
        // ページ遷移時にプロンプトを解除
        return () => {
            if (promptRegistered) {
                setPromptRegistered(false);
                // プロンプトを解除する
                window.onbeforeunload = null;
            }
        };
    }, [promptRegistered, isIOSSafari]);

    useEffect(() => {
        if (isNavigatable) {
            navigate(path, {
                state: historyState
            });
        }

        if (isDirty) {
            // プロンプトが登録されていなければ登録する
            if (!promptRegistered) {
                setPromptRegistered(true);
                window.onbeforeunload = () => true;
            }
        } else {
            // プロンプトが登録されていれば解除する
            if (promptRegistered) {
                setPromptRegistered(false);
                window.onbeforeunload = null;
            }
        }
    }, [
        isDirty,
        isNavigatable,
        path,
        historyState,
        navigate,
        promptRegistered,
        isIOSSafari,
        setCheckStatus
    ]);

    // iOS safari用のダイアログ
    const ConfirmDialog = () => (
        <>
            {checkStatus === 'NEED' && (
                <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen z-80 bg-gray bg-opacity-50">
                    <div className="flex flex-col items-center justify-center p-5 bg-white border rounded-md gap-5 border-primary">
                        <p className="text-sm text-gray-dark">
                            このページを離れると入力した内容は失われます。
                        </p>
                        <div className="flex items-center justify-center gap-3">
                            <Button
                                variant="primary-outline"
                                className="px-5 py-2"
                                onClick={() => {
                                    setCheckStatus('CHECKED_CANCEL');
                                }}
                            >
                                キャンセル
                            </Button>
                            <Button
                                className="px-10 py-2"
                                onClick={() => {
                                    setCheckStatus('CHECKED_OK');
                                }}
                            >
                                OK
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );

    return {
        forceNavigate,
        setCheckStatus,
        ConfirmDialog
    };
};
