import imageCompression from 'browser-image-compression';

export const compressImage = async ({
    file,
    maxSizeMB
}: {
    file: File;
    maxSizeMB: number;
}): Promise<File> => {
    if (file.size > maxSizeMB * 1024 * 1024) {
        const options = {
            maxSizeMB: maxSizeMB
        };
        const compressedFile = await imageCompression(file, options);

        return compressedFile;
    } else {
        return file;
    }
};
