import { rest } from 'msw';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const notificationsHandlers = [
    rest.get(`${employeeURL}/notifications`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                currentPage: 1,
                totalItems: 30,
                items: [
                    {
                        id: 1,
                        subjectId: 1,
                        type: 0,
                        userName: '山田 太郎',
                        userId: 1,
                        title: '弁当作成のアルバイト募集　追加募集',
                        jobTitle: 'ベルト盛り付け',
                        workingTime: '午後のみ',
                        sendedDate: '2023/04/01',
                        senderName: '田中 一郎'
                    },
                    {
                        id: 1,
                        subjectId: 1,
                        type: 0,
                        userName: '山田 花子',
                        userId: 2,
                        title: '弁当作成のアルバイト募集　追加募集',
                        jobTitle: 'ベルト盛り付け',
                        workingTime: '午後のみ',
                        sendedDate: '2023/04/01',
                        senderName: '田中 一郎'
                    },
                    {
                        id: 1,
                        subjectId: 3,
                        type: 0,
                        userName: '佐藤 三郎',
                        userId: 2,
                        title: '弁当作成のアルバイト募集　追加募集',
                        jobTitle: 'ベルト盛り付け',
                        workingTime: '午後のみ',
                        sendedDate: '2023/04/01',
                        senderName: '田中 一郎'
                    },
                    {
                        id: 1,
                        subjectId: 1,
                        type: 0,
                        userName: '山田 太郎',
                        userId: 1,
                        title: '弁当作成のアルバイト募集　追加募集',
                        jobTitle: 'おせち',
                        workingTime: '午後のみ',
                        sendedDate: '2023/04/01',
                        senderName: '田中 一郎'
                    },
                    {
                        id: 1,
                        subjectId: 1,
                        type: 0,
                        userName: '山田 花子',
                        userId: 2,
                        title: '弁当作成のアルバイト募集　追加募集',
                        jobTitle: 'おせち',
                        workingTime: '午後のみ',
                        sendedDate: '2023/04/01',
                        senderName: '田中 一郎'
                    },
                    {
                        id: 1,
                        subjectId: 3,
                        type: 0,
                        userName: '佐藤 三郎',
                        userId: 2,
                        title: '弁当作成のアルバイト募集　追加募集',
                        jobTitle: 'おせち',
                        workingTime: '午後のみ',
                        sendedDate: '2023/04/01',
                        senderName: '田中 一郎'
                    },
                    {
                        id: 2,
                        subjectId: 1,
                        type: 1,
                        userName: '山田 太郎',
                        userId: 1,
                        title: '弁当作成のアルバイト募集　不採用通知',
                        jobTitle: 'ベルト盛り付け',
                        workingTime: '6:30〜ラストまで',
                        sendedDate: '2023/04/05',
                        senderName: '田中 一郎'
                    },
                    {
                        id: 3,
                        subjectId: 1,
                        type: 1,
                        userName: '山田 太郎',
                        userId: 1,
                        jobTitle: 'おせち',
                        title: '弁当作成のアルバイト募集　不採用通知',
                        workingTime: '朝イチ〜ラスト（17時予定）まで',
                        sendedDate: '2023/04/05',
                        senderName: '田中 一郎'
                    },
                    {
                        id: 4,
                        subjectId: 1,
                        type: 2,
                        userName: '山田 太郎',
                        userId: 1,
                        title: '弁当作成のアルバイト募集　前日通知',
                        workingTime: null,
                        sendedDate: '2023/05/29',
                        senderName: '田中 一郎'
                    },
                    {
                        id: 5,
                        subjectId: 1,
                        type: 0,
                        userName: '山田 太郎',
                        userId: 1,
                        title: '弁当作成のアルバイト募集　追加募集',
                        workingTime: '朝〜昼まで',
                        sendedDate: '2023/04/05',
                        senderName: '田中 一郎'
                    },
                    {
                        id: 6,
                        subjectId: 1,
                        type: 1,
                        userName: '山田 太郎',
                        userId: 1,
                        title: '弁当作成のアルバイト募集　不採用通知',
                        jobTitle: '調理補助',
                        workingTime: '午後のみ',
                        sendedDate: '2023/04/05',
                        senderName: '田中 一郎'
                    },
                    {
                        id: 7,
                        subjectId: 1,
                        type: 1,
                        userName: '山田 太郎',
                        userId: 1,
                        title: '弁当作成のアルバイト募集　不採用通知',
                        jobTitle: '調理補助',
                        workingTime: '6:30〜ラストまで',
                        sendedDate: '2023/04/05',
                        senderName: '田中 一郎'
                    },
                    {
                        id: 8,
                        subjectId: 1,
                        type: 0,
                        userName: '山田 太郎',
                        userId: 1,
                        title: '弁当作成のアルバイト募集　追加募集',
                        workingTime: '朝イチ〜ラスト（17時予定）まで',
                        sendedDate: '2023/04/12',
                        senderName: '田中 一郎'
                    },
                    {
                        id: 9,
                        subjectId: 1,
                        type: 3,
                        userName: '山田 太郎',
                        userId: 1,
                        title: '弁当作成のアルバイト募集　取り消し通知',
                        workingTime: null,
                        sendedDate: '2023/05/30',
                        senderName: '田中 一郎'
                    }
                ]
            })
        );
    }),
    rest.get(`${employeeURL}/notifications/:id`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                subjectId: 1,
                type: 2,
                subjectTitle: '弁当作成',
                userName: '山田 太郎',
                title: '弁当作成のアルバイト募集',
                body: '4月1日のアルバイトを募集します。',
                signature: '八尾彦アルバイト担当者',
                implementationDate: '2023/04/01',
                definedStartTime: '09:00',
                definedEndTime: '15:00',
                timeNote: '仕事量により、終業時刻の長短があります',
                breakTime: '法令に従う',
                jobTitle: 'ベルト盛り付け',
                workLocation: '八百彦本店調理場',
                hourlyWage: '1,020円',
                salaryNote: '8時間以上の勤務がある場合は25%割増',
                paymentMethod: '当日払い',
                transportationCost: '500円',
                note: '備考です'
            })
        );
    }),
    rest.get(
        `${employeeURL}/notifications/:subjectId/senders`,
        (_req, res, ctx) => {
            return res(
                ctx.status(200),
                ctx.json([
                    // {
                    //     senderName: '田中 一郎',
                    //     phoneticSenderName: 'タナカ イチロウ'
                    // },
                    // {
                    //     senderName: '山田 太郎',
                    //     phoneticSenderName: 'ヤマダ タロウ'
                    // },
                    {
                        senderName: null,
                        phoneticSenderName: null
                    },
                    {
                        senderName: '鈴木 花江',
                        phoneticSenderName: 'スズキ ハナエ'
                    },
                    {
                        senderName: '鈴木 花江',
                        phoneticSenderName: 'スズキ ハナエ'
                    },
                    {
                        senderName: '鈴木 花江',
                        phoneticSenderName: 'スズキ ハナエ'
                    }
                ])
            );
        }
    ),
    rest.get(
        `${employeeURL}/notifications/:subjectId/titles`,
        (_req, res, ctx) => {
            return res(
                ctx.status(200),
                ctx.json([
                    '弁当作成のアルバイト募集',
                    '弁当作成追加募集',
                    '弁当作成取り消しの案内',
                    '弁当作成　労働条件通知書'
                ])
            );
        }
    ),
    rest.post(`${employeeURL}/notifications/reject`, (_req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
    }),
    rest.post(`${employeeURL}/notifications/hire`, (_req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
    }),
    rest.post(`${employeeURL}/notifications/apply`, (_req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
    }),
    rest.post(`${employeeURL}/notifications/cancel`, (_req, res, ctx) => {
        return res(ctx.status(200), ctx.json({}));
    })
];
