import { rest } from 'msw';
const userURL = process.env.REACT_APP_USER_API_URL!;

export const applicationsHandlers = [
    rest.post(`${userURL}/applications`, (_req, res, ctx) => {
        return res(ctx.status(401));
    }),
    rest.get(`${userURL}/applications`, (_req, res, ctx) => {
        return res(ctx.status(200));
    }),
    rest.put(
        `${userURL}/applications/:subjectId/:userId/hire/:uuid`,
        (_req, res, ctx) => {
            return res(ctx.status(409));
        }
    ),
    rest.get(
        `${userURL}/applications/:subjectId/:userId/hire/:uuid`,
        (_req, res, ctx) => {
            return res(ctx.status(200));
        }
    )
];
