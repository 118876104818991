import { ComponentPropsWithRef, FC, forwardRef } from 'react';
import { cn } from '@/utils';

type CheckboxProps = {
    variant?: 'primary' | 'gray';
} & ComponentPropsWithRef<'input'>;

export const Checkbox: FC<CheckboxProps> = forwardRef(
    ({ variant = 'primary', ...props }, ref) => {
        const variantClass = {
            primary: 'bg-white text-primary border-primary',
            gray: 'bg-white text-primary border-gray-neutral disabled:text-gray-neutral'
        };
        return (
            <input
                {...props}
                className={cn(
                    'rounded border-2 p-2',
                    variantClass[variant],
                    props.className
                )}
                type="checkbox"
                ref={ref}
            />
        );
    }
);
