import { rest } from 'msw';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const emailTemplatesHandlers = [
    rest.get(`${employeeURL}/email_templates/:type`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                type: 0,
                title: 'スタッフ登録を承認',
                body: 'スタッフ登録情報を承認しました'
            })
        );
    }),
    rest.put(`${employeeURL}/email_templates/:type`, (_req, res, ctx) => {
        return res(ctx.status(200));
    })
];
