import { FC } from 'react';
import { CautionProps } from './Caution.types';
import { cn } from '@/utils';

export const Caution: FC<CautionProps> = ({ size = 'middle', className }) => {
    const variantClass = {
        small: 'h-4 w-4 text-2xs font-bold',
        middle: 'h-9 w-9 text-2xl font-bold',
        large: 'h-16 w-16 text-4xl font-bold'
    };

    return (
        <div
            className={cn(
                'flex0 flex flex-none items-center justify-center rounded-full bg-primary text-white',
                variantClass[size],
                className
            )}
        >
            !
        </div>
    );
};
