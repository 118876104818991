import { rest } from 'msw';
const employeeURL = process.env.REACT_APP_EMPLOYEE_API_URL!;

export const subjectBeltCapacityHandlers = [
    rest.put(
        `${employeeURL}/subject_belt_capacities/:subjectBeltId/:timeSlot`,
        (_req, res, ctx) => {
            return res(ctx.status(200));
        }
    ),
    rest.get(`${employeeURL}/subject_belt_capacities`, (_req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json([
                {
                    totalRecruitmentCapacity: 300,
                    totalAssignedNumber: 18,
                    totalConfirmedNumber: 20,
                    totalProvisionalHiredNumber: 22,
                    totalHiredNumber: 20,
                    subjectBeltCapacities: [
                        {
                            timeSlot: 0,
                            beltName: 'A',
                            recruitmentCapacity: 100,
                            assignedNumber: 5,
                            confirmedNumber: 5,
                            provisionalHiredNumber: 5,
                            hiredNumber: 5
                        },
                        {
                            timeSlot: 1,
                            beltName: 'A',
                            recruitmentCapacity: 100,
                            assignedNumber: 6,
                            confirmedNumber: 7,
                            provisionalHiredNumber: 8,
                            hiredNumber: 5
                        }
                        // {
                        //     timeSlot: 2,
                        //     beltName: 'A',
                        //     recruitmentCapacity: 100,
                        //     assignedNumber: 7,
                        //     confirmedNumber: 8,
                        //     provisionalHiredNumber: 9,
                        //     hiredNumber: 10
                        // }
                    ]
                },
                {
                    totalRecruitmentCapacity: 150,
                    totalAssignedNumber: 10,
                    totalConfirmedNumber: 7,
                    totalProvisionalHiredNumber: 8,
                    totalHiredNumber: 75,
                    subjectBeltCapacities: [
                        {
                            timeSlot: 0,
                            beltName: 'B',
                            recruitmentCapacity: 40,
                            assignedNumber: 5,
                            confirmedNumber: 2,
                            provisionalHiredNumber: 3,
                            hiredNumber: 30
                        },
                        {
                            timeSlot: 1,
                            beltName: 'B',
                            recruitmentCapacity: 20,
                            assignedNumber: 5,
                            confirmedNumber: 5,
                            provisionalHiredNumber: 5,
                            hiredNumber: 4
                        }
                    ]
                },
                {
                    totalRecruitmentCapacity: 150,
                    totalAssignedNumber: 10,
                    totalConfirmedNumber: 7,
                    totalProvisionalHiredNumber: 8,
                    totalHiredNumber: 75,
                    subjectBeltCapacities: [
                        {
                            beltName: 'C',
                            timeSlot: 0,
                            recruitmentCapacity: null,
                            assignedNumber: 0,
                            confirmedNumber: 0,
                            provisionalHiredNumber: 0,
                            hiredNumber: 0
                        },
                        {
                            beltName: 'C',
                            timeSlot: 1,
                            recruitmentCapacity: null,
                            assignedNumber: 0,
                            confirmedNumber: 0,
                            provisionalHiredNumber: 0,
                            hiredNumber: 0
                        }
                    ]
                },
                {
                    totalRecruitmentCapacity: 110,
                    totalAssignedNumber: 10,
                    totalConfirmedNumber: 7,
                    totalProvisionalHiredNumber: 18,
                    totalHiredNumber: 75,
                    subjectBeltCapacities: [
                        {
                            timeSlot: 0,
                            beltName: '調理補助',
                            recruitmentCapacity: 50,
                            assignedNumber: 5,
                            confirmedNumber: 2,
                            provisionalHiredNumber: 3,
                            hiredNumber: 30
                        },
                        {
                            timeSlot: 1,
                            beltName: '調理補助',
                            recruitmentCapacity: 60,
                            assignedNumber: 5,
                            confirmedNumber: 5,
                            provisionalHiredNumber: 15,
                            hiredNumber: 35
                        }
                    ]
                }
            ])
        );
    })
];
