export const encodeFileToBase64 = ({ file }: { file: File }): Promise<string> =>
    new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e: ProgressEvent<FileReader>) => {
            const base64Data = e.target!.result as string;
            resolve(base64Data);
        };
    });
