import { FC } from 'react';
import { Button } from '@/components/bases';

type ErrorProps = { statusCode: number };
export const Error: FC<ErrorProps> = ({ statusCode }) => {
    const errorMessage =
        statusCode === 401 ? (
            <p className="text-center">
                セッションの有効期限が切れました
                <br />
                再度ログインしてください
            </p>
        ) : (
            'エラーが発生しました'
        );

    const buttonLabel = statusCode === 401 ? 'ログイン画面へ' : 'トップへ戻る';

    return (
        <div className="flex flex-col items-center justify-center h-screen gap-y-8">
            <h1 className="text-xl font-bold text-color-black">
                {errorMessage}
            </h1>
            <Button
                onClick={() => window.location.assign(window.location.origin)}
            >
                {buttonLabel}
            </Button>
        </div>
    );
};
