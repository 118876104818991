import { ReactComponent as ArrowBack } from '@material-design-icons/svg/outlined/arrow_back.svg';
import { ReactComponent as ArrowForward } from '@material-design-icons/svg/outlined/arrow_forward.svg';
import { FC } from 'react';
import { PagerProps } from './Pager.types';
import { getPagerDisplay } from '@/logics';
import { cn } from '@/utils';

export const Pager: FC<PagerProps> = ({
    pagerSize,
    currentPage,
    dataLength,
    getNewPage
}) => {
    const pagerDisplay = getPagerDisplay({
        pagerSize: pagerSize,
        currentPage: currentPage,
        dataLength: dataLength
    });

    const decrementPage = () => {
        if (currentPage > 0) {
            getNewPage(currentPage - 1);
        }
    };

    const incrementPage = () => {
        if (currentPage < pagerDisplay.maxPage) {
            getNewPage(currentPage + 1);
        }
    };

    const setTargetPage = (targetPage: number) => {
        if (currentPage !== targetPage) {
            getNewPage(targetPage);
        }
    };

    return (
        <div className="flex items-center justify-center text-xs">
            {pagerDisplay.labels.length > 0 && (
                <div className="flex items-center justify-center">
                    <ArrowBack
                        data-disabled={currentPage === 0}
                        onClick={decrementPage}
                        className={cn(
                            'w-5 h-5 cursor-pointer fill-primary',
                            'data-[disabled=true]:fill-primary-shadow data-[disabled=true]:cursor-default'
                        )}
                    />
                    <div className="flex items-center justify-center mx-3 gap-2">
                        {pagerDisplay.labels.map((label, i) => (
                            <p
                                key={'page' + i}
                                onClick={() => setTargetPage(label - 1)}
                                className={cn(
                                    label - 1 === currentPage
                                        ? 'font-bold'
                                        : 'text-primary-shadow',
                                    'cursor-pointer w-6 text-center'
                                )}
                            >
                                {label}
                            </p>
                        ))}
                    </div>
                    <ArrowForward
                        onClick={incrementPage}
                        data-disabled={currentPage === pagerDisplay.maxPage}
                        className={cn(
                            'w-5 h-5 cursor-pointer fill-primary',
                            'data-[disabled=true]:fill-primary-shadow data-[disabled=true]:cursor-default'
                        )}
                    />
                </div>
            )}
            <div className="flex items-center justify-center w-40 ml-5">
                <p>{dataLength}件中</p>
                <p className="ml-3">{`${pagerDisplay.currentPageMin.toString()} - ${pagerDisplay.currentPageMax.toString()}件表示`}</p>
            </div>
        </div>
    );
};
